import { Typography, Box, Button } from "@mui/material";
import NotFoundImg from "../assets/images/404_not_found.svg";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  let navigate = useNavigate();

  return (
    <Box
      id="error-page"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap={4}
      sx={{ marginTop: "50px" }}
    >
      <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
        Oops!, Page Not Found
      </Typography>

      <div>
        <Typography
          variant="h6"
          color="grey"
          component="div"
          sx={{ flexGrow: 1 }}
        >
          Sorry, we couldn’t find the page you’re looking for. Perhaps
        </Typography>

        <Typography
          variant="h6"
          color="grey"
          component="div"
          sx={{ flexGrow: 1 }}
        >
          you’ve mistyped the URL? Be sure to check your spelling.
        </Typography>
      </div>

      <img src={NotFoundImg} alt="sidebar-icon" style={{ width: "450px" }} />

      <Button
        color="primary"
        variant="contained"
        onClick={() => navigate(-1)}
        sx={{ width: "200px" }}
      >
        Go Back
      </Button>
    </Box>
  );
}
