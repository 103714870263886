import React, { useState } from "react";
import {
  Button,
  IconButton,
  Dialog,
  StepLabel,
  Box,
  CircularProgress,
} from "@mui/material";
import StepButton from "@mui/material/StepButton";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { Stepper, Step } from "@mui/material";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
// import { Mapping } from "./Mapping";
import { UploadFile } from "./UploadFile";
import {
  updateFileError,
  updateSelectedFile,
} from "store/employees/employeesSlice";
import { useDispatch, useSelector } from "react-redux";
import { ValidateData } from "./ValidateData";
// import { MapDataOptions } from "mock_data/EmployeeMock";

export const Import = (props) => {
  const { close, checkImportFileStatus } = props;
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed] = React.useState({});
  const [dataLoaded, setDataLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validatedData, setValidatedData] = useState({
    total_records: 0,
    valid_records: 0,
    invalid_records: 0,
    invalid_records_path: "",
  });
  const [uploadError, setUploadError] = useState("");

  const selectedFile = useSelector((state) => state.employees.selectedFile);
  const dispatch = useDispatch();

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  // useEffect(() => {
  //   let result = {};
  //   Arrayofobjects.forEach((item) => {
  //     const { name, value } = item;
  //     if (value !== "") {
  //       result[value] = name;
  //     }
  //   });
  //   setMappingsResult(result);
  // }, [Arrayofobjects]);

  // const validateMapping = (MapDataOptions, Arrayofobjects) => {
  //   const valuesFromArray1 = MapDataOptions.filter((item) =>
  //     item.label.includes('*')
  //   ).map((item) => item.value);
  //   const valuesFromArray2 = Arrayofobjects.map((item) => item.value);

  //   const valuesPresent = valuesFromArray1.every((value) =>
  //     valuesFromArray2.includes(value),
  //   );
  //   return valuesPresent;
  // };

  // const handleNext = () => {
  //   if (selectedFile !== null) {
  //     const newActiveStep =
  //       isLastStep() && !allStepsCompleted()
  //         ? steps.findIndex((step, i) => !(i in completed))
  //         : activeStep + 1;
  //     setActiveStep(newActiveStep);

  //     const formData = new FormData();
  //     formData.append("file", selectedFile);

  //     if (!dataLoaded) {
  //       setLoading(true);
  //       axios
  //         .post(`${process.env.REACT_APP_API_URL}file_upload`, formData)
  //         .then((response) => {
  //           console.log("Response data:", response.data);
  //           setValidatedData(response.data.results);
  //           // setImportedFields(response.data.results);
  //           // createImportedColomns(response.data.results.csv_headers);
  //           setDataLoaded(true);
  //         })
  //         .catch((error) => {
  //           console.error("Error:", error);
  //         })
  //         .finally(() => {
  //           setLoading(false);
  //         });
  //     }
  //     return;
  //   } else {
  //     dispatch(updateFileError("please select file"));
  //   }
  //   if(validatedData.invalid_records === 0){
  //     checkImportFileStatus(true)
  //     close()
  //   }
  //   close()
  // };

  const handleNext = () => {
    if (selectedFile !== null) {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);

      const formData = new FormData();
      formData.append("file", selectedFile);

      if (!dataLoaded) {
        setLoading(true);
        axios
          .post(`${process.env.REACT_APP_API_URL}file_upload`, formData)
          .then((response) => {
            setValidatedData(response.data.results);
            setDataLoaded(true);
          })
          .catch((error) => {
            console.error("Error:", error);
            setUploadError(error.response.data.results.columns);
          })
          .finally(() => {
            setLoading(false);
          });
      }
      return;
    } else {
      dispatch(updateFileError("please select file"));
    }
  };

  const handleFinish = () => {
    if (validatedData.invalid_records !== validatedData.total_records) {
      checkImportFileStatus(Math.random());
      close();
      dispatch(updateSelectedFile(null));
    } else {
      close();
      dispatch(updateSelectedFile(null));
    }
  };

  // const handleUpload = () => {
  //   setmMappingError(null);
  //   if (!validateMapping(MapDataOptions, Arrayofobjects)) {
  //     setmMappingError("Please map the mandatory fields mentioned with (*)");
  //   } else {
  //     const newActiveStep =
  //       isLastStep() && !allStepsCompleted()
  //         ? steps.findIndex((step, i) => !(i in completed))
  //         : activeStep + 1;
  //     setActiveStep(newActiveStep);

  //     /*       const formData = new FormData();
  //     formData.append("filename", importedFields?.filename);
  //     formData.append("path", importedFields?.path);
  //     formData.append("mappings", mappingsResult); */

  //     let validationData = {
  //       fileName: importedFields?.filename,
  //       path: importedFields?.path,
  //       mappings: mappingsResult,
  //     };

  //     setLoading(true);
  //     axios
  //       .post(`${process.env.REACT_APP_API_URL}insert_data`, validationData)
  //       .then((response) => {
  //         setValidatedData(response.data.results);
  //       })
  //       .catch((error) => {
  //         console.error("Error:", error);
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   }
  // };

  // const createImportedColomns = (val) => {
  //   const valuesArray = val;
  //   for (let i = 0; i < valuesArray?.length; i++) {
  //     const val = valuesArray[i];
  //     const obj = { name: val, value: "" };
  //     Arrayofobjects.push(obj);
  //   }
  // };

  const steps = ["Upload File", "Summary"];

  // const updaArrayOfObjects = (data) => {
  //   setArrayOfObjects(data);
  // };

  const closeDialog = () => {
    if (validatedData.invalid_records !== validatedData.total_records) {
      checkImportFileStatus(Math.random());
      dispatch(updateSelectedFile(null));
      dispatch(updateFileError(null));
      close();
    } else {
      dispatch(updateSelectedFile(null));
      dispatch(updateFileError(null));
      close();
    }
  };

  // const Options = MapDataOptions?.map((option, index) => (
  //   <MenuItem key={option.value} value={Object.values(option)[0]}>
  //     {Object.keys(option)}
  //   </MenuItem>
  // ));

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <UploadFile />;
      // case 1:
      //   return (
      //     <Mapping
      //       Arrayofobjects={Arrayofobjects}
      //       MapDataOptions={MapDataOptions}
      //       updaArrayOfObjects={updaArrayOfObjects}
      //       mappingError={mappingError}
      //     />
      //   );
      case 1:
        return <ValidateData {...validatedData} uploadError={uploadError} />;

      default:
        return "Unknown step";
    }
  };

  return (
    <Dialog
      onClose={close}
      aria-labelledby="customized-dialog-title"
      open={true}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className="bg-ligh-blue">Import New Employees</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          closeDialog();
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent className="custom-model-content-height">
        <div className="import-file">
          <div className="tab-header">
            <Stepper activeStep={activeStep} linear='true' sx={{ width: "280px" }}>
              {steps.map((label, index) => (
                <Step key={label} completed={completed[index]}>
                  <StepButton
                    className="step-button"
                    color="inherit"
                    onClick={handleStep(index)}
                  >
                    <StepLabel className="step-label">{label}</StepLabel>
                  </StepButton>
                </Step>
              ))}
            </Stepper>
          </div>

          <div className="tab-content">
            
            {loading ? (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
                <CircularProgress />
              </Box>
            ) : (
              getStepContent(activeStep)
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions 
      // dividers 
      sx={{ mr: 2, mb: 1 }}>
        {activeStep === 0 ? (
          selectedFile === null ? (
            <Button
              sx={{ width: "100px" }}
              size="small"
              variant="contained"
              color="primary"
              disabled
            >
              Upload
            </Button>
          ) : (
            <LoadingButton
              sx={{ width: "100px" }}
              size="small"
              variant="contained"
              loading={loading}
              color="primary"
              onClick={handleNext}
            >
              Upload
            </LoadingButton>
          )
        ) : (
          <LoadingButton
            sx={{ width: "100px" }}
            size="small"
            variant="contained"
            loading={loading}
            color="primary"
            onClick={handleFinish}
          >
            Finish
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};
