import React from "react";
import { Stack, Alert, AlertTitle } from "@mui/material";

export const ValidateData = (props) => {
  const {
    total_records,
    valid_records,
    invalid_records,
    invalid_records_file,
    uploadError,
  } = props;

  return (
    <>
      {total_records === 0 ? (
        <Alert severity="error">
          <AlertTitle>Upload Failed</AlertTitle>
          {`Following column(s) are missing or not correct in the file.`}

          {uploadError?.length > 0 && (
            <ol style={{ fontWeight: "bold" }}>
              {uploadError?.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ol>
          )}
        </Alert>
      ) : (
        <Stack direction="column" spacing={2}>
          {valid_records !== 0 && invalid_records !== 0 && (
            <div>
              <Alert severity="success">
                <AlertTitle>Upload Success</AlertTitle>
                {`${valid_records} out of ${total_records} records were successfully uploaded.`}
              </Alert>
              <Alert severity="error">
                <AlertTitle>Upload Failed</AlertTitle>
                <div>{`Kindly download the file and address the issues found in the ${invalid_records} records that failed validation. Once resolved, please proceed to re-upload the corrected file.`}</div>
                <a
                  href={`${process.env.REACT_APP_API_URL}export_invalid_records?filename=${invalid_records_file}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Download Error File
                </a>
              </Alert>
            </div>
          )}

          {valid_records === 0 && invalid_records !== 0 && (
            <Alert severity="error">
              <AlertTitle>Upload Failed</AlertTitle>
              <div>{`Kindly download the file and address the issues found in the ${invalid_records} records that failed validation. Once resolved, please proceed to re-upload the corrected file.`}</div>
              <a
                href={`${process.env.REACT_APP_API_URL}export_invalid_records?filename=${invalid_records_file}`}
                target="_blank"
                rel="noreferrer"
              >
                Download Error File
              </a>
            </Alert>
          )}

          {valid_records !== 0 && invalid_records === 0 && (
            <Alert severity="success">
              <AlertTitle>Upload Success</AlertTitle>
              {`${valid_records} out of ${total_records} records were successfully uploaded.`}
            </Alert>
          )}
        </Stack>
      )}
    </>
  );
};
