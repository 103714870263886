import React, { useMemo, useState } from "react";
import { Button, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import EditIcon from "@mui/icons-material/Edit";
import AddTimeOff from "./AddTimeOff";
import { agGridConfigs } from "utils/Util";
import EmployeeAssignment from "./EmployeeAssignment";
import InfoIcon from "@mui/icons-material/Info";

const pagination = false;
const paginationPageSize = 5;

const TerritoryAssignments = (props) => {
  const { empDetails, update, isEditMode, empTerminated } = props;
  const [territoryModal, setTerritoryModel] = useState(false);
  const [timeOffModal, setTimeOffModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedTimeOffRow, setSelectedtimeOffRow] = useState(null);
  const [action, setAction] = useState(null);
  const [actionTimeOff, setActionTimeOff] = useState(null);

  const TerritoryAssignmentDefs = useMemo(() => {
    return agGridConfigs;
  }, []);

  const editModel = (params) => {
    setSelectedRow(params.data);
    setTerritoryModel(true);
    setAction("edit");
  };

  const editTimeOffModel = (params) => {
    setSelectedtimeOffRow(params.data);
    setTimeOffModal(true);
    setActionTimeOff("edit");
  };

  const [columnDefs,] = useState([
    {
      headerName: "Team",
      field: "team",
      sortable: true,
      resizable: true,
      width: 120,
    },
    {
      headerName: "Territory ID",
      field: "territoryNo",
      sortable: true,
      resizable: true,
      width: 120,
    },
    {
      headerName: "Territory Name",
      field: "territory",
      sortable: true,
      resizable: true,
      width: 120,
    },
    {
      headerName: "Type",
      field: "type",
      sortable: true,
      resizable: true,
      width: 120,
    },
    {
      headerName: "Start Date",
      field: "startDate",
      sortable: true,
      resizable: true,
      width: 120,
    },
    {
      headerName: "IC Date",
      field: "ICDate",
      sortable: true,
      resizable: true,
      width: 120,
    },
    {
      headerName: "End Date",
      field: "endDate",
      sortable: true,
      resizable: true,
      width: 120,
    },
    {
      width: 50,
      cellRenderer: (props) => {
        return (
          <div>
            <IconButton
              color="info"
              variant="outlined"
              size="small"
              onClick={() => editModel(props)}
            >
              <EditIcon />
            </IconButton>
          </div>
        );
      },
      cellRendererParams: {
        label: "Edit",
      },
    },
  ]);

  const [timeOffcolumnDefs,] = useState([
    {
      headerName: "Team",
      field: "team",
      sortable: true,
      resizable: true,
      width: 120,
    },
    {
      headerName: "Territory ID",
      field: "territoryNo",
      sortable: true,
      resizable: true,
      width: 120,
    },
    {
      headerName: "Territory Name",
      field: "territory",
      sortable: true,
      resizable: true,
      width: 120,
    },
    {
      headerName: "Type",
      field: "type",
      sortable: true,
      resizable: true,
      width: 120,
    },
    {
      headerName: "Start Date",
      field: "startDate",
      sortable: true,
      resizable: true,
      width: 120,
    },
    {
      headerName: "End Date",
      field: "endDate",
      sortable: true,
      resizable: true,
      width: 120,
    },
    {
      width: 50,
      cellRenderer: (props) => {
        return (
          <div>
            <IconButton
              color="info"
              variant="outlined"
              size="small"
              onClick={() => editTimeOffModel(props)}
            >
              <EditIcon />
            </IconButton>
          </div>
        );
      },
      cellRendererParams: {
        label: "Edit",
      },
    },
  ]);

  const saveTerritoryAssignment = (data, action) => {
    let newEmpData = { ...empDetails };
    if (action === "add") {
      newEmpData = {
        ...empDetails,
        territoryAssignment: [...empDetails.territoryAssignment, data],
      };
    } else {
      newEmpData = {
        ...newEmpData,
        territoryAssignment: newEmpData.territoryAssignment.map((item) => {
          if (item.id === data.id) {
            return { ...data };
          }
          return item;
        }),
      };
    }
    update(newEmpData);
  };

  const saveTimeOffTerritory = (data, actionTimeOff) => {
    let newEmpData = { ...empDetails };
    if (actionTimeOff === "add") {
      newEmpData = {
        ...empDetails,
        timeOffTerritory: [...empDetails.timeOffTerritory, data],
      };
    } else {
      newEmpData = {
        ...newEmpData,
        timeOffTerritory: newEmpData.timeOffTerritory.map((item) => {
          if (item.id === data.id) {
            return { ...data };
          }
          return item;
        }),
      };
    }
    update(newEmpData);
  };

  //Add Assignment model
  const openTerritoryModel = () => {
    setTerritoryModel(true);
    setAction("add");
  };

  const closeTerritoryModel = () => {
    setTerritoryModel(false);
    setAction(null);
  };

  //TimeOff model
  const openTimeOffModel = () => {
    setTimeOffModal(true);
    setActionTimeOff("add");
  };

  const closeTimeOffModel = () => {
    setTimeOffModal(false);
    setActionTimeOff(null);
  };

  return (
    <div>
      <Grid container direction="column" spacing={1} sx={{ mb: 4 }}>
        <Grid item>
          <Grid container direction={"row"} justifyContent={"space-between"}>
            <Grid item>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Territory Assignments
              </Typography>
            </Grid>
            <Grid item>
              <div className="add-assignment-info">
                <Button
                  color="info"
                  variant="outlined"
                  size="small"
                  onClick={() => openTerritoryModel()}
                  disabled={
                    isEditMode === "add" ||
                    empDetails.terminated_by !== null ||
                    empTerminated?.date_terminated !== undefined
                      ? true
                      : false
                  }
                >
                  ADD ASSIGNMENT
                </Button>
                {isEditMode === "add" ? (
                  <Tooltip title="Please create an employee to assign territories ">
                    <InfoIcon
                      color="action"
                      fontSize="10px"
                      sx={{ cursor: "pointer", fontSize: 18, ml: 1 }}
                    />
                  </Tooltip>
                ) : (
                  ""
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <div className="ag-theme-alpine ag-theme-alpine--compact">
            <AgGridReact
              headerHeight={35}
              defaultColDef={TerritoryAssignmentDefs}
              rowData={empDetails.territoryAssignment}
              columnDefs={columnDefs}
              pagination={pagination}
              paginationPageSize={paginationPageSize}
              rowHeight={30}
              domLayout="autoHeight"
            />
          </div>
        </Grid>

        <Grid item sx={{ mt: 4 }}>
          <Grid container direction={"row"} justifyContent={"space-between"}>
            <Grid item>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Time off Territory
              </Typography>
            </Grid>
            <Grid item>
              <div className="add-assignment-info">
                <Button
                  color="info"
                  variant="outlined"
                  size="small"
                  onClick={() => openTimeOffModel()}
                  // disabled={isEditMode === "add" || !isEditMode}
                  disabled={
                    isEditMode === "add" ||
                    empDetails?.territoryAssignment?.length === 0 ||
                    (empDetails?.terminated_by === null &&
                    empTerminated?.date_terminated === undefined
                      ? false
                      : true)
                  }
                >
                  ADD TIMEOFF
                </Button>
                {isEditMode === "add" ? (
                  <Tooltip title="Please create an employee to add territory time-off">
                    <InfoIcon
                      color="action"
                      fontSize="10px"
                      sx={{ cursor: "pointer", fontSize: 18, ml: 1 }}
                    />
                  </Tooltip>
                ) : (
                  ""
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <div className="ag-theme-alpine ag-theme-alpine--compact">
            <AgGridReact
              headerHeight={35}
              defaultColDef={TerritoryAssignmentDefs}
              rowData={empDetails.timeOffTerritory}
              columnDefs={timeOffcolumnDefs}
              pagination={pagination}
              paginationPageSize={paginationPageSize}
              rowHeight={30}
              domLayout="autoHeight"
            />
          </div>
        </Grid>
      </Grid>

      {territoryModal && (
        <EmployeeAssignment
          data={selectedRow}
          action={action}
          close={() => closeTerritoryModel()}
          update={saveTerritoryAssignment}
          empDetails={empDetails}
          empTerminated={empTerminated}
        />
      )}

      {timeOffModal && (
        <AddTimeOff
          data={selectedTimeOffRow}
          actionTimeOff={actionTimeOff}
          close={() => closeTimeOffModel()}
          update={saveTimeOffTerritory}
          empDetails={empDetails}
          empTerminated={empTerminated}
        />
      )}
    </div>
  );
};

export default TerritoryAssignments;
