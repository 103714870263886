import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Skeleton from "@mui/material/Skeleton";
import { CardContent, Grid, Stack } from "@mui/material";

function CustomCardSkeleton(props) {
  return (
    <Card className="report-card" sx={{ width: "100%" }}>
      <CardHeader
        avatar={
          <Skeleton
            animation="wave"
            variant="circular"
            width={40}
            height={40}
          />
        }
        title={
          <React.Fragment>
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={15} width="80%" />
            <Skeleton animation="wave" height={15} width="80%" />
          </React.Fragment>
        }
      />

      <CardContent>
        <React.Fragment>
          <Grid container spacing={2} sx={{pl:7}}>
            <Grid item xs={4}>
              <Skeleton variant="rounded" width="100%" height={20} />
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="rounded" width="100%" height={20} />
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="rounded" width="100%" height={20} />
            </Grid>
          </Grid>
        </React.Fragment>
      </CardContent>
    </Card>
  );
}

export default function CardNotificationSkeleton(props) {
  return (
    <div>
      <CustomCardSkeleton small={props.small} />
    </div>
  );
}
