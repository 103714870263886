import React, { useEffect } from "react";
import { IconButton, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import MuiAlert from "@mui/material/Alert";
import CustomTextField from "components/shared/CustomTextField";
import { dateProp } from "utils/Util";

const TerminateEmployee = (props) => {
  const { close, empDetails, updateTerminate } = props;
  const [terminateDate, setTerminateDate] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const dateProps = dateProp();

  useEffect(() => {
    if (empDetails.date_terminated) {
      setTerminateDate(empDetails.date_terminated);
    }
  }, []);

  const handleTerminate = () => {
    setError(null);
    if (!terminateDate) {
      setError("Please select date");
      return;
    }
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}termination/${empDetails.id}`, {
        date: terminateDate,
      })
      .then((res) => {
        setOpen(true);
        updateTerminate(res.data.results);
        // setTimeout(() => {
        //   close();
        // }, 5000);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        close();
      });
  };

  const handleChange = (e) => {
    setTerminateDate(e.target.value);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Dialog
      onClose={close}
      aria-labelledby="customized-dialog-title"
      open={true}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className="bg-ligh-blue">Terminate Employee</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={close}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        {open && (
          <MuiAlert
            variant="filled"
            severity="success"
            sx={{ width: "100%", mb: 2 }}
            onClose={handleClose}
          >
            The employee's termination has been processed.
          </MuiAlert>
        )}
        {error && (
          <MuiAlert
            variant="filled"
            severity="error"
            sx={{ width: "100%", mb: 2 }}
          >
            {error}
          </MuiAlert>
        )}

        <CustomTextField
          type={"date"}
          size="small"
          required
          name="terminateDate"
          onKeyDown={(e) => e.preventDefault()}
          label="Terminate Test Demo's employment as of"
          value={terminateDate || ""}
          // onChange={(value) => setTerminateDate(value)}
          onChange={(e) => handleChange(e)}
          // fullWidth
          sx={{ width: "50%" }}
          {...dateProps}
        />
      </DialogContent>
      <DialogActions sx={{ mr: 2, mb: 1 }}>
        <Button
          sx={{ width: "100px" }}
          size="small"
          variant="outlined"
          onClick={close}
        >
          Cancel
        </Button>
        <LoadingButton
          sx={{ width: "100px" }}
          size="small"
          loading={loading}
          variant="contained"
          onClick={() => handleTerminate()}
        >
          Terminate
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default TerminateEmployee;
