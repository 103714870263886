import React, { useState, useRef, useCallback } from "react";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  MenuItem,
  Box,
  Chip,
  TextField,
  Select,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import FolderOffIcon from "@mui/icons-material/FolderOff";
import { useSelector } from "react-redux";
import axios from "axios";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import SearchIcon from "@mui/icons-material/Search";

export default function DownloadFile() {
  const gridRef = useRef();
  const [loading, setLoading] = React.useState(false);
  const [downloadErrors, setDownloadErrors] = useState({});
  const [colData, setColData] = useState([]);
  const [rowdata, setRowData] = useState([]);
  const [error, setError] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [gridApi, setGridApi] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [filters, setFilters] = React.useState({
    position: [],
    employmentType: [],
    rosterDate: "",
    teams: [],
    // interfaces: [],
    employeeFields: true,
    territoryFields: true,
    isPrimaryContacts: false,
    isActive: false,
    isVacant: false,
    isTerminatedEmployee: false,
  });
  const PositionOptions = useSelector(
    (state) => state.employees.PositionOptions,
  );
  const EmploymentTypeOptions = useSelector(
    (state) => state.employees.EmploymentTypeOptions,
  );
  const TeamOptions = useSelector((state) => state.employees.TeamOptions);
  // const InterfaceOptions = useSelector(
  //   (state) => state.employees.InterfaceOptions,
  // );

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
    gridApi.setQuickFilter(e.target.value);
  };

  const handleCheckbox = (e) => {
    const { name, checked } = e.target;
    setFilters({ ...filters, [name]: checked });
    if (checked) setDownloadErrors({ ...downloadErrors, [name]: false });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFilters({
      ...filters,
      [name]: typeof value === "string" ? value.split(",") : value,
    });
    if (value) setDownloadErrors({ ...downloadErrors, [name]: false });
  };

  const handleDelete = (value, name) => {
    const newFilters = { ...filters };
    const index = newFilters[name].indexOf(value);
    if (index > -1) {
      newFilters[name].splice(index, 1);
    }
    setFilters(newFilters);
  };

  const pagination = true;
  const paginationPageSize = 25;

  const generateReport = () => {
    setSearchTerm("");
    setDataLoaded(false);
    setRowData(null);
    setColData(null);
    if (
      !Object.values(filters).some(
        (value) => typeof value === "boolean" && value,
      ) &&
      !Object.values(filters).some((value) => {
        if (Array.isArray(value)) {
          return value.length > 0;
        } else if (typeof value === "string") {
          return value.trim() !== "";
        }
        return false;
      })
    ) {
      setError("Please ensure that at least one field is filled.");
      setRowData(null);
      setColData(null);
      return;
    } else {
      setError("");
    }

    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}report/download`, filters)
      .then((response) => {
        setDataLoaded(true);
        const columnNames = response.data.results[0];
        const columnData = Object.keys(columnNames).map((key) => ({
          headerName: key,
          field: key,
          width: 90,
          filter: "agTextColumnFilter",
          floatingFilter: false,
          resizable: true,
          sortable: true,
        }));
        setColData(columnData);
        setRowData(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const reset = () => {
    setError("");
    setFilters({
      position: [],
      employmentType: [],
      rosterDate: "",
      teams: [],
      // interfaces: [],
      employeeFields: true,
      territoryFields: true,
      isPrimaryContacts: false,
      isActive: false,
      isVacant: false,
      isTerminatedEmployee: false,
    });
  };

  const exportData = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  return (
    <>
      <Card className="report-card">
        <CardContent>
          <Grid container spacing={4}>
            <Grid item md={3}>
              <Typography className="card-title no-padding">
                Select Fields
              </Typography>
              <FormGroup sx={{ mt: 1 }}>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Employee Fields"
                  name="employeeFields"
                  checked={filters.employeeFields}
                  onChange={handleCheckbox}
                />
                <FormControlLabel
                  control={<Checkbox />}
                  name="territoryFields"
                  label="Territory Fields"
                  checked={filters.territoryFields}
                  onChange={handleCheckbox}
                />
              </FormGroup>
            </Grid>
            <Grid item md={9}>
              <Typography className="card-title no-padding">
                Apply Filters
              </Typography>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item md={8.5}>
                  <Grid container spacing={2}>
                    {/* <Grid item md={6}>
                      <CustomTextField
                        type={"date"}
                        size="small"
                        name="rosterDate"
                        label="Roster Date"
                        inputProps={{
                          max: '9999-12-31',
                          maxLength: 10,
                        }}
                        value={filters.rosterDate}
                        onChange={(e) => handleInputChange(e)}
                        fullWidth
                      />
                    </Grid> */}
                    <Grid item md={6}>
                      {/* <InputLabel id="form-Teams">Teams</InputLabel> */}
                      <Select
                        // labelId="form-teams"
                        // label="Teams"
                        name="teams"
                        id="teams"
                        value={filters.teams}
                        onChange={($e) => handleInputChange($e)}
                        size="small"
                        fullWidth
                        multiple
                        select
                        input={
                          <TextField
                            variant="outlined"
                            id="teams"
                            label="Teams"
                            children={<></>}
                          />
                        }
                        renderValue={(selected) => (
                          <div>
                            {selected.map((teamId) => {
                              const selectedTeam = TeamOptions.find(
                                (option) => option.id === teamId,
                              );
                              return (
                                <Chip
                                  sx={{ backgroundColor: "#e8edf3" }}
                                  onMouseDown={(event) => {
                                    event.stopPropagation();
                                  }}
                                  key={teamId}
                                  size="small"
                                  label={
                                    selectedTeam
                                      ? selectedTeam.territory_name
                                      : ""
                                  }
                                  onDelete={() => handleDelete(teamId, "teams")}
                                />
                              );
                            })}
                          </div>
                        )}
                      >
                        {TeamOptions.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.territory_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item md={6} id="position">
                      <Select
                        name="position"
                        id="position"
                        multiple
                        value={filters.position}
                        onChange={($e) => handleInputChange($e)}
                        size="small"
                        fullWidth
                        select
                        input={
                          <TextField
                            variant="outlined"
                            id="position"
                            label="Position"
                            children={<></>}
                          />
                        }
                        renderValue={(selected) => (
                          <div>
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((teamId) => {
                                const selectedTeam = PositionOptions.find(
                                  (option) => option.id === teamId,
                                );
                                return (
                                  <Chip
                                    sx={{ backgroundColor: "#e8edf3" }}
                                    onMouseDown={(event) => {
                                      event.stopPropagation();
                                    }}
                                    key={teamId}
                                    size="small"
                                    label={
                                      selectedTeam ? selectedTeam.name : ""
                                    }
                                    onDelete={() =>
                                      handleDelete(teamId, "position")
                                    }
                                  />
                                );
                              })}
                            </Box>
                          </div>
                        )}
                      >
                        {PositionOptions &&
                          PositionOptions.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </Grid>
                    <Grid item md={6}>
                      <Select
                        label="Employment Type"
                        name="employmentType"
                        value={filters.employmentType}
                        onChange={($e) => handleInputChange($e)}
                        size="small"
                        fullWidth
                        multiple
                        select
                        input={
                          <TextField
                            variant="outlined"
                            label="Employment Type"
                            children={<></>}
                          />
                        }
                        renderValue={(selected) => (
                          <div>
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((teamId) => {
                                const selectedTeam = EmploymentTypeOptions.find(
                                  (option) => option.id === teamId,
                                );
                                return (
                                  <Chip
                                    sx={{ backgroundColor: "#e8edf3" }}
                                    onMouseDown={(event) => {
                                      event.stopPropagation();
                                    }}
                                    key={teamId}
                                    size="small"
                                    label={
                                      selectedTeam ? selectedTeam.type : ""
                                    }
                                    onDelete={() =>
                                      handleDelete(teamId, "employmentType")
                                    }
                                  />
                                );
                              })}
                            </Box>
                          </div>
                        )}
                      >
                        {EmploymentTypeOptions &&
                          EmploymentTypeOptions.map((option) => (
                            <MenuItem key={option.type} value={option.id}>
                              {option.type}
                            </MenuItem>
                          ))}
                      </Select>
                    </Grid>
                    {/* <Grid item md={6}>
                      <Select
                        label="Interfaces"
                        name="interfaces"
                        value={filters.interfaces}
                        onChange={($e) => handleInputChange($e)}
                        size="small"
                        fullWidth
                        multiple
                        select
                        input={
                          <TextField
                            variant="outlined"
                            label="Interfaces"
                            children={<></>}
                          />
                        }
                        // renderValue={(selected) =>
                        //   renderChip(selected, "interfaces")
                        // }
                        renderValue={(selected) => (
                          <div>
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((teamId) => {
                                const selectedTeam = InterfaceOptions.find(
                                  (option) => option.id === teamId,
                                );
                                return (
                                  <Chip
                                    sx={{ backgroundColor: "#e8edf3" }}
                                    onMouseDown={(event) => {
                                      event.stopPropagation();
                                    }}
                                    key={teamId}
                                    size="small"
                                    label={
                                      selectedTeam ? selectedTeam.name : ""
                                    }
                                    onDelete={() =>
                                      handleDelete(teamId, "interfaces")
                                    }
                                  />
                                );
                              })}
                            </Box>
                          </div>
                        )}
                      >
                        {InterfaceOptions.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid> */}
                  </Grid>
                </Grid>
                <Grid item md={3.5}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      name="isActive"
                      checked={filters.isActive}
                      onChange={handleCheckbox}
                      label="Active Only"
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      name="isVacant"
                      checked={filters.isVacant}
                      onChange={handleCheckbox}
                      label="Include Vacant Territories"
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      name="isPrimaryContacts"
                      checked={filters.isPrimaryContacts}
                      onChange={handleCheckbox}
                      label="Primary Address/Phone Only"
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      name="isTerminatedEmployee"
                      checked={filters.isTerminatedEmployee}
                      onChange={handleCheckbox}
                      label="Terminated Employees"
                    />
                  </FormGroup>
                </Grid>
              </Grid>

              <Grid
                container
                justifyContent={"flex-end"}
                spacing={1}
                alignItems={"center"}
                sx={{ mt: 2 }}
              >
                {error && (
                  <Grid item sm={7}>
                    <Typography sx={{ color: "red", textAlign: "end" }}>
                      {error}
                    </Typography>
                  </Grid>
                )}

                <Grid item>
                  <Button
                    size="small"
                    sx={{ width: "100px" }}
                    variant="outlined"
                    onClick={reset}
                  >
                    Reset
                  </Button>
                </Grid>
                <Grid item>
                  <LoadingButton
                    // sx={{ width: "100px" }}
                    size="small"
                    loading={loading}
                    variant="contained"
                    onClick={() => generateReport()}
                  >
                    Generate Report
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {dataLoaded &&
        (rowdata?.length === 0 || rowdata?.length === undefined ? (
          <div style={{ textAlign: "center", margin: "50px 0", color: "grey" }}>
            <FolderOffIcon size="medium" />
            <Typography variant="h5">No data found</Typography>
          </div>
        ) : (
          <Card className="report-card" sx={{ mt: 1 }}>
            <CardContent>
              <Grid
                display={"flex"}
                justifyContent={"space-between"}
                sx={{ mb: 1 }}
              >
                <Typography className="card-title no-padding">
                  Results Table
                </Typography>
                <Stack spacing={2} direction="row" justifyContent="flex-end">
                  <TextField
                    size="small"
                    type="search"
                    label="Search"
                    value={searchTerm}
                    onChange={handleChange}
                    sx={{ width: 250 }}
                    InputProps={{
                      endAdornment: <SearchIcon />,
                    }}
                  />
                  <Button
                    size="small"
                    sx={{ width: "70px", mb: 1 }}
                    variant="contained"
                    color="info"
                    onClick={exportData}
                    startIcon={<DownloadForOfflineIcon />}
                  >
                    csv
                  </Button>
                </Stack>
              </Grid>

              <div className="table-container ag-theme-alpine ag-theme-alpine--compact">
                <AgGridReact
                  // gridOptions={gridOptions}
                  columnDefs={colData}
                  rowData={rowdata}
                  headerHeight={35}
                  rowHeight={30}
                  pagination={pagination}
                  paginationPageSize={paginationPageSize}
                  onGridReady={onGridReady}
                  // domLayout="autoWidth"
                  // domLayout="autoHeight"
                  ref={gridRef}
                />
              </div>
            </CardContent>
          </Card>
        ))}
    </>
  );
}
