import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Skeleton from "@mui/material/Skeleton";

function CustomCardSkeleton(props) {
  return (
    <Card className="notes-card" sx={{ width: "100%", m: 1 }}>
      <CardHeader
        avatar={
          <Skeleton
            animation="wave"
            variant="circular"
            width={32}
            height={32}
          />
        }
        title={
          <Skeleton
            animation="wave"
            height={10}
            width="80%"
            style={{ marginBottom: 6 }}
          />
        }
        subheader={<Skeleton animation="wave" height={10} width="40%" />}
      />
    </Card>
  );
}

export default function NotesSkeleton(props) {
  return (
    <div>
      <CustomCardSkeleton small={props.small} />
    </div>
  );
}
