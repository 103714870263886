import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Skeleton from "@mui/material/Skeleton";

function CustomCardSkeleton(props) {
  return (
    <Card className="report-card" sx={{width: '100%'}}>
      <CardHeader
        avatar={
          <Skeleton
            animation="wave"
            variant="circular"
            width={40}
            height={40}
          />
        }
        title={
          <Skeleton
            animation="wave"
            height={10}
            width="80%"
            style={{ marginBottom: 6 }}
          />
        }
        subheader={<Skeleton animation="wave" height={10} width="40%" />}
      />
      <Skeleton
        sx={{ height: props.small ? 100 : 230 }}
        animation="wave"
        variant="rectangular"
      />
    </Card>
  );
}

export default function CardSkeleton(props) {
  return (
    <div>
      <CustomCardSkeleton small={props.small} />
    </div>
  );
}
