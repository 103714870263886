import React from "react";
import {
  Grid,
  Typography,
  MenuItem,
  Box,
  FormGroup,
  FormHelperText,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import dayjs from "dayjs";
import {
  updateErrorList,
} from "store/employees/employeesSlice";
import CustomTextField from "components/shared/CustomTextField";
import LocalizedFormat from "dayjs/plugin/localizedFormat"; // Import the LocalizedFormat plugin
import { dateProp } from "utils/Util";
import InfoIcon from "@mui/icons-material/Info";

dayjs.extend(LocalizedFormat);

const EmployeeDetails = (props) => {
  const { empDetails, update, isEditMode } = props;
  const dispatch = useDispatch();
  const dateProps = dateProp();

  const SuffixOptions = useSelector((state) => state.employees.SuffixOptions);
  const empErrors = useSelector((state) => state.employees.empErrors);
  const PositionOptions = useSelector(
    (state) => state.employees.PositionOptions,
  );
  const DepartmentOptions = useSelector(
    (state) => state.employees.DepartmentOptions,
  );
  const ManagerOptions = useSelector(
    (state) => state.employees.ManagerTypeoptions,
  );
  const EmploymentTypeOptions = useSelector(
    (state) => state.employees.EmploymentTypeOptions,
  );

  // let isDefaultEmptype = EmploymentTypeOptions.find(
  //   (el) => el.is_default == true,
  // );
  // useEffect(() => {
  //   let isDefaultEmptype = empOptions.find((el) => el.is_default == true);
  //   update({ ...empDetails, employmentType: isDefaultEmptype?.id });

  //   dispatch(
  //     updateEmployeeDetails({
  //       employmentType: isDefaultEmptype?.id,
  //     }),
  //   );
  // }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    update({ ...empDetails, [name]: value });
    if (value) {
      const newEmpErrors = { ...empErrors, [name]: false };
      dispatch(updateErrorList(newEmpErrors));
    }
  };

  const handleCheckbox = (e) => {
    const { name, checked } = e.target;
    update({ ...empDetails, [name]: checked ? "Active" : "Inactive" });
    if (checked) {
      const newEmpErrors = { ...empErrors, [name]: false };
      dispatch(updateErrorList(newEmpErrors));
    }
  };

  const handleDateInputChange = (e) => {
    const { name, value } = e.target;
    update({ ...empDetails, [name]: value });
  };
  const handleStartdateChange = (e) => {
    // let val = dayjs(value).format("YYYY-MM-DD");
    const { name, value } = e.target;
    update({ ...empDetails, startDate: value });
    if (value) {
      const newEmpErrors = { ...empErrors, [name]: false };
      dispatch(updateErrorList(newEmpErrors));
    }
  };

  // const addCrmLogin = () => {
  //   const newLogin = {
  //     id: UID(),
  //     login: "",
  //     status: "Inactive",
  //   };

  //   update({ ...empDetails, crmLogins: [...empDetails.crmLogins, newLogin] });
  // };

  // const handleLoginChange = (e, id) => {
  //   const { name, value, checked } = e.target;
  //   const newLogins = empDetails.crmLogins.map((el) => {
  //     if (el.id === id) {
  //       return {
  //         ...el,
  //         [name]: name === "status" ? (checked ? "Active" : "Inactive") : value,
  //       };
  //     }
  //     dispatch(upDateLoginData(value));
  //     return el;
  //   });
  //   update({ ...empDetails, crmLogins: newLogins });
  // };

  // const deleteLoginRow = (id) => {
  //   const newLogins = empDetails.crmLogins.filter((el) => el.id !== id);
  //   update({ ...empDetails, crmLogins: newLogins });
  // };

  return (
    <div>
      <Grid container direction="column" spacing={1} sx={{ mb: 4 }}>
        <Grid item>
          <Typography variant="h6"> Employee Details</Typography>
        </Grid>
        <Grid item>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <CustomTextField
                  required
                  label="First Name"
                  name="firstName"
                  value={empDetails.firstName || ""}
                  onChange={($e) => handleInputChange($e)}
                  size="small"
                  fullWidth
                  xs={4}
                  error={Boolean(empErrors.firstName)}
                  helperText={
                    empErrors.firstName ? "This field is required" : ""
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomTextField
                  label="Middle Name"
                  name="middleName"
                  size="small"
                  value={empDetails.middleName || ""}
                  onChange={($e) => handleInputChange($e)}
                  xs={4}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomTextField
                  label="Last Name"
                  name="lastName"
                  value={empDetails.lastName || ""}
                  onChange={($e) => handleInputChange($e)}
                  size="small"
                  xs={4}
                  fullWidth
                  required
                  error={Boolean(empErrors.lastName)}
                  helperText={
                    empErrors.lastName ? "This field is required" : ""
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomTextField
                  label="Preferred Name"
                  name="preferredName"
                  value={empDetails.preferredName || ""}
                  onChange={($e) => handleInputChange($e)}
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomTextField
                  label="Suffix"
                  name="suffix"
                  value={empDetails.suffix || ""}
                  onChange={($e) => handleInputChange($e)}
                  size="small"
                  fullWidth
                  defaultValue={""}
                  select
                >
                  {SuffixOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </CustomTextField>
              </Grid>
              {/* <Grid item xs={12} sm={6} md={4}>
                <CustomTextField
                  size="small"
                  label="Date of Birth"
                  name="dateOfBirth"
                  {...dateProps}
                  value={empDetails.dateOfBirth}
                  onChange={(e) => handleDateInputChange(e)}
                  fullWidth
                />
              </Grid> */}
              <Grid item xs={12} sm={6} md={4}>
                <CustomTextField
                  required
                  label="Position"
                  name="position"
                  value={empDetails.position || ""}
                  onChange={($e) => handleInputChange($e)}
                  size="small"
                  fullWidth
                  select
                  inputProps={{
                    max: "9999-12-31",
                    maxLength: 10,
                  }}
                  defaultValue={""}
                  error={Boolean(empErrors.position)}
                  helperText={
                    empErrors.position ? "This field is required" : ""
                  }
                >
                  {PositionOptions &&
                    PositionOptions.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                </CustomTextField>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomTextField
                  label="Department"
                  name="department"
                  value={empDetails.department || ""}
                  onChange={($e) => handleInputChange($e)}
                  size="small"
                  fullWidth
                  select
                  required
                  error={Boolean(empErrors.department)}
                  helperText={
                    empErrors.department ? "This field is required" : ""
                  }
                >
                  {DepartmentOptions &&
                    DepartmentOptions.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                </CustomTextField>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomTextField
                  label="Employment Type"
                  name="employmentType"
                  // defaultValue="full-time"
                  value={empDetails.employmentType ?? ""}
                  // value={empDetails.employmentType || EmploymentTypeOptions[0].id}
                  onChange={($e) => handleInputChange($e)}
                  size="small"
                  fullWidth
                  select
                  required
                  error={Boolean(empErrors.employmentType)}
                  helperText={
                    empErrors.employmentType ? "This field is required" : ""
                  }
                >
                  {EmploymentTypeOptions &&
                    EmploymentTypeOptions.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.type}
                      </MenuItem>
                    ))}
                </CustomTextField>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomTextField
                  label="Employee#"
                  name="employeeNo"
                  value={empDetails.employeeNo || ""}
                  onChange={($e) => handleInputChange($e)}
                  size="small"
                  fullWidth
                  disabled={isEditMode === "edit" ? true : false}
                  required
                  error={Boolean(empErrors.employeeNo)}
                  helperText={
                    empErrors.employeeNo ? "This field is required" : ""
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomTextField
                  label="Job Title"
                  name="jobTitle"
                  value={empDetails.jobTitle || ""}
                  onChange={($e) => handleInputChange($e)}
                  size="small"
                  fullWidth
                  // error={empErrors.jobTitle}
                  // helperText={
                  //   empErrors.jobTitle ? "This field is required" : ""
                  // }
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <CustomTextField
                  label="Manager"
                  name="Manager"
                  value={empDetails.Manager || ""}
                  onChange={($e) => handleInputChange($e)}
                  size="small"
                  select
                  defaultValue={""}
                  fullWidth
                  // error={empErrors.Manager}
                  // helperText={empErrors.Manager ? "This field is required" : ""}
                >
                  {ManagerOptions &&
                    ManagerOptions.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                </CustomTextField>
              </Grid>
              {/* <Grid item xs={12} sm={6} md={4}>
                <CustomTextField
                  label="Network username"
                  name="networkUsername"
                  value={empDetails.networkUsername}
                  onChange={($e) => handleInputChange($e)}
                  size="small"
                  fullWidth
                />
              </Grid> */}

              <Grid item xs={12} sm={6} md={4}>
                <CustomTextField
                  size="small"
                  name="startDate"
                  // required
                  // label="Start Date"
                  label={
                    <div
                      style={{
                        width: "90px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        gap: 4,
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        style={{
                          display: "inline-block",
                          verticalAlign: "middle",
                        }}
                      >
                        Start Date
                      </Typography>
                      <Typography variant="subtitle1" className="emp-startdate">
                        *
                      </Typography>
                      <Tooltip title="The start date of the position">
                        <InfoIcon
                          color="action"
                          style={{ cursor: "pointer", fontSize: 18 }}
                        />
                      </Tooltip>
                    </div>
                  }
              
                  value={empDetails.startDate ?? ""}
                  // value={dayjs(empDetails.startDate)}
                  onChange={(e) => handleStartdateChange(e)}
                  fullWidth
                  onKeyDown={(e) => e.preventDefault()}
                  {...dateProps}
                  error={Boolean(empErrors.startDate)}
                  helperText={
                    empErrors.startDate ? "This field is required" : ""
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomTextField
                  size="small"
                  name="hire_date"
                  // label="Hire Date"
                  onKeyDown={(e) => e.preventDefault()}
                  label={
                    <div
                      style={{
                        width: "90px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: 4,
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        style={{
                          display: "inline-block",
                          verticalAlign: "middle",
                        }}
                      >
                        Hire Date
                      </Typography>
                      <Tooltip title="When did the employment start">
                        <InfoIcon
                          color="action"
                          fontSize="10px"
                          sx={{ cursor: "pointer", fontSize: 18 }}
                        />
                      </Tooltip>
                    </div>
                  }
                  // InputProps={{
                  //   endAdornment: (
                  //     <InputAdornment position="end">
                  //       <Tooltip title="When did you begin your employment">
                  //         <InfoIcon
                  //           color="action"
                  //           fontSize="10px"
                  //           sx={{ ml: -1, mr: -1, cursor: "pointer" }}
                  //         />
                  //       </Tooltip>
                  //     </InputAdornment>
                  //   ),
                  // }}
                  {...dateProps}
                  value={empDetails.hire_date ?? ""}
                  // value={dayjs(empDetails.startDate)}
                  onChange={(e) => handleDateInputChange(e)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                {/* <CustomTextField
                  label="Job Code"
                  type="number"
                  name="job_code"
                  value={empDetails.job_code ?? ""}
                  onChange={($e) => handleInputChange($e)}
                  size="small"
                  fullWidth
                /> */}
              </Grid>
              <Grid item xs={12} sm={6} md={8}>
                <Box sx={{ display: "flex" }}>
                  <FormGroup
                    row
                    required
                    sx={{ alignItems: "center" }}
                    error={empErrors?.status?.toString()}
                  >
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Active"
                      size="small"
                      name="status"
                      id="active"
                      required
                      checked={Boolean(
                        empDetails.status === "Active" ? true : false,
                      )}
                      onChange={($e) => handleCheckbox($e)}
                      // error={Boolean(empErrors.status)}
                    />
                    <FormControlLabel
                      name="insider"
                      control={<Checkbox />}
                      label="Home Office"
                      size="small"
                      id="insider"
                      checked={Boolean(
                        empDetails.insider === "Active" ? true : false,
                      )}
                      onChange={($e) => handleCheckbox($e)}
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Field Based"
                      size="small"
                      id="field_based"
                      name="field_based"
                      checked={Boolean(
                        empDetails.field_based === "Active" ? true : false,
                      )}
                      onChange={($e) => handleCheckbox($e)}
                    />
                    {empErrors.status && (
                      <FormHelperText className="text-error">
                        Active is required
                      </FormHelperText>
                    )}
                  </FormGroup>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>

      {/* <Grid container direction="column" spacing={1} sx={{ mb: 4 }}>
        <Grid item>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            CRM Login
          </Typography>
        </Grid>
        <Grid item>
          {empDetails?.crmLogins &&
            empDetails?.crmLogins.map((el, index) => (
              <Grid
                container
                direction="row"
                spacing={2}
                alignItems={"center"}
                key={index}
                sx={{ mb: 1 }}
              >
                <Grid item md={4}>
                  <CustomTextField
                    label="Login"
                    size="small"
                    fullWidth
                    name="login"
                    required
                    onChange={($e) => handleLoginChange($e, el.id)}
                    value={el.login}
                  />
                </Grid>
                <Grid item md={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={el.status === "Active" ? true : false}
                        name="status"
                        required
                        onChange={($e) => handleLoginChange($e, el.id)}
                        label="Active"
                        // inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Active"
                  />
                </Grid>
                <Grid item md={1}>
                  <IconButton
                    size="small"
                    onClick={() => deleteLoginRow(el.id)}
                  >
                    <CancelOutlinedIcon color="error" />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
        </Grid>
        <Grid item>
          <Button
            color="info"
            variant="outlined"
            size="small"
            onClick={() => addCrmLogin()}
          >
            Add Login
          </Button>
        </Grid>
      </Grid> */}
    </div>
  );
};

export default EmployeeDetails;
