import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  FormGroup,
  FormControlLabel,
  IconButton,
  Checkbox,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Box } from "@mui/system";
import { UID } from "utils/Util";
import { useDispatch, useSelector } from "react-redux";
import CustomTextField from "components/shared/CustomTextField";
import { updateErrorList } from "store/employees/employeesSlice";

const ContactDetails = (props) => {
  const { empDetails, update } = props;

  const dispatch = useDispatch();
  const EmailTypeOptions = useSelector(
    (state) => state.employees.EmailTypeOptions,
  );
  const PhoneTypeOptions = useSelector(
    (state) => state.employees.PhoneTypeOptions,
  );
  const AddressTyoeOptions = useSelector(
    (state) => state.employees.AddressTyoeOptions,
  );
  const StateOptions = useSelector((state) => state.employees.StateOptions);
  const CountryOptions = useSelector((state) => state.employees.CountryOptions);
  const empErrors = useSelector((state) => state.employees.empErrors);
  const [selectedEmail, setSelectedEmail] = useState([]);

  const validateZip = (name, id) => {
    let error = false;
    if (
      empErrors?.addressErrors &&
      empErrors?.addressErrors[id]?.indexOf(name) > -1
    ) {
      error = true;
    }
    return error;
  };

  const checkEmailError = (errorType, id) => {
    return (
      empErrors?.emailErrors && empErrors?.emailErrors[id]?.includes(errorType)
    );
  };

  const handleEmailChange = (e, id) => {
    let { name, value } = e.target;

    const emailsData = empDetails.emails.map((el) => {
      if (el.id === id) {
        setSelectedEmail(value);

        return {
          ...el,
          [name]: value,
        };
        // setValidEmail(!validEmail(value))
      }
      return el;
    });

    update({ ...empDetails, emails: emailsData });
    if (value && empErrors.emailErrors?.[id]) {
      const newEmpErrors = {
        ...empErrors,
        emailErrors: {
          ...empErrors.emailErrors,
          [id]: [
            ...empErrors.emailErrors[id].filter(
              (el) => el !== (name === "value" ? "email" : name),
            ),
          ],
        },
      };
      let arr = {};
      Object.entries(newEmpErrors.emailErrors).forEach(([key, value]) => {
        if (value.length) {
          arr[key] = value;
        }
      });
      dispatch(updateErrorList({ ...newEmpErrors, emailErrors: arr }));
    }
  };
  const formatPhoneNumber = (value) => {
    const val = value.replace(/\D/g, "");

    if (val?.length === 10) {
      const formattedVal = val.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
      return formattedVal;
    }
    return val;
  };

  const handlePhoneChange = (e, id) => {
    const { name, value } = e.target;
    const phoneData = empDetails.phoneNumbers.map((el) => {
      if (el.id === id) {
        return {
          ...el,
          [name]: name === "phoneType" ? value : formatPhoneNumber(value),
        };
      }
      return el;
    });
    update({ ...empDetails, phoneNumbers: phoneData });

    if (value && empErrors.phoneErrors?.[id]) {
      const newEmpErrors = {
        ...empErrors,
        phoneErrors: {
          ...empErrors.phoneErrors,
          [id]: [
            ...empErrors.phoneErrors[id].filter(
              (el) => el !== (name === "value" ? "phone" : name),
            ),
          ],
        },
      };
      let arr = {};
      Object.entries(newEmpErrors.phoneErrors).forEach(([key, value]) => {
        if (value.length) {
          arr[key] = value;
        }
      });
      dispatch(updateErrorList({ ...newEmpErrors, phoneErrors: arr }));
    }
  };

  const handleAddessChange = (e, id) => {
    const { name, value } = e.target;
    const addressData = empDetails.addresses.map((el) => {
      if (el.id === id) {
        return {
          ...el,
          [name]: name === "zip" ? value?.replace(/\D/g, "") : value,
        };
      }
      return el;
    });
    // if (name === "country") setSelectedCountry(value);
    update({ ...empDetails, addresses: addressData });
    if (value && empErrors.addressErrors?.[id]) {
      const newEmpErrors = {
        ...empErrors,
        addressErrors: {
          ...empErrors.addressErrors,
          [id]: [...empErrors.addressErrors[id].filter((el) => el !== name)],
        },
      };
      let arr = {};
      Object.entries(newEmpErrors.addressErrors).forEach(([key, value]) => {
        if (value.length) {
          arr[key] = value;
        }
      });
      // validateZip()
      dispatch(updateErrorList({ ...newEmpErrors, addressErrors: arr }));
    }
  };

  const handleCheckboxChange = (e, id) => {
    const { name, checked } = e.target;
    handleAddessChange(
      { target: { name, value: checked ? "Active" : "Inactive" } },
      id,
    );
  };

  const deletePhoneRow = (id) => {
    const phoneData = empDetails.phoneNumbers.filter((el) => el.id !== id);
    update({ ...empDetails, phoneNumbers: phoneData });
  };

  const deleteEmailRow = (id) => {
    const emailData = empDetails.emails.filter((el) => el.id !== id);
    update({ ...empDetails, emails: emailData });
  };
  const deleteAddressRow = (id) => {
    const addressData = empDetails.addresses.filter((el) => el.id !== id);
    update({ ...empDetails, addresses: addressData });
  };

  const addNewEmail = () => {
    const row = {
      id: UID(),
      emailType: "",
      value: "",
      status: "Active",
    };
    update({ ...empDetails, emails: [...empDetails.emails, row] });
  };

  const addNewPhoneNumber = () => {
    const row = {
      id: UID(),
      phoneType: "",
      value: "",
      status: "Active",
    };
    update({ ...empDetails, phoneNumbers: [...empDetails.phoneNumbers, row] });
  };

  const addNewaddress = () => {
    const row = {
      id: UID(),
      addressesType: "",
      isPrimary: false,
      isActive: false,
      addressLine1: "",
      addressLine2: "",
      city: "",
      timezone: "",
      state: "",
      zip: "",
      country: "",
    };
    update({ ...empDetails, addresses: [...empDetails.addresses, row] });
  };

  const checkPhoneError = (errorType, id) => {
    return (
      empErrors?.phoneErrors && empErrors?.phoneErrors[id]?.includes(errorType)
    );
  };
  const AddressValidate = (name, id) => {
    let error = false;
    if (
      empErrors?.addressErrors &&
      empErrors?.addressErrors[id]?.indexOf(name) > -1
    ) {
      error = true;
    }
    return error;
  };

  const fetchDomain = (id) => {
    if(id){
      let val = EmailTypeOptions?.find(el => el.id === id)
      return val.domain
    }
    
  }

  return (
    <div className="card-right-content">
      <Typography variant="h6">Contact Details</Typography>

      <Box className="email-section" sx={{ mb: 2 }}>
        <Typography sx={{ fontWeight: 500, pb: 1 }}>Email</Typography>
        {empDetails?.emails?.map((el, index) => (
          <Grid
            container
            direction="row"
            spacing={1}
            key={index}
            sx={{ mb: 2 }}
          >
            <Grid item md={5}>
              <CustomTextField
                required
                label="Email Type"
                size="small"
                fullWidth
                inputProps={{
                  pattern: "^[a-zA-Z0-9]*$",
                }}
                select
                value={el.emailType || ""}
                name="emailType"
                onChange={($e) => handleEmailChange($e, el.id)}
                error={checkEmailError("emailType", el.id)}
                helperText={
                  checkEmailError("emailType", el.id)
                    ? "This field is required"
                    : ""
                }
              >
                {EmailTypeOptions.filter(
                  (option) => option.id !== selectedEmail.id,
                ).map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.type}
                  </MenuItem>
                ))}
              </CustomTextField>
            </Grid>
            <Grid item md={6}>
              <CustomTextField
                required
                label="Email Address"
                name="value"
                type="email"
                value={el.value || ""}
                onChange={($e) => handleEmailChange($e, el.id)}
                size="small"
                fullWidth
                error={
                  checkEmailError("email", el.id) ||
                  checkEmailError("emailCheck", el.id) ||
                  checkEmailError("invalidDomain", el.id)
                }
                helperText={`${
                  checkEmailError("email", el.id)
                    ? "This field is required"
                    : ""
                } ${
                  checkEmailError("emailCheck", el.id) ? "Invalid Format" : ""
                } ${
                  checkEmailError("invalidDomain", el.id)
                    ? `Email should contain ${fetchDomain(el.emailType)}`
                    : ""
                }
                `}
              />
            </Grid>
            <Grid item md={1}>
              {index !== 0 && (
                <IconButton size="small" onClick={() => deleteEmailRow(el.id)}>
                  <CancelOutlinedIcon color="error" />
                </IconButton>
              )}
            </Grid>
          </Grid>
        ))}
        {empDetails?.emails?.length < 2 ? (
          <Button
            size="small"
            color="info"
            variant="outlined"
            onClick={addNewEmail}
          >
            Add New
          </Button>
        ) : (
          ""
        )}
      </Box>

      <Box className="Phone-section" sx={{ mb: 2 }}>
        <Typography sx={{ fontWeight: 500, pb: 1 }}>Phone</Typography>
        {empDetails?.phoneNumbers?.map((el, index) => (
          <Grid
            container
            direction="row"
            spacing={1}
            key={index}
            sx={{ mb: 2 }}
          >
            <Grid item md={5}>
              <CustomTextField
                label="Phone Type"
                size="small"
                value={el.phoneType || ""}
                fullWidth
                select
                name="phoneType"
                inputProps={{ maxLength: 14 }}
                onChange={($e) => handlePhoneChange($e, el.id)}
                error={checkPhoneError("phoneType", el.id)}
                helperText={
                  checkPhoneError("phoneType", el.id)
                    ? "This field is required"
                    : ""
                }
              >
                {PhoneTypeOptions.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.type}
                  </MenuItem>
                ))}
              </CustomTextField>
            </Grid>
            <Grid item md={6}>
              <CustomTextField
                // type={'number'}
                label="Phone #"
                value={el.value || ""}
                size="small"
                // type="tel"
                fullWidth
                inputProps={{ maxLength: 10 }}
                placeholder="___-___-____"
                name="value"
                onChange={($e) => handlePhoneChange($e, el.id)}
                error={
checkPhoneError("phone", el.id) ||
                  checkPhoneError("phoneCheck", el.id)
                }
                helperText={`${
                  checkPhoneError("phone", el.id)
                    ? "This field is required"
                    : ""
                } ${
                  checkPhoneError("phoneCheck", el.id) === true
                    ? "A phone number must contain 10 digits"
                    : ""
                } `}
              />
            </Grid>
            <Grid item md={1}>
              {index !== 0 && (
                <IconButton size="small" onClick={() => deletePhoneRow(el.id)}>
                  <CancelOutlinedIcon color="error" />
                </IconButton>
              )}
            </Grid>
          </Grid>
        ))}
        {empDetails?.phoneNumbers?.length < 3 ? (
          <Button
            size="small"
            color="info"
            variant="outlined"
            onClick={addNewPhoneNumber}
          >
            Add New
          </Button>
        ) : (
          ""
        )}
      </Box>

      <Box className="Address-section" sx={{ mb: 2 }}>
        <Typography sx={{ fontWeight: 500, mb: 2 }}>Address</Typography>
        {empDetails?.addresses?.map((el, index) => (
          <Grid
            key={index}
            container
            direction="column"
            spacing={2}
            className="address-card"
          >
            <Grid item md={11}>
              {index !== 0 && (
                <IconButton
                  size="small"
                  sx={{
                    position: "absolute",
                    marginTop: "-30px",
                    marginRight: "5px",
                    right: 0,
                  }}
                  onClick={() => deleteAddressRow(el.id)}
                >
                  <CancelOutlinedIcon color="error" />
                </IconButton>
              )}
              <CustomTextField
                required
                label="Address type"
                size="small"
                value={el.addressesType || ""}
                select
                fullWidth
                name="addressesType"
                onChange={($e) => handleAddessChange($e, el.id)}
                error={AddressValidate("addressesType", el.id)}
                helperText={
                  AddressValidate("addressesType", el.id)
                    ? "This field is required"
                    : ""
                }
              >
                {AddressTyoeOptions.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.type}
                  </MenuItem>
                ))}
              </CustomTextField>
            </Grid>
            <Grid item md={12}>
              {index === 0 && (
                <FormGroup
                  required
                  sx={{ alignItems: "flex-start" }}
                  error={AddressValidate("isActive", el.id)?.toString()}
                >
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Primary"
                    required
                    checked={el.isPrimary === "Active" ? true : false}
                    name="isPrimary"
                    onChange={($e) => handleCheckboxChange($e, el.id)}
                    error={AddressValidate("isPrimary", el.id)?.toString()}
                  />
                  {AddressValidate("isPrimary", el.id) && (
                    <FormHelperText className="text-error">
                      These fields are required
                    </FormHelperText>
                  )}
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Active"
                    size="small"
                    required
                    checked={el.isActive === "Active" ? true : false}
                    name="isActive"
                    onChange={($e) => handleCheckboxChange($e, el.id)}
                    error={AddressValidate("isActive", el.id)?.toString()}
                  />
                  {AddressValidate("isActive", el.id) && (
                    <FormHelperText className="text-error">
                      These fields are required
                    </FormHelperText>
                  )}
                </FormGroup>
              )}
            </Grid>
            <Grid item md={12}>
              <CustomTextField
                required
                label="Addressline 1"
                value={el.addressLine1 || ""}
                size="small"
                fullWidth
                name="addressLine1"
                onChange={($e) => handleAddessChange($e, el.id)}
                error={AddressValidate("addressLine1", el.id)}
                helperText={
                  AddressValidate("addressLine1", el.id)
                    ? "This field is required"
                    : ""
                }
              />
            </Grid>
            <Grid item md={12}>
              <CustomTextField
                label="Addressline 2"
                value={el.addressLine2 || ""}
                size="small"
                fullWidth
                name="addressLine2"
                onChange={($e) => handleAddessChange($e, el.id)}
              />
            </Grid>
            <Grid item md={12}>
              <CustomTextField
                sx={{ width: "70%" }}
                label="City"
                value={el.city || ""}
                size="small"
                fullWidth
                required
                name="city"
                onChange={($e) => handleAddessChange($e, el.id)}
                error={AddressValidate("city", el.id)}
                helperText={
                  AddressValidate("city", el.id) ? "This field is required" : ""
                }
              />
            </Grid>
            <Grid item md={12}>
              <CustomTextField
                sx={{ width: "70%" }}
                label="Time zone"
                value={el.timezone || ""}
                size="small"
                fullWidth
                // required
                name="timezone"
                onChange={($e) => handleAddessChange($e, el.id)}
              />
            </Grid>
            <Grid item md={12}>
              <CustomTextField
                required
                sx={{ width: "70%" }}
                select
                label="Country"
                variant="outlined"
                value={el.country || ""}
                size="small"
                fullWidth
                name="country"
                onChange={($e) => handleAddessChange($e, el.id)}
                error={AddressValidate("country", el.id)}
                helperText={
                  AddressValidate("country", el.id)
                    ? "This field is required"
                    : ""
                }
              >
                {CountryOptions.map((option) => (
                  <MenuItem key={option.code} value={option.code}>
                    {option.code}
                    {"-"}
                    {option.name}
                  </MenuItem>
                ))}
              </CustomTextField>
            </Grid>
            <Grid item md={12}>
              <Box display="flex" gap={1}>
                <Grid item md={5}>
                  <CustomTextField
                    required
                    label="Zip"
                    value={el.zip || ""}
                    size="small"
                    fullWidth
                    inputProps={{ maxLength: 5 }}
                    name="zip"
                    onChange={($e) => handleAddessChange($e, el.id)}
                    error={
                      AddressValidate("zip", el.id) ||
                      validateZip("zipCheck", el.id)
                    }
                    helperText={`${
                      AddressValidate("zip", el.id)
                        ? "This field is required"
                        : ""
                    } ${
                      validateZip("zipCheck", el.id) === true
                        ? "A zip code must contain 5 digits"
                        : ""
                    } `}
                  />
                </Grid>
                <Grid item md={7}>
                  <CustomTextField
                    required
                    // sx={{ width: "70%" }}
                    label="State"
                    value={el.state || ""}
                    size="small"
                    select
                    fullWidth
                    name="state"
                    onChange={($e) => handleAddessChange($e, el.id)}
                    error={AddressValidate("state", el.id)}
                    helperText={
                      AddressValidate("state", el.id)
                        ? "This field is required"
                        : ""
                    }
                  >
                    {StateOptions.map((option) => (
                      <MenuItem key={option.code} value={option.code}>
                        {option.code}
                        {"-"}
                        {option.name}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        ))}
        {empDetails?.addresses?.length < 2 ? (
          <Button
            size="small"
            color="info"
            variant="outlined"
            onClick={addNewaddress}
          >
            Add New
          </Button>
        ) : (
          ""
        )}
      </Box>
    </div>
  );
};

export default ContactDetails;
