import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { colorPalletes } from "utils/Util";

export default function BarChart(props) {
  const { data } = props;

  let xAxisValues =
    data && data.length > 0 ? data.map((item) => item.name) : [];

  const options = {
    chart: {
      type: "bar",
      //   inverted: true,
      height: 230,
    },
    accessibility: {
      enabled: false, 
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "",
    },
    legends: {
      enabled: false,
    },
    xAxis: {
      categories: [...xAxisValues],
      crosshair: true,
      labels: {
        style: {
          fontSize: "11px",
        },
      },
      // title: {
      //   text: "Employment Types",
      //   style: {
      //     fontSize: "11px",
      //     fontWeight: "bold",
      //   },
      // },
    },
    colors: colorPalletes(),
    yAxis: {
      min: 0,
      gridLineWidth: 0,
      labels: {
        style: {
          fontSize: "11px",
        },
      },
      title: {
        text: "",
      },
    },
    // tooltip: {
    //   headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    //   pointFormat:
    //     '<tr><td style="color:{series.color};padding:0">{series.position}: </td>' +
    //     '<td style="padding:0"><b>{point.count} </b></td></tr>',
    //   footerFormat: "</table>",
    //   shared: true,
    //   useHTML: true,
    // },
    plotOptions: {
      // column: {
      //   // pointPadding: 0.2,
      //   borderWidth: 0,
      // },
      series: {
        dataLabels: {
          enabled: true,
          style: {
            textOutline: "white",
          },
        },
      },
    },
    series: [
      {
        showInLegend: false,
        data: data,
      },
    ],
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
}
