import React, { useState } from "react";
import {
  Button,
  IconButton,
  Dialog,
  Typography,
  Stack,
  Alert,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useDispatch } from "react-redux";
import {
  deleteAddressTypeOptions,
  deleteCountryOptions,
  deleteDepartmentOptions,
  deleteEmailTypeOptions,
  deleteEmploymentTypeOptions,
  deleteInterfaceOptions,
  deletePhoneTypeOptions,
  deletePositionOptions,
  deleteStateOptions,
  deleteTerritoryLevelAdminOptions,
  deleteAssignmentTypeOptions,
  deleteTimeOffTypeOptions,
} from "store/employees/employeesSlice";

const DeleteModal = (props) => {
  const {
    close,
    selectedItem,
    data,
    update,
    selectedItemName,
    selectedParentData,
  } = props;
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();

  const closeDialog = () => {
    close();
  };

  const dispatchDeleteFunction = (data) => {
    const deleteFunctionMap = {
      Position: deletePositionOptions,
      Department: deleteDepartmentOptions,
      "Employment Type": deleteEmploymentTypeOptions,
      'Email Type': deleteEmailTypeOptions,
      'Phone Type': deletePhoneTypeOptions,
      'Address Type': deleteAddressTypeOptions,
      State: deleteStateOptions,
      Country: deleteCountryOptions,
      Interface: deleteInterfaceOptions,
      "Territory Levels": deleteTerritoryLevelAdminOptions,
      'Assignment Type': deleteAssignmentTypeOptions,
      "Time Off": deleteTimeOffTypeOptions,
    };

    const deleteFunction = deleteFunctionMap[selectedItemName];

    if (deleteFunction) {
      dispatch(deleteFunction(data));
    } else {
      console.log("Invalid selected item:", selectedItemName);
    }
  };

  const handleDelete = () => {
    let ID =
      selectedItemName === "State" || selectedItemName === "Country"
        ? data?.code
        : selectedItemName === "Territory Levels"
        ? selectedParentData?.id
        : data?.id;
    setLoading(true);
    axios
      .delete(`${process.env.REACT_APP_API_URL}${selectedItem}/${ID}`)
      .then((response) => {
        dispatchDeleteFunction(ID);
        update(Math.random());
        close();
      })
      .catch((error) => {
        const errMessage = error?.response?.data?.message;
        setErrorMessage(errMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={true}
    >
      <IconButton
        aria-label="close"
        onClick={() => {
          closeDialog();
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "gray",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent className="custom-model-content-height">
        <div className="delete-record">
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <div className="admin-delete-button">
              <DeleteForeverIcon sx={{ color: "white" }} />
            </div>

            <Typography variant="h5">Are you sure?</Typography>
            <Typography variant="subtitle2" textAlign="center">
              You are about to delete {selectedItemName}. This action cannot be
              undone.
            </Typography>
            {errorMessage ? (
              <Alert
                severity="error"
                sx={{ width: "300px", display: "flex", alignItems: "center" }}
              >
                <strong>{errorMessage}</strong>
              </Alert>
            ) : (
              ""
            )}
          </Stack>
        </div>
      </DialogContent>
      <DialogActions
        sx={{ mr: 2, mb: 1, justifyContent: "space-evenly" }}
      >
        <Button
          size="small"
          variant="outlined"
          color="primary"
          sx={{ width: "120px", borderRadius: "20px" }}
          onClick={() => {
            closeDialog();
          }}
        >
          Take me back
        </Button>

        <LoadingButton
          sx={{ width: "120px", borderRadius: "20px" }}
          size="small"
          variant="contained"
          loading={loading}
          disabled ={errorMessage ? true:false}
          
          color="error"
          onClick={handleDelete}
        >
          Yes, Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
