import React, { useMemo } from "react";
import { Grid, IconButton, Typography, Button, Box, Chip } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import CustomTextField from "../../shared/CustomTextField";
import { agGridConfigs } from "utils/Util";
import EditIcon from "@mui/icons-material/Edit";
import AddAssignment from "../../employees/AddAssignment";
import { useSelector } from "react-redux";

const pagination = false;
const paginationPageSize = 5;

export default function GenericTerritoryForm(props) {
  const {
    action,
    state,
    setState,
    selectedRow,
    closeAssignmentModel,
    openAssignmentModel,
    handleInputChange,
    endDateBeforeStartDate,
    employeeOptions,
    topNode,
  } = props;
  const tableConfig = useMemo(() => {
    return agGridConfigs;
  }, []);

  const [territoryAssignments, setTerritoryAssignments] = React.useState({});
  const formErrors = useSelector(
    (state) => state.employees.terrModuleFormErrors,
  );

  const columnDefs = [
    {
      headerName: "ID",
      field: "employee",
      sortable: true,
      width: 70,
      resizable: true,
    },
    {
      headerName: "Name",
      sortable: true,
      width: 100,
      resizable: true,
      cellRenderer: (props) => {
        return (
          <div>
            {props.data.first_name} {props.data.last_name}
          </div>
        );
      },
    },
    {
      headerName: "Position",
      field: "position_abbr",
      sortable: true,
      width: 80,
      resizable: true,
    },
    {
      headerName: "Start Date",
      field: "start_date",
      width: 80,
      resizable: true,
    },
    {
      headerName: "End Date",
      field: "end_date",
      sortable: true,
      width: 80,
      resizable: true,
    },
    {
      headerName: "",
      width: 50,
      cellRenderer: (props) => {
        return (
          <div>
            <IconButton
              color="info"
              variant="outlined"
              size="small"
              onClick={() => {
                openAssignmentModel("Edit");
                setTerritoryAssignments(props.data);
              }}
            >
              <EditIcon />
            </IconButton>
          </div>
        );
      },
    },
  ];

  const handleSaveAssignment = async (initialData, action) => {
    let assignments = [];
    if (action === "Add") {
      assignments = [...state.territory_assignments, initialData];
    } else {
      assignments = state.territory_assignments.map((i) => {
        if (i.id === initialData.id) {
          return {
            ...initialData,
          };
        }
        return i;
      });
    }
    setState({
      ...state,
      territory_assignments: assignments,
    });
  };

  const checkEnddateFromParent = () => {
    if (state?.territory_assignments?.length > 0) {
      const date = new Date();
      const currentDate = date.toISOString().split("T")[0];

      const mostRecentAssignment = state.territory_assignments.reduce(
        (mostRecent, assignment) => {
          if (assignment.end_date > mostRecent.end_date) {
            return assignment;
          } else {
            return mostRecent;
          }
        },
        state.territory_assignments[0],
      );

      return mostRecentAssignment.end_date > currentDate;
    } else {
      return false;
    }
  };

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <Box sx={{ display: "inline-flex", alignItems: "center" }}>
            <Typography variant="caption" sx={{ fontWeight: 500, mr: 1 }}>
              ID:
            </Typography>
            <Chip label={action === "Add" ? "NEW" : state.id} size="small" />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Box sx={{ display: "inline-flex", alignItems: "center" }}>
            <Typography variant="caption" sx={{ fontWeight: 500, mr: 1 }}>
              Level:
            </Typography>
            <Chip label={selectedRow.level} size="small" />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <CustomTextField
            required
            label="Number"
            name="territory_number"
            value={state.territory_number || ""}
            onChange={handleInputChange}
            size="small"
            fullWidth
            
            xs={4}
            error={formErrors.territory_number}
            helperText={
              formErrors.territory_number ? "This field is required" : ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <CustomTextField
            required
            label="Name"
            name="territory_name"
            value={state.territory_name || ""}
            onChange={handleInputChange}
            size="small"
            fullWidth
            xs={4}
            error={formErrors.territory_name}
            helperText={
              formErrors.territory_name ? "This field is required" : ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <CustomTextField
            type={"date"}
            key={state.start_date}
            size="small"
            name="start_date"
            required
            format="YYYY-MM-DD"
            label="Territory Begin Date"
            onKeyDown={(e) => e.preventDefault()}
            inputProps={{
              max: "9999-12-31",
              maxLength: 10,
            }}
            {...(state.start_date && { value: state.start_date })}
            onChange={handleInputChange}
            fullWidth
            error={formErrors.start_date}
            helperText={formErrors.start_date ? "This field is required" : ""}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <CustomTextField
            type={"date"}
            key={state.end_date}
            size="small"
            name="end_date"
            format="YYYY-MM-DD"
            label="Territory End Date"
            onKeyDown={(e) => e.preventDefault()}
            inputProps={{
              max: "9999-12-31",
              maxLength: 10,
            }}
            {...(state.end_date && { value: state.end_date })}
            onChange={handleInputChange}
            fullWidth
            error={endDateBeforeStartDate}
            helperText={
              endDateBeforeStartDate ? "End Date must be after Start Date" : ""
            }
          />
        </Grid>
      </Grid>

      {action === "Edit" && (
        <>
          <Grid
            container
            direction="row"
            justifyContent={"space-between"}
            alignItems="center"
            sx={{ pb: 1, borderRadius: "8px 8px 0 0", mt: 2 }}
          >
            <Grid item>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                Employee Assignments
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="info"
                variant="outlined"
                size="small"
                onClick={() => openAssignmentModel("Add")}
                disabled={
                  state?.territory_assignments?.length === 0
                    ? false
                    : state?.territory_assignments?.some(
                          (el) => el.end_date === null,
                        )
                      ? true
                      : // : false
                        checkEnddateFromParent()
                }
              >
                Add Assignment
              </Button>
            </Grid>
          </Grid>
          {state.openModal && (
            <AddAssignment
              data={selectedRow}
              action={state.action}
              close={closeAssignmentModel}
              allAssignments={state}
              update={handleSaveAssignment}
              readOnlyFields={true}
              employeeOptions={employeeOptions}
              selectedTerritoryAssignment={territoryAssignments}
              topNode={topNode}
            />
          )}
          <Grid container>
            <Grid item xs={12}>
              <div className="small ag-theme-alpine ag-theme-alpine--compact territory-grid">
                <AgGridReact
                  style={{
                    height: 40,
                    overflow: "auto",
                    backgrondColor: "red",
                  }}
                  headerHeight={35}
                  defaultColDef={tableConfig}
                  rowData={state.territory_assignments}
                  columnDefs={columnDefs}
                  pagination={pagination}
                  paginationPageSize={paginationPageSize}
                  rowHeight={30}
                  domLayout="autoHeight"
                />
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </form>
  );
}
