import React from "react";
import { Box, Card, Divider, Grid, Typography } from "@mui/material";

const StatsCard = (props) => {
  const { title, stats } = props;

  return (
    <div>
      <Card className="stats-card report-card">
        <Grid container>
          <Grid item xs={3}  >
            <Box className="stats-titles">
              <Typography className="stats-title">{title}</Typography>
              <Typography className="stats-count">{stats.total}</Typography>
            </Box>
          </Grid>
          <Grid item xs={0.5}>
            <Divider
              variant="inset"
              orientation="vertical"
              sx={{ marginLeft: 0 }}
            />
          </Grid>

          <Grid item xs={8.5} sx={{ p:'5px' }} >
            <Grid container spacing={2}  alignItems={"center"}>
              <Grid item xs={4}>
                <Typography variant="subtitle1" className="records right">Active</Typography>
              </Grid>
              <Grid item xs={2} className="records">
                <div className="stats-chip pst">{stats.active}</div>
              </Grid>
              <Grid item xs={2}>
                <div className="stats-chip ngt">{stats.inactive}</div>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1" className="records left">Inactive</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 1 }} alignItems={"center"}>
              <Grid item xs={4}>
                <Typography variant="subtitle1" className="records right">Assigned</Typography>
              </Grid>
              <Grid item xs={2}>
                <div className="stats-chip pst">{stats.assigned}</div>
              </Grid>
              <Grid item xs={2}>
                <div className="stats-chip ngt">{stats.unassigned}</div>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1" className="records left">Unassigned</Typography>
              </Grid>
            </Grid>
            {title === "Employees" && (
              <Grid container spacing={2} sx={{ mt: 1 }} alignItems={"center"}>
                <Grid item xs={4}>
                  {title === "Employees" ? (
                    <Typography variant="subtitle1" className="records right">Time Off</Typography>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={2}>
                  {title === "Employees" ? (
                    <div className="stats-chip pst">{stats.timeOff}</div>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={2}>
                  <div className="stats-chip pst">{stats.terminated}</div>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="subtitle1" className="records left">Terminated</Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default StatsCard;
