import React, { useEffect, useState } from "react";
import { Grid, Button, IconButton, Dialog, MenuItem } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import CustomTextField from "components/shared/CustomTextField";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import { dateProp } from "utils/Util";

const AddTimeOff = (props) => {
  const TimeOffTypeOptions = useSelector(
    (state) => state.employees.TimeOffTypeOptions,
  );
  const { data, close, actionTimeOff, update, empDetails, empTerminated } =
    props;
  const [initialData, setInitialData] = useState({});
  const [assignmentErrors, setAssignmentErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [TerritoryOptions, setTerritoryOptions] = useState([]);
  const dateProps = dateProp();
  useEffect(() => {
    let initialData = {
      team: "",
      territoryId: "",
      type: "",
      startDate: "",
      endDate: "",
    };
    if (actionTimeOff === "edit") {
      initialData = {
        team: data.team_id,
        territoryId: data.territory_id,
        type: data.type_id,
        startDate: data.startDate,
        endDate: data.endDate,
      };
      fetchTerritoriesByTeam(data?.team_id);
    }
    setInitialData(initialData);
  }, [actionTimeOff]);

  const uniqueTeamData = empDetails?.territoryAssignment?.reduce(
    (acc, item) => {
      if (!acc.some((el) => el.team_id === item.team_id)) {
        acc.push(item);
      }
      return acc;
    },
    [],
  );
  const fetchTerritoriesByTeam = (teamId) => {
    const uniqueTerrData = empDetails?.territoryAssignment?.reduce(
      (acc, item) => {
        if (!acc.some((el) => el.territoryNo === item.territoryNo)) {
          acc.push(item);
        }
        return acc;
      },
      [],
    );

    let options = uniqueTerrData
      ?.filter((el) => el.team_id === teamId)
      .map((el) => ({
        label: `${el.territoryNo} - ${el.territory}`,
        value: el.territory_id,
      }));

    setTerritoryOptions(options);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInitialData({
      ...initialData,
      [name]: value,
    });
    if (name === "team") {
      fetchTerritoriesByTeam(value);
    }
    if (value) setAssignmentErrors({ ...assignmentErrors, [name]: false });
  };

  const validateForm = () => {
    let errors = {
      team: false,
      territoryId: false,
      type: false,
      startDate: false,
      // ICDate: false,
      // endDate:false
    };

    Object.entries(errors).forEach(([key, value]) => {
      if (initialData[key] === "") {
        errors[key] = true;
      }
    });

    if (initialData.endDate && initialData.startDate > initialData.endDate) {
      errors["endDate"] = true;
    }

    setAssignmentErrors(errors);
    return Object.values(errors).indexOf(true) > -1;
  };

  const SaveTimeoff = () => {
    if (validateForm()) {
      return;
    }

    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}time-off-territories?employee_id=${empDetails.id}`,
        initialData,
      )
      .then((response) => {
        update(response.data.results, actionTimeOff);
        close();
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoading(false);
      });
  };

  const updateTimoff = () => {
    if (validateForm()) {
      return;
    }
    setLoading(true);
    axios
      .put(
        `${process.env.REACT_APP_API_URL}time-off-territories/${data.id}?employee_id=${empDetails.id}`,
        initialData,
      )
      .then((response) => {
        update(response.data.results, actionTimeOff);
        close();
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <Dialog
        onClose={close}
        aria-labelledby="customized-dialog-title"
        open={true}
        // onBackdropClick={close}
        // hideBackdrop={true}
        // disableBackdropClick
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle className="bg-ligh-blue">
          {actionTimeOff === "edit"
            ? "Edit Territory Time Off"
            : "Add Territory Time Off"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={close}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Grid container direction={"column"} spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <CustomTextField
                    label="Team"
                    name="team"
                    variant="outlined"
                    size="small"
                    defaultValue={""}
                    fullWidth
                    required
                    select
                    value={initialData.team || ""}
                    onChange={($e) => handleInputChange($e)}
                    error={assignmentErrors.team}
                    helperText={
                      assignmentErrors.team ? "This field is required" : ""
                    }
                  >
                    {uniqueTeamData?.map((option) => (
                      <MenuItem key={option.team_id} value={option.team_id}>
                        {option.team}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                </Grid>

                <Grid item xs={6}>
                  <CustomTextField
                    label="Territory"
                    name="territoryId"
                    variant="outlined"
                    size="small"
                    defaultValue={""}
                    fullWidth
                    select
                    required
                    loading={loading}
                    value={initialData.territoryId || ""}
                    onChange={($e) => handleInputChange($e)}
                    error={assignmentErrors.territoryId}
                    helperText={
                      assignmentErrors.territoryId
                        ? "This field is required"
                        : ""
                    }
                  >
                    {TerritoryOptions?.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <CustomTextField
                    {...dateProps}
                    size="small"
                    name="startDate"
                    required
                    fullWidth
                    label="Start Date"
                    value={initialData.startDate}
                    onChange={(e) => handleInputChange(e)}
                    onKeyDown={(e) => e.preventDefault()}
                    error={assignmentErrors.startDate}
                    helperText={
                      assignmentErrors.startDate ? "This field is required" : ""
                    }
                  />
                </Grid>

                <Grid item xs={6}>
                  {/* <CustomTextField
                    type={"date"}
                    size="small"
                    name="ICDate"
                    required
                    label="IC Date"
                    value={initialData.ICDate}
                    onChange={(e) => handleInputChange(e)}
                    fullWidth
                    error={assignmentErrors.ICDate}
                    helperText={
                      assignmentErrors.ICDate ? "This field is required" : ""
                    }
                  /> */}

                  <CustomTextField
                    {...dateProps}
                    size="small"
                    name="endDate"
                    label="End Date"
                    value={initialData.endDate}
                    onChange={(e) => handleInputChange(e)}
                  onKeyDown={(e) => e.preventDefault()}
                    fullWidth
                    error={assignmentErrors.endDate}
                    helperText={
                      assignmentErrors.endDate
                        ? "End Date must be after Start Date"
                        : ""
                    }
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <CustomTextField
                    label="Type"
                    name="type"
                    variant="outlined"
                    size="small"
                    defaultValue={""}
                    fullWidth
                    required
                    select
                    value={initialData.type || ""}
                    onChange={($e) => handleInputChange($e)}
                    error={assignmentErrors.type}
                    helperText={
                      assignmentErrors.type ? "This field is required" : ""
                    }
                  >
                    {TimeOffTypeOptions?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.time_off_type_name}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions
          //  dividers
          sx={{ mr: 2, mb: 1 }}
        >
          <Button
            sx={{ width: "100px" }}
            size="small"
            variant="outlined"
            onClick={close}
          >
            Cancel
          </Button>
          <LoadingButton
            sx={{ width: "100px" }}
            size="small"
            variant="contained"
            loading={loading}
            onClick={() =>
              actionTimeOff === "edit" ? updateTimoff() : SaveTimeoff()
            }
            disabled={
              empDetails?.terminated_by === null &&
              empTerminated?.date_terminated === undefined
                ? false
                : true
            }
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddTimeOff;
