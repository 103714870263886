import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { MenuItem } from "@mui/material";
import { Grid, Typography, IconButton, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomTextField from "components/shared/CustomTextField";
import axios from "axios";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { dateProp, isFutureDate } from "utils/Util";

const ReasignEmployee = (props) => {
  const { close, empDetails, update } = props;
  const activeAssignment = empDetails.territoryAssignment.filter(
    (el) => !isFutureDate(el.endDate),
  );

  const teamOptions = useSelector((state) => state.employees.TeamOptions);
  const existingAssignments = teamOptions?.filter(
    (el) => activeAssignment[0]?.team !== el?.territory_name,
  );

  const TypeOptions = useSelector(
    (state) => state.employees.AssignmentTypeOptions,
  );
  const dateProps = dateProp();
  const [fromDetails, setFromDetails] = useState(activeAssignment);
  const [territoryOptions, setTerritoryOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reassignError, setReassignError] = useState({});
  const [initialData, setInitialData] = useState({
    team: "",
    territoryId: "",
    type: "",
    startDate: "",
    ICDate: "",
    endDate: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "team") {
      setInitialData({ ...initialData, [name]: value, territoryId: "" });
      if (value)
        setReassignError({
          ...reassignError,
          [name]: false,
          territoryId: false,
        });
    } else {
      setInitialData({ ...initialData, [name]: value });
      if (value) setReassignError({ ...reassignError, [name]: false });
    }

    if (name === "team") {
      fetchTerritoriesByTeam(value);
    }
  };

  const fetchTerritoriesByTeam = (teamId) => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}team-territories/${teamId}`)
      .then((response) => {
        modifyTerraOptions(response.data.results);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const modifyTerraOptions = (data) => {
    let finalTerraOptions = data?.map((el) => ({
      label: `${el.territory_name} - ${el.territory_number}`,
      value: el.id,
    }));
    setTerritoryOptions(finalTerraOptions);
  };

  const validateForm = () => {
    let errors = {
      team: false,
      territoryId: false,
      type: false,
      startDate: false,
      ICDate: false,
      endDate: false,
    };

    Object.entries(errors).forEach(([key, value]) => {
      if (initialData[key] === "" || initialData[key] === undefined) {
        errors[key] = true;
      }
    });

    if (initialData.startDate > initialData.ICDate) {
      errors["icDateBeforeStartdate"] = true;
    }
    if (initialData.ICDate > initialData.endDate) {
      errors["icDateafterEnddate"] = true;
    }

    setReassignError(errors);
    return Object.values(errors).indexOf(true) > -1;
  };

  const saveReassignData = (val) => {
    let newData = { ...empDetails };
    newData = { ...empDetails, territoryAssignment: val };
    update(newData);
  };

  const save = () => {
    if (validateForm()) {
      return;
    }
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}employee-reassignment?employee_id=${empDetails.id}`,
        initialData,
      )
      .then((response) => {
        saveReassignData(response.data.results);
        close();
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog
      onClose={close}
      aria-labelledby="customized-dialog-title"
      open={true}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className="bg-ligh-blue">Reassign Employee</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={close}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container spacing={3} direction={"column"}>
          <Grid item md={6}>
            <CustomTextField
              // placeholder="Reassign Test Demo as of"
              sx={{ width: "50%" }}
              label="Reassign Test Demo as of"
              name="endDate"
              variant="outlined"
              size="small"
              fullWidth
              required
              {...dateProps}
              value={initialData.endDate || ""}
              onChange={(e) => handleChange(e)}
              onKeyDown={(e) => e.preventDefault()}
              error={reassignError.endDate}
              helperText={reassignError.endDate ? "This field is required" : ""}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Grid container direction={"column"} spacing={3}>
                  <Grid item>
                    <Typography variant="subtitle2" sx={{ mb: 1 }}>
                      From:
                    </Typography>

                    <CustomTextField
                      label="Team"
                      name="team"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={fromDetails[0]?.team || ""}
                      onChange={(e) => setFromDetails(e.target.value)}
                      disabled
                    />
                  </Grid>
                  <Grid item>
                    <CustomTextField
                      label="Territory"
                      name="territory"
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      value={fromDetails[0]?.territory || ""}
                      onChange={(e) => setFromDetails(e.target.value)}
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item>
                    <CustomTextField
                      label="Type"
                      name="type"
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      value={fromDetails[0]?.type || ""}
                      onChange={(e) => setFromDetails(e.target.value)}
                      fullWidth
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container direction={"column"} spacing={3}>
                  <Grid item>
                    <Typography variant="subtitle2" sx={{ mb: 1 }}>
                      To:
                    </Typography>

                    <CustomTextField
                      label="Team"
                      name="team"
                      variant="outlined"
                      size="small"
                      fullWidth
                      required
                      select
                      value={initialData.team || ""}
                      onChange={($e) => handleChange($e)}
                      error={reassignError.team}
                      helperText={
                        reassignError.team ? "This field is required" : ""
                      }
                    >
                      {existingAssignments?.map((option) => (
                        <MenuItem key={option?.id} value={option?.id}>
                          {option?.territory_number}
                          {"-"}
                          {option?.territory_name}
                        </MenuItem>
                      ))}
                    </CustomTextField>
                  </Grid>
                  <Grid item>
                    <CustomTextField
                      label="Territory"
                      name="territoryId"
                      variant="outlined"
                      size="small"
                      fullWidth
                      required
                      select
                      value={initialData.territoryId || ""}
                      onChange={($e) => handleChange($e)}
                      error={reassignError?.territoryId}
                      helperText={
                        reassignError?.territoryId
                          ? "This field is required"
                          : ""
                      }
                    >
                      {territoryOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </CustomTextField>
                  </Grid>
                  <Grid item>
                    <CustomTextField
                      label="Type"
                      name="type"
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      select
                      value={initialData.type || ""}
                      onChange={($e) => handleChange($e)}
                      error={reassignError.type}
                      helperText={
                        reassignError.type ? "This field is required" : ""
                      }
                    >
                      {TypeOptions &&
                        TypeOptions.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.assignment_type_name}
                          </MenuItem>
                        ))}
                    </CustomTextField>
                  </Grid>
                  <Grid item>
                    <CustomTextField
                      label="Start Date"
                      name="startDate"
                      variant="outlined"
                      size="small"
                      fullWidth
                      required
                      {...dateProps}
                      value={initialData.startDate || ""}
                      onChange={(e) => handleChange(e)}
                      onKeyDown={(e) => e.preventDefault()}
                      error={reassignError.startDate}
                      helperText={
                        reassignError.startDate ? "This field is required" : ""
                      }
                    />
                  </Grid>
                  <Grid item>
                    <CustomTextField
                      label="IC Date"
                      name="ICDate"
                      variant="outlined"
                      value={initialData.ICDate || ""}
                      onChange={(e) => handleChange(e)}
                      onKeyDown={(e) => e.preventDefault()}
                      size="small"
                      fullWidth
                      required
                      {...dateProps}
                      error={
                        reassignError.ICDate ||
                        reassignError.icDateBeforeStartdate ||
                        reassignError.icDateafterEnddate
                      }
                      helperText={
                        reassignError.ICDate
                          ? "This field is required"
                          : reassignError.icDateBeforeStartdate
                            ? "Ensure that the IC Date comes after the Start Date"
                            : reassignError.icDateafterEnddate
                              ? "Ensure that the IC Date comes before the End Date"
                              : ""
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        // dividers
        sx={{ mr: 2, mb: 1 }}
      >
        <Button
          sx={{ width: "100px" }}
          size="small"
          variant="outlined"
          onClick={close}
          disabled={loading}
        >
          Cancel
        </Button>
        <LoadingButton
          sx={{ width: "100px" }}
          size="small"
          variant="contained"
          onClick={() => save()}
          loading={loading}
        >
          Reassign
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ReasignEmployee;
