import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch,} from "react-redux";
import axios from "axios";
import {
    upDateDepartmentOptions,
    upDatePositionOptions,
    upDateEmploymentTypeOptions,
    updateEmailTypeOptions,
    updatePhoneTypeOptions,
    updateCountryOptions,
    updateStateOptions,
    updateAddressTypeOptions,
    updateTeamOptions,
    updateInterfaceOptions,
    updateTerritoryLevelOptions,
    updateTerritoryLevelAdminOptions,
    updateAssignmentTypeOptions,
    updateTimeOffTypeOptions,
    updateManagerTypeOptions
  } from "store/employees/employeesSlice";

export default function ParentLayout() {
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      try {
        axios
          .get(`${process.env.REACT_APP_API_URL}email_type`)
          .then((response) => {
            dispatch(updateEmailTypeOptions(response.data.results));
          })
          .catch((err) => {
            console.log(err);
          });

        axios
          .get(`${process.env.REACT_APP_API_URL}phone_type`)
          .then((response) => {
            dispatch(updatePhoneTypeOptions(response.data.results));
          })
          .catch((err) => {
            console.log(err);
          });

        axios
          .get(`${process.env.REACT_APP_API_URL}address_type`)
          .then((response) => {
            dispatch(updateAddressTypeOptions(response.data.results));
          })
          .catch((err) => {
            console.log(err);
          });

        axios
          .get(`${process.env.REACT_APP_API_URL}state`)
          .then((response) => {
            dispatch(updateStateOptions(response.data.results));
          })
          .catch((err) => {
            console.log(err);
          });

        axios
          .get(`${process.env.REACT_APP_API_URL}country`)
          .then((response) => {
            dispatch(updateCountryOptions(response.data.results));
          })
          .catch((err) => {
            console.log(err);
          });

        axios
          .get(`${process.env.REACT_APP_API_URL}department`)
          .then((response) => {
            dispatch(upDateDepartmentOptions(response.data.results));
          })
          .catch((err) => {
            console.log(err);
          });

        axios
          .get(`${process.env.REACT_APP_API_URL}position`)
          .then((response) => {
            dispatch(upDatePositionOptions(response.data.results));
          })
          .catch((err) => {
            console.log(err);
          });

        await axios
          .get(`${process.env.REACT_APP_API_URL}employee_type`)
          .then((response) => {
            dispatch(upDateEmploymentTypeOptions(response.data.results));
          })
          .catch((err) => {
            console.log(err);
          });

        axios
          .get(`${process.env.REACT_APP_API_URL}team`)
          .then((response) => {
            dispatch(updateTeamOptions(response.data.results));
          })
          .catch((err) => {
            console.log(err);
          });

        axios
          .get(`${process.env.REACT_APP_API_URL}assignment_type`)
          .then((response) => {
            dispatch(updateAssignmentTypeOptions(response.data.results));
          })
          .catch((err) => {
            console.log(err);
          });

        axios
          .get(`${process.env.REACT_APP_API_URL}manager`)
          .then((response) => {
            dispatch(updateManagerTypeOptions(response.data.results));
          })
          .catch((err) => {
            console.log(err);
          });

        axios
          .get(`${process.env.REACT_APP_API_URL}time_off_type`)
          .then((response) => {
            dispatch(updateTimeOffTypeOptions(response.data.results));
          })
          .catch((err) => {
            console.log(err);
          });

        axios
          .get(`${process.env.REACT_APP_API_URL}interface`)
          .then((response) => {
            dispatch(updateInterfaceOptions(response.data.results));
          })
          .catch((err) => {
            console.log(err);
          });

        axios
          .get(`${process.env.REACT_APP_API_URL}territory-level`)
          .then((response) => {
            dispatch(updateTerritoryLevelOptions(response.data.results));
          })
          .catch((err) => {
            console.log(err);
          });
        axios
          .get(`${process.env.REACT_APP_API_URL}territory-level-admin`)
          .then((response) => {
            dispatch(updateTerritoryLevelAdminOptions(response.data.results));
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (<Outlet />);
}
