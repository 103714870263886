import React, { useEffect, useState } from "react";
import {
  Grid,
  Stack,
  Typography,
  Card,
  Box,
  Button,
  MenuItem,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { useSelector } from "react-redux";
import TerritoryIcon from "../assets/images/territory-bg.png";
import TerritoryTree from "components/territory_hierarchy/TerritoryTree";
import CustomTextField from "components/shared/CustomTextField";
import AddTeam from "components/territory_hierarchy/AddTeam";
import axios from "axios";
import CustomLoader from "components/shared/CustomLoader";
import HierarchyFormLayout from "components/territory_hierarchy/HierarchyFormLayout";
import CustomNotify from "components/shared/CustomNotify";

const TerritoryHierarchy = () => {
  const TeamOptions = useSelector((state) => state.employees.TeamOptions);
  const [action, setAction] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [teamModal, setTeamModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [masterData, setMasterData] = useState([]);
  const [expanded, setExpanded] = React.useState([]);
  const [heirarchyLevels, setHeirarchyLevels] = useState({});
  const [isSaved, setIsSaved] = useState(null);
  const [isExpand, setIsExpand] = useState(false);
  const [topNode, setTopNode] = useState(null);
  const [formLoader, setFormLoader] = useState(false);
  const [filter, setFilter] = useState({
    team: "",
    date: "",
  });

  const handleFilterChange = ($e) => {
    setFilter({ ...filter, [`${$e.target.name}`]: $e.target.value });
  };

  const applyFilters = () => {
    close();
    getTerritoryHeirachy(filter.team, filter.date);
  };

  const resetFilters = () => {
    setFilter({
      team: "",
      date: "",
    });
    getTerritoryHeirachy();
  };

  const addNewTeam = () => {
    setSelectedRow(null);
    setAction("Add");
    setTeamModel(true);
  };

  const handleSelNode = (node, action) => {
    setFormLoader(true);
    setTimeout(() => {
      setSelectedRow(node);
      setAction(action);
      setTopNode(null);
      let topID = null;
      const ids = masterData.map((el) => getIdsFromTree([el], []));
      if (ids) {
        ids.forEach((id) => {
          if (id.includes(node.id.toString())) {
            topID = id[0];
          }
        });
      }

      if (topID) {
        const topNode = masterData.find((el) => el.id === parseInt(topID));
        setTopNode(topNode);
      }
      setFormLoader(false);
    }, 700);
  };

  const close = () => {
    setSelectedRow(null);
    setAction(null);
  };

  const getTerritoryHeirachy = (team, date) => {
    setLoading(true);
    let url = `${process.env.REACT_APP_API_URL}territory-hierarchy`;
    if (team || date) {
      url = `${url}?team_id=${team}&date=${date}`;
    }
    axios
      .get(url)
      .then((response) => {
        const { territory_hierarchy, territory_overall_level } =
          response.data.results;
        let treeData = null;
        if (team || date) {
          treeData = [{ ...territory_hierarchy }];
        } else {
          treeData = territory_hierarchy;
        }
        setMasterData(treeData);
        setHeirarchyLevels(territory_overall_level);

        setIsExpand(true);
        updateExpandedValues(true, treeData);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getTerritoryHeirachy();
  }, []);

  const refreshHierarchy = () => {
    getTerritoryHeirachy();
    setIsSaved("success");
    close();
  };

  const closeTeamModel = (event, reason) => {
    if (reason && ["backdropClick", "escapeKeyDown"].indexOf(reason) !== -1) {
      return;
    }
    setTeamModel(false);
    setAction(null);
  };

  const getIdsFromTree = (tree, idList) => {
    tree.forEach((node) => {
      idList.push(node.id.toString());
      if (node.children) {
        getIdsFromTree(node.children, idList);
      }
    });
    return idList;
  };

  const handleExpandClick = (event) => {
    const val = event.target.checked;
    setIsExpand(val);

    updateExpandedValues(val, masterData);
  };

  const updateExpandedValues = (val, data) => {
    setExpanded((oldExpanded) => {
      if (val) {
        const ids = data.map((el) => getIdsFromTree([el], []));
        
        return [...new Set(ids.flat().filter((el) => el !== undefined))];
      } else {
        return [];
      }
    });
  };

  const saveTeamDetails = (data) => {
    setIsSaved(null);
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}team`, data)
      .then((response) => {
        refreshHierarchy();
        closeTeamModel();
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <div className="page-content">
      <div className="page-header">
        <Grid container direction="row" alignItems="center">
          <Grid item md={5} sm={6}>
            <Stack direction="row" alignItems="center">
              <Typography variant="h5">Territory Hierarchy</Typography>
              <img
                style={{ marginLeft: "3em", maxWidth: "200px" }}
                src={TerritoryIcon}
                alt="people"
              />
            </Stack>
          </Grid>
          <Grid item md={7} sm={6}>
            <Stack spacing={1} direction="row" justifyContent="flex-end">
              {/* <Button size="small" color="info" variant="outlined">
                Export
              </Button>
              <Button size="small" color="info" variant="outlined">
                Import
              </Button>
              <Button size="small" color="info" variant="contained">
                Publish
              </Button> */}
            </Stack>
          </Grid>
        </Grid>
      </div>
      <div className="page-content">
        {loading && <CustomLoader />}
        <Card className="custom-card">
          <Box className="card-header">
            <Grid container direction="row" alignItems="center" gap={2}>
              <Grid item>
                <CustomTextField
                  label="Select Team"
                  name="team"
                  size="small"
                  fullWidth
                  value={filter.team || ''}
                  sx={{ width: 200 }}
                  defaultValue={""}
                  select
                  onChange={($e) => handleFilterChange($e)}
                >
                  {TeamOptions.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.territory_name}
                    </MenuItem>
                  ))}
                </CustomTextField>
              </Grid>
              <Grid item>
                <Button
                  size="small"
                  color="info"
                  variant="contained"
                  onClick={() => applyFilters()}
                >
                  Filter
                </Button>
                <Button
                  size="small"
                  color="info"
                  variant="outlined"
                  sx={{ ml: 1 }}
                  onClick={() => resetFilters()}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </Box>
          <div className="card-content">
            <Grid container direction="row" gap={1}>
              <Grid item md={6.5} sm={12}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  sx={{ p: 1 }}
                >
                  <Grid item md={7} sm={8}>
                    <Typography variant="h6">Territory Hierarchy</Typography>
                  </Grid>
                  <Grid item md={5} sm={4} sx={{ textAlign: "right" }}>
                    {masterData.length > 0 && (
                      <FormControlLabel
                        control={
                          <Switch
                            size="small"
                            color="info"
                            checked={isExpand}
                            onChange={handleExpandClick}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Expand"
                      />
                    )}
                    <Button
                      size="small"
                      color="info"
                      sx={{ ml: 1 }}
                      variant="contained"
                      onClick={() => addNewTeam()}
                    >
                      Add Team
                    </Button>
                  </Grid>
                </Grid>
                {masterData.length > 0 && (
                  <div className="box-shadow fixed-scroll">
                    <TerritoryTree
                      expanded={expanded}
                      setExpanded={setExpanded}
                      data={masterData}
                      handleSelNode={handleSelNode}
                      heirarchyLevels={heirarchyLevels}
                      refreshHierarchy={refreshHierarchy}
                    />
                  </div>
                )}
              </Grid>
              {selectedRow && (
                <Grid item md={5.4} sm={12}>
                  <Box sx={{ width: "100%" }}></Box>
                  <HierarchyFormLayout
                    selectedRow={selectedRow}
                    action={action}
                    close={close}
                    loading={loading}
                    formLoader={formLoader}
                    setLoading={setLoading}
                    setIsSaved={setIsSaved}
                    refreshHierarchy={refreshHierarchy}
                    topNode={topNode}
                  />
                </Grid>
              )}
            </Grid>
          </div>
        </Card>
      </div>

      {isSaved === "success" && (
        <CustomNotify
          open={true}
          severity="success"
          message="The Details have been saved Successfully"
        />
      )}
      {isSaved === "error" && (
        <CustomNotify
          open={true}
          severity="error"
          message="Failed to save the Employee Details"
        />
      )}

      {teamModal && (
        <AddTeam
          data={selectedRow}
          action={action}
          loading={loading}
          close={closeTeamModel}
          update={saveTeamDetails}
        />
      )}
    </div>
  );
};

export default TerritoryHierarchy;
