import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Chip from "@mui/material/Chip";

export default function CustomMenu({ name, options }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = ($event) => {
    setAnchorEl($event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (item) => {
    let value;
    if (
      name === "Email Type" ||
      name === "Phone Type" ||
      name === "Address Type" || 
      name === "Employment Type"
    ) {
      value = item.type;
    } else if (name === "Position" || name === "Department" || name === "Manager") {
      value = item.name;
    } else {
      value = item.label;
    }
    handleClose();
    copyText(value);
  };

  const copyText = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert(`Copied: ${text}`);
      })
      .catch((error) => {
        console.error("Copy failed: ", error);
      });
  };

  return (
    <div>
      <Chip
        icon={
          <InfoOutlinedIcon
            className="info-icon"
            style={{ fontSize: "12px" }}
          />
        }
        label={name}
        size="small"
        onClick={handleClick}
        variant="outlined"
        className="import-chips"
        sx={{ borderRadius: "6px", m: 0.5 }}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{ sx: { minWidth: "80px" } }}
      >
        {options.map((option, index) => (
          <MenuItem key={index} onClick={() => handleMenuItemClick(option)}>
            {name === "Email Type" ||
            name === "Phone Type" ||
            name === "Address Type" ||
            name === "Employment Type"
              ? option.type
              : name === "Position" || name === "Department" || name === 'Manager'
              ? option.name
              : option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
