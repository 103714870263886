import React, { useEffect, useState } from "react";
import { TreeView, TreeItem } from "@mui/x-tree-view";
import axios from "axios";
import { Chip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FolderIcon from "@mui/icons-material/Folder";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const AdminTerritoryTree = (props) => {
  const { handleAddtem, handleEditItem, refresh, handleDeleteitem } = props;

  const [treeData, setTreeData] = useState([]);
  const [hoveredNode, setHoveredNode] = useState(null);
  const [expandedData, setExpandedData] = useState();

  const labelColorStyle = {
    color: "#27aae1",
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}territory-level-admin`)
      .then((res) => {
        setTreeData(res.data.results);
        setExpandedData(
          res.data.results.map((el) => el.id.toString()).slice(1),
        );
      })
      .catch((err) => console.log(err));
  }, [refresh]);

  const handleNodeMouseEnter = (node) => {
    setHoveredNode(node);
  };

  const handleNodeMouseLeave = () => {
    setHoveredNode(null);
  };

  const renderTree = (nodes) => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes?.id?.toString()}
      label={
        <div
          className="territory-levels"
          onMouseEnter={() => handleNodeMouseEnter(nodes)}
          onMouseLeave={handleNodeMouseLeave}
        >
          <FolderIcon sx={{ mr: 1 }} />
          <div style={{ marginRight: "8em" }}>{nodes.name}</div>

          {hoveredNode?.name === nodes?.name && (
            <div>
              <Chip
                size="small"
                icon={<AddIcon style={labelColorStyle} />}
                onClick={() => handleAddtem(nodes)}
                style={labelColorStyle}
                sx={{ p: 1, border: "1px solid #27aae1" }}
                label="Add Level"
                variant="outlined"
              />
              <Chip
                size="small"
                icon={<EditOutlinedIcon style={labelColorStyle} />}
                onClick={() => handleEditItem(nodes)}
                style={labelColorStyle}
                sx={{
                  ml: 1.5,
                  "& .MuiChip-icon": {
                    marginLeft: 1,
                  },
                  border: "1px solid #27aae1",
                }}
                label={null}
                variant="outlined"
              />
            </div>
          )}
        </div>
      }
    >
      {Array.isArray(nodes.children) && nodes.children.length > 0
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  const buildTree = (data, parentId) => {
    const children = data
      .filter((node) => node.parent_id === parentId)
      .map((node) => buildTree(data, node.id))
      .flat();

    return parentId !== null
      ? [{ ...data.find((node) => node.id === parentId), children }]
      : children;
  };

  const treeStructure = buildTree(treeData, treeData[1]?.id);

  return (
    <div>
      {expandedData?.length > 0 && (
        <TreeView
          defaultCollapseIcon={<ArrowDropDownRoundedIcon />}
          defaultExpandIcon={<ArrowRightRoundedIcon />}
          defaultExpanded={expandedData}
        >
          {treeStructure?.map((node) => renderTree(node))}
        </TreeView>
      )}
    </div>
  );
};

export default AdminTerritoryTree;
