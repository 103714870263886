import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  IconButton,
  Dialog,
  MenuItem,
  Typography,
  Tooltip,
  Alert,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import axios from "axios";
import CustomTextField from "components/shared/CustomTextField";
import { dateProp } from "utils/Util";
import InfoIcon from "@mui/icons-material/Info";

const EmployeeAssignment = (props) => {
  const { empDetails, data, close, action, update, empTerminated } = props;

  const AddTerritoryTypeOptions = useSelector(
    (state) => state.employees.AssignmentTypeOptions,
  );
  const [initialData, setInitialData] = useState({});
  const [loading, setLoading] = useState(false);
  const [assignmentErrors, setAssignmentErrors] = useState({});
  const TeamOptions = useSelector((state) => state.employees.TeamOptions);
  const [terraOptions, setTerraOptions] = useState([]);
  const [territoryDateLimits, setTerritoryDateLimits] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [typeOfAssignment, setTypeOfAssignment] = useState(null);
  const [latestAssignmentDates, setLatestAssignmentDates] = useState({});

  const dateProps = dateProp();
  let currentDate = new Date();
  let currentDateVal = currentDate.toISOString().split("T")[0];

  useEffect(() => {
    let initialData = {
      team: "",
      territoryId: "",
      type: "",
      startDate: "",
      ICDate: "",
      endDate: "",
    };
    if (action === "add") {
      setTerraOptions([]);
    }

    if (action === "edit") {
      initialData = {
        team: data.team_id,
        territoryId: data.territory_id,
        type: data.type_id,
        startDate: data.startDate,
        ICDate: data.ICDate,
        endDate: data.endDate,
      };
      fetchTerritoriesByTeam(data?.team_id, data.territory_id);
    }
    setInitialData(initialData);

    if (action === "edit") {
      fetchTeeritoryStartEndDates(data?.territoryNo);
      fetchTerritoryLatestAssignmentDates(data?.territory_id);
    }
    callTypeFunction();
  }, [action]);

  const fetchTeeritoryStartEndDates = (terrNo) => {
    setTerritoryDateLimits({});
    axios
      .get(
        `${process.env.REACT_APP_API_URL}territory_detail?territory_id=${terrNo}`,
      )
      .then((response) => {
        setTerritoryDateLimits(response.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const callTypeFunction = () => {
    let primaryObjects = empDetails?.territoryAssignment?.filter(
      (obj) =>
        obj.type === "Primary" &&
        (action === "edit" ? data.territory_id : initialData.territoryId) !==
          obj.territory_id &&
        (!obj.endDate || new Date(obj.endDate) > new Date()),
    );

    setTypeOfAssignment(primaryObjects?.length > 0 ? true : false);
  };

  const modifyTerraOptions = (data) => {
    let finalTerraOptions = data?.map((el) => ({
      label: `${el.territory_name} - ${el.territory_number}`,
      value: el.id,
    }));
    setTerraOptions(finalTerraOptions);
  };

  const fetchTerritoriesByTeam = (teamId, territoryId) => {
    setLoading(true);
    axios
      .get(
        territoryId
          ? `${process.env.REACT_APP_API_URL}team-territories/${teamId}?territory_id=${territoryId}`
          : `${process.env.REACT_APP_API_URL}team-territories/${teamId}`,
        // `${process.env.REACT_APP_API_URL}team-territories/${teamId}?territory_id=${territoryId}`,
      )
      .then((response) => {
        modifyTerraOptions(response.data.results);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchTerritoryDates = (territoryNo) => {
    const terrdate = terraOptions?.filter((el) => el.value === territoryNo);
    const terrval = terrdate[0]?.label?.split("-");
    const val = terrval[1]?.trim();
    fetchTeeritoryStartEndDates(val);
  };

  const fetchTerritoryLatestAssignmentDates = (territoryNo) => {
    setLatestAssignmentDates({});
    axios
      .get(
        `${process.env.REACT_APP_API_URL}territory-assignments/${territoryNo}/date_range?employee_id=${empDetails?.id}`,
      )
      .then((response) => {
        setLatestAssignmentDates(response.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "team") {
      setInitialData({ ...initialData, [name]: value, territoryId: "" });
      if (value)
        setAssignmentErrors({
          ...assignmentErrors,
          [name]: false,
          territoryId: false,
        });

      fetchTerritoriesByTeam(value);
    } else {
      setInitialData({ ...initialData, [name]: value });
      if (value) setAssignmentErrors({ ...assignmentErrors, [name]: false });
    }
    if (name === "territoryId") {
      fetchTerritoryDates(value);
      fetchTerritoryLatestAssignmentDates(value);
    }
  };

  const validateForm = () => {
    let errors = {
      team: false,
      territoryId: false,
      type: false,
      startDate: false,
      ICDate: false,
    };

    Object.entries(errors).forEach(([key, value]) => {
      if (initialData[key] === "" || initialData[key] === undefined) {
        errors[key] = true;
      }
    });

    // if (initialData.startDate > initialData.ICDate) {
    //   errors["icDateBeforeStartdate"] = true;
    // }

    if (initialData.endDate) {
      if (initialData.startDate > initialData.endDate) {
        errors["endDate"] = true;
      }

      // if (initialData.endDate < initialData.ICDate) {
      //   errors["icDateafterEnddate"] = true;
      // }

      if (initialData.endDate > territoryDateLimits?.end_date) {
        errors["enddateBeforeTerrEnddate"] = true;
      }
    }

    if (initialData.startDate)
      if (
        initialData.startDate < territoryDateLimits?.start_date ||
        initialData.startDate > territoryDateLimits?.end_date
      ) {
        errors["startdateBeforeTerrStartdate"] = true;
      }

    // if (
    //   initialData.startDate < latestAssignmentDates?.end_date
    //   // && latestAssignmentDates.territory_id !== initialData.territoryId
    // ) {
    //   errors["startdateBeforePreviousAssignmentEnddate"] = true;
    // }

    if (checkEndDateofPrevAssignment(initialData.startDate)) {
      errors["startdateBeforePreviousAssignmentEnddate"] = true;
    }
    if (
      initialData.startDate < latestAssignmentDates.start_date &&
      initialData.endDate > latestAssignmentDates.start_date
    ) {
      errors["enddateInbetweenlatestAssignmentDates"] = true;
    }
    if (
      initialData.type === 1 &&
      typeOfAssignment &&
      (action === "edit" || action === "add")
    ) {
      errors["PrimaryResourceExisted"] = true;
    }

    setAssignmentErrors(errors);
    return Object.values(errors).indexOf(true) > -1;
  };

  const saveAssignment = () => {
    if (validateForm()) {
      return;
    }
    setLoading(true);
    setErrorMessage();
    axios
      .post(
        `${process.env.REACT_APP_API_URL}emp-terr-assignments?employee_id=${empDetails.id}`,
        initialData,
      )
      .then((response) => {
        update(response.data.results, action);
        close();
      })
      .catch((error) => {
        const errMessage = error?.response?.data?.message;
        setErrorMessage(errMessage);
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateAssignment = () => {
    if (validateForm()) {
      return;
    }
    setLoading(true);
    setErrorMessage();
    axios
      .put(
        `${process.env.REACT_APP_API_URL}emp-terr-assignments/${data.id}?employee_id=${empDetails.id}`,
        initialData,
      )
      .then((response) => {
        update(response.data.results, action);
        close();
      })
      .catch((error) => {
        const errMessage = error?.response?.data?.message;
        setErrorMessage(errMessage);
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const checkEndDateofPrevAssignment = (currentStartDate) => {
    if (action === "add") {
      if (
        latestAssignmentDates.start_date === null &&
        latestAssignmentDates?.end_date === null
      ) {
        return false;
      } else if (
        latestAssignmentDates.end_date &&
        currentStartDate < latestAssignmentDates?.end_date &&
        currentStartDate > latestAssignmentDates.start_date
      ) {
        return true;
      } else if (
        latestAssignmentDates.start_date &&
        latestAssignmentDates.end_date === null
      ) {
        return true;
      } else {
        return false;
      }
    } else if (
      action === "edit"
    ) {
      if (
        latestAssignmentDates?.end_date  &&
        latestAssignmentDates?.end_date >= currentStartDate &&
        currentStartDate >= latestAssignmentDates.start_date
      ) {
        return true;
      } else if (
        latestAssignmentDates.start_date &&
        latestAssignmentDates?.end_date === null
      ) {
        return true;
      } else if (
        latestAssignmentDates.start_date === null &&
        latestAssignmentDates?.end_date === null
      ) {
        return false;
      } else {
        return false;
      }
    }
    return false;
  };

  return (
    <Dialog
      onClose={close}
      aria-labelledby="customized-dialog-title"
      open={true}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className="bg-ligh-blue">
        {action === "edit"
          ? "Edit Territory Assignment"
          : "Add Territory Assignment"}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={close}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container direction={"column"} spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <CustomTextField
                  label="Team"
                  name="team"
                  variant="outlined"
                  size="small"
                  defaultValue={""}
                  fullWidth
                  select
                  required
                  disabled={action === "edit" ? true : false}
                  value={initialData.team || ""}
                  onChange={($e) => handleInputChange($e)}
                  error={assignmentErrors.team}
                  helperText={
                    assignmentErrors.team ? "This field is required" : ""
                  }
                >
                  {TeamOptions.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.territory_number}
                      {"-"}
                      {option.territory_name}
                    </MenuItem>
                  ))}
                </CustomTextField>
              </Grid>

              <Grid item xs={6}>
                <CustomTextField
                  // label="Territory"
                  label={
                    <div
                      style={{
                        width: "90px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        gap: 4,
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        style={{
                          display: "inline-block",
                          verticalAlign: "middle",
                        }}
                      >
                        Territory
                      </Typography>
                      <Typography variant="subtitle1" className="emp-startdate">
                        *
                      </Typography>

                      <Tooltip title="Select from vacant territories">
                        <InfoIcon
                          color="action"
                          style={{ cursor: "pointer", fontSize: 18 }}
                        />
                      </Tooltip>
                    </div>
                  }
                  name="territoryId"
                  variant="outlined"
                  size="small"
                  defaultValue={""}
                  fullWidth
                  select
                  // required
                  loading={loading.toString()}
                  disabled={action === "edit" ? true : false}
                  value={initialData.territoryId || ""}
                  onChange={($e) => handleInputChange($e)}
                  error={assignmentErrors?.territoryId}
                  helperText={
                    assignmentErrors?.territoryId
                      ? "This field is required"
                      : ""
                  }
                >
                  {terraOptions?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </CustomTextField>
                {/* <Autocomplete
                  disablePortal
                  size="small"
                  defaultValue={""}
                  options={Terr_options}
                  loading={loading}
                  value={initialData.territoryId || ""}
                  sx={{ width: 265 }}
                  onChange={(e, newValue) => handleTerritoryChange(e, newValue)}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      loading={loading}
                      label="Territory"
                      required
                      error={assignmentErrors.territoryId}
                      helperText={
                        assignmentErrors.territoryId
                          ? "This field is required"
                          : ""
                      }
                    />
                  )}
                /> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <CustomTextField
                  size="small"
                  name="startDate"
                  onKeyDown={(e) => e.preventDefault()}
                  required
                  label="In Field Start Date"
                  // {...dateProps}
                  type="date"
                  inputProps={{
                    max: `${territoryDateLimits?.end_date}`,
                    min: `${territoryDateLimits?.start_date}`,
                  }}
                  value={initialData.startDate || ""}
                  onChange={(e) => handleInputChange(e)}
                  fullWidth
                  error={
                    assignmentErrors.startDate ||
                    assignmentErrors.startdateBeforeTerrStartdate ||
                    assignmentErrors.startdateBeforePreviousAssignmentEnddate
                  }
                  helperText={
                    assignmentErrors.startDate
                      ? "This field is required"
                      : assignmentErrors.startdateBeforeTerrStartdate
                        ? `Start date must fall between Territory start date(${
                            territoryDateLimits?.start_date
                          }) & end date(${territoryDateLimits?.end_date || "-"}).`
                        : assignmentErrors.startdateBeforePreviousAssignmentEnddate
                          ? `Start date must be after the previous territory assignment's end date(${
                              latestAssignmentDates?.end_date || "-"
                            })`
                          : ""
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTextField
                  {...dateProps}
                  size="small"
                  name="ICDate"
                  required
                  onKeyDown={(e) => e.preventDefault()}
                  label="IC Date"
                  value={initialData.ICDate || ""}
                  onChange={(e) => handleInputChange(e)}
                  fullWidth
                  error={
                    assignmentErrors.ICDate 
                    //  || assignmentErrors.icDateBeforeStartdate ||
                    // assignmentErrors.icDateafterEnddate
                  }
                  helperText={
                    assignmentErrors.ICDate
                      ? "This field is required"
                      // : assignmentErrors.icDateBeforeStartdate
                      //   ? "Ensure that the IC Date comes after the Start Date"
                      //   : assignmentErrors.icDateafterEnddate
                      //     ? "Ensure that the IC Date comes before the End Date"
                          : ""
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <CustomTextField
                  // {...dateProps}

                  type="date"
                  inputProps={{
                    max: `${territoryDateLimits?.end_date}`,
                    min: `${territoryDateLimits?.start_date}`,
                  }}
                  size="small"
                  name="endDate"
                  format="YYYY-MM-DD"
                  onKeyDown={(e) => e.preventDefault()}
                  label="In Field End Date"
                  value={initialData.endDate || ""}
                  onChange={(e) => handleInputChange(e)}
                  fullWidth
                  error={
                    assignmentErrors.endDate ||
                    assignmentErrors.enddateBeforeTerrEnddate ||
                    assignmentErrors.enddateInbetweenlatestAssignmentDates
                  }
                  helperText={
                    assignmentErrors.endDate
                      ? "End Date must be after Start Date."
                      : assignmentErrors.enddateBeforeTerrEnddate
                        ? "End Date must be before territory End Date."
                        : assignmentErrors.enddateInbetweenlatestAssignmentDates
                          ? `The end date should not extend beyond the start date of the preceding assignment {${latestAssignmentDates.start_date}}`
                          : ""
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTextField
                  label="Type"
                  name="type"
                  variant="outlined"
                  size="small"
                  required
                  defaultValue={""}
                  fullWidth
                  select
                  value={initialData.type || ""}
                  onChange={($e) => handleInputChange($e)}
                  error={
                    assignmentErrors.type ||
                    assignmentErrors.PrimaryResourceExisted
                  }
                  helperText={
                    assignmentErrors.type
                      ? "This field is required"
                      : assignmentErrors.PrimaryResourceExisted
                        ? "An employee cannot serve as the primary resource for multiple territories."
                        : ""
                  }
                >
                  {AddTerritoryTypeOptions &&
                    AddTerritoryTypeOptions.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.assignment_type_name}
                      </MenuItem>
                    ))}
                </CustomTextField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className="existed-terr-alert">
          {errorMessage ? (
            <Alert
              severity="error"
              sx={{ width: "300px", display: "flex", alignItems: "center" }}
            >
              <strong>{errorMessage}</strong>
            </Alert>
          ) : (
            ""
          )}
        </div>
      </DialogContent>
      <DialogActions sx={{ mr: 2, mb: 1 }}>
        <Button
          sx={{ width: "100px" }}
          size="small"
          variant="outlined"
          onClick={close}
          disabled={loading}
        >
          Cancel
        </Button>
        <LoadingButton
          sx={{ width: "100px" }}
          size="small"
          loading={loading}
          variant="contained"
          onClick={() =>
            action === "edit" ? updateAssignment() : saveAssignment()
          }
          disabled={
            empDetails?.terminated_by === null &&
            empTerminated?.date_terminated === undefined
              ? false
              : true
          }
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default EmployeeAssignment;
