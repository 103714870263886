import React, { useEffect, useState } from "react";
import { Grid, Typography, Stack, Chip, TextField } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NotificationCard from "components/shared/NotificationCard";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import Illustration2 from "../assets/images/Illustration-2.png";
import {
  updateNotifReadCount,
  updatedId,
  updateShowTable,
} from "store/employees/employeesSlice";
import { Select, MenuItem, FormControl } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CardNotificationSkeleton from "components/shared/CardNotificationSkeleton";
import CustomLoader from "components/shared/CustomLoader";
import { Box } from "@mui/system";
import { calculateRowHeight, mapKeyToDisplayName } from "utils/Util";
const pagination = true;
const paginationPageSize = 25;

const Notification = () => {
  const showTable = useSelector((state) => state.employees.showTable);
  const { id } = useParams();
  const [rowData, setRowdata] = useState([]);
  const [colData, setColData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [notificationData, setNotificationData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("desc");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}notifications`,
        );
        setNotificationData(response.data.results);
      } catch (error) {
        console.error(error);
      } finally {
        setLoader(false);
      }
    };

    dispatch(updateShowTable(false));
    setSelectedOption("desc");
    fetchData();
  }, []);

  useEffect(() => {
    const fetchDataById = async () => {
      if (id) {
        try {
          setLoading(true);
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}notification/${id}`,
          );
          const data = response?.data?.results;
          dispatch(updateShowTable(true));

          if (Array.isArray(data) && data.length > 0) {
            const dynamicColumn = Object.keys(data[0]).map((column) => ({
              headerName: mapKeyToDisplayName(column),
              field: column,
              filter: "agTextColumnFilter",
              floatingFilter: false,
              resizable: true,
              sortable: true,
              cellRenderer:
                column === "record_status"
                  ? statusComponent
                  : column === "message"
                  ? messageComponent
                  : undefined,
              minWidth: column === "message" && 350,
              rowGroup: column === "record_status",
              hide: column === "record_status",
            }));

            setColData(dynamicColumn);
            setRowdata(data);

            dispatch(updateNotifReadCount(id));
            dispatch(updatedId(id));
            setNotificationData((prevData) =>
              prevData.map((el) => {
                if (el.id === parseInt(id)) {
                  return {
                    ...el,
                    read_flag: true,
                  };
                }
                return el;
              }),
            );
          }
        } catch (error) {
          console.error("Error", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchDataById();
  }, [id]);

  const handleCardClick = async (id) => {
    setSearchTerm("");
    navigate(`/notification/${id}`);
  };

  const statusComponent = (params) => {
    let chipColor = "";
    switch (params?.value?.toLowerCase()) {
      case "success":
        chipColor = "#EDF7ED";
        break;
      case "failed":
        chipColor = "#FDEDED";
        break;
      case "skipped":
        chipColor = "#FFF4E5";
        break;
      default:
        chipColor = "default";
    }
    return (
      <Chip
        size="small"
        label={params.value}
        className="notification-chip"
        sx={{
          backgroundColor: chipColor,
          borderRadius: "5px",
          color: "black",
        }}
      >
        {/* {params.value} */}
      </Chip>
    );
  };

  const messageComponent = (params) => {
    if (params.value === undefined) {
      return null;
    }
    const matches = calculateRowHeight(params?.value);

    if (matches && matches?.length > 0) {
      return (
        <div className="row-message">
          {matches?.map((msg, index) => (
            <li key={index}>{msg.replace(/'/g, "")}</li>
          ))}
        </div>
      );
    }

    return (
      <div className="row-message">
        <li>{params?.value}</li>
      </div>
    );
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
    gridApi.setQuickFilter(e.target.value);
  };

  const handleCancel = () => {
    dispatch(updateShowTable(false));
    setRowdata(false);
    navigate(`/notification`);
  };

  const handleSortChange = (event) => {
    const val = event.target.value;
    setSelectedOption(val);
    sortData(val);
  };

  const getUnreadNotif = () => {
    return notificationData.filter((el) => !el.read_flag)?.length;
  };

  const getFileName = () => {
    const file = notificationData?.find((el) => el.id == id);
    return file?.file_path;
  };

  const getDate = () => {
    const date = notificationData?.find((el) => el.id == id);
    const originalDate = new Date(date?.created_at);

    const year = originalDate.getFullYear();
    const month = (originalDate.getMonth() + 1).toString().padStart(2, "0");
    const day = originalDate.getDate().toString().padStart(2, "0");
    const hours = originalDate.getHours().toString().padStart(2, "0");
    const minutes = originalDate.getMinutes().toString().padStart(2, "0");
    const seconds = originalDate.getSeconds().toString().padStart(2, "0");
    const meridiem = hours >= 12 ? "PM" : "AM";
    const formattedTime = `${
      hours % 12 || 12
    }:${minutes}:${seconds}${meridiem}`;

    const formatteddate = `${year}-${month}-${day}, ${formattedTime}`;
    return formatteddate;
  };

  const sortData = (option) => {
    const sortedData = [...notificationData];

    sortedData.sort((a, b) => {
      const timestampA = new Date(a.created_at).getTime();
      const timestampB = new Date(b.created_at).getTime();

      if (option === "asc") {
        return timestampA - timestampB;
      } else if (option === "desc") {
        return timestampB - timestampA;
      }

      return 0;
    });
    setNotificationData(sortedData);
  };

  const gridOptions = {
    rowHeight: 35,
    getRowHeight: (params) => {
      const matches = calculateRowHeight(params?.data?.message);

      if (matches?.length) {
        const lineHeight = 30;
        const numberOfLines = matches.length;
        return lineHeight * numberOfLines;
      }
      return gridOptions.rowHeight;
    },
  };

  return (
    <div className="notification-page">
      <div className="notification-leftcard">
        <div className="notification-list">
          <Typography variant="h6" style={{ paddingLeft: "5px" }}>
            Notifications ({getUnreadNotif()}/{notificationData.length})
          </Typography>
          <Stack direction="row">
            <FormControl
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              size="small"
            >
              <Select
                value={selectedOption}
                sx={{ width: "85px", ml: 0 }}
                onChange={handleSortChange}
                inputProps={{
                  name: "sort",
                  id: "sort",
                }}
              >
                <MenuItem value="asc">Oldest</MenuItem>
                <MenuItem value="desc">Latest</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </div>
        <div className="notification-sidebar-body">
          {loader ? (
            <>
              {[...Array(4)].map((elementInArray, index) => (
                <Grid
                  item
                  xs={12}
                  key={index}
                  sx={{ p: "5px", cursor: "pointer" }}
                >
                  <CardNotificationSkeleton key={index} />
                </Grid>
              ))}
            </>
          ) : (
            notificationData?.map((el) => (
              <div key={el.id} className="notification-main">
                <NotificationCard
                  data={el}
                  key={el.id}
                  handleNotification={handleCardClick}
                />
              </div>
            ))
          )}
        </div>
      </div>

      <div className="notification-table">
        {showTable ? (
          <div className="custom-card-notification">
            <div className="card-header-notification">
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent={"space-between"}
              >
                <Grid item p={1}>
                  <Typography variant="h6">{getFileName()}</Typography>
                  <div className="grid-date">
                    <CalendarMonthIcon
                      className="card-icons"
                      sx={{ fontSize: 14 }}
                    />
                    <Typography>{getDate()}</Typography>
                  </div>
                </Grid>
                <Grid item>
                  <TextField
                    size="small"
                    type="search"
                    label="Search"
                    value={searchTerm}
                    onChange={handleChange}
                    sx={{ width: 250, mr: 1 }}
                    InputProps={{
                      endAdornment: <SearchIcon />,
                    }}
                  />
                  <Tooltip
                    title="Close table"
                    enterDelay={300}
                    leaveDelay={200}
                  >
                    <CancelOutlinedIcon
                      sx={{
                        m: 0.5,
                        fontSize: 26,
                        color: "red",
                        cursor: "pointer",
                      }}
                      onClick={handleCancel}
                    />
                  </Tooltip>
                  {/* <IconButton aria-label="delete" onClick={handleCancel}>
                      <CancelOutlinedIcon sx={{ fontSize: 24, color: "red" }} />
                    </IconButton> */}
                </Grid>
              </Grid>
            </div>
            <div className="card-content_-notification">
              <div
                className="table-container ag-theme-alpine ag-theme-alpine--compact"
                style={{ height: "100%" }}
              >
                {loading ? (
                  <CustomLoader />
                ) : (
                  <AgGridReact
                    rowData={rowData}
                    columnDefs={colData}
                    headerHeight={35}
                    pagination={pagination}
                    paginationPageSize={paginationPageSize}
                    onGridReady={onGridReady}
                    gridOptions={gridOptions}
                    autoGroupColumnDef={{
                      headerName: "Record Status",
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        ) : (
          <>
            {loading ? (
              <CustomLoader />
            ) : (
              <Box className="no-data-display">
                <img src={Illustration2} alt="select-notification" />
                <p>Select notification to preview the data</p>
              </Box>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Notification;
