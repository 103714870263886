import * as React from "react";
import Box from "@mui/material/Box";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import CustomNode from "./CustomNode";

export default function TerritoryTree(props) {
  const {
    data,
    setExpanded,
    expanded,
    heirarchyLevels,
    handleSelNode,
    refreshHierarchy,
  } = props;

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const renderTree = (nodes, index) => (
    <TreeItem
      key={index}
      nodeId={nodes.id?.toString()}
      label={
        <CustomNode
          heirarchyLevels={heirarchyLevels}
          handleSelNode={handleSelNode}
          node={nodes}
          key={nodes.id}
          refreshHierarchy={refreshHierarchy}
        />
      }
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node,index) => renderTree(node,index))
        : null}
    </TreeItem>
  );

  return (
    <Box>
      <TreeView
        aria-label="rich object"
        defaultCollapseIcon={<ArrowDropDownRoundedIcon />}
        defaultExpanded={["root"]}
        defaultExpandIcon={<ArrowRightRoundedIcon />}
        expanded={expanded}
        onNodeToggle={handleToggle}
        // onNodeSelect={(event, nodeId) => onNodeSelect(event, nodeId)}
      >
        {data && data.length > 0 && data.map((item,index) => renderTree(item,index))}
      </TreeView>
    </Box>
  );
}
