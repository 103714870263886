import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { NavLink, useLocation } from "react-router-dom";
import employeeIcon from "../../assets/images/employee.svg";
import territoryIcon from "../../assets/images/territory.svg";
import reportIcon from "../../assets/images/report.svg";
import adminIcon from "../../assets/images/admin.svg"
import adminIconDark from "../../assets/images/admin_dark.svg"
import employeeIconDark from "../../assets/images/employee_dark.svg";
import territoryIconDark from "../../assets/images/territory_dark.svg";
import reportIconDark from "../../assets/images/report_dark.svg";
import { Typography } from "@mui/material";

const drawerWidth = 140;

export default function Sidebar() {
  const location = useLocation();
  const menuItems = [
    {
      label: "Employees",
      name: "employees",
      icon: employeeIcon,
      darkIcon: employeeIconDark,
      link: "/",
    },
    {
      label: "Territories",
      name: "territories",
      icon: territoryIcon,
      darkIcon: territoryIconDark,
      link: "/territoryHierarchy",
    },
    {
      label: "Reports",
      name: "reports",
      icon: reportIcon,
      darkIcon: reportIconDark,
      link: "/reports",
    },
    {
      label: "Admin",
      name: "admin",
      icon: adminIcon,
      darkIcon: adminIconDark,
      link: "/admin/address_type",
    },
  ];
  const [isActive, setIsActive] = useState({
    employees: false,
    territories: false,
    reports: false,
    admin:false,
  });

  useEffect(() => {
    let activeTab = location.pathname.split("/")[1];
    if (activeTab === "") activeTab = "employees";
    setIsActive({
      employees: activeTab === "employees",
      territories: activeTab === "territoryHierarchy",
      reports: activeTab === "reports",
      admin:activeTab === "admin"
    });
  }, [location]);

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: "auto" }}>
        <List>
          {menuItems.map((menuItem, index) => (
            <ListItem
              key={index}
              component={NavLink}
              to={menuItem.link}
              className={`custom-nav-link ${
                isActive[menuItem.name] ? "custom-active" : ""
              }`}
            >
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <img
                  src={menuItem.darkIcon}
                  className="sidebar-icon dark"
                  alt="sidebar-icon"
                />
                <img
                  src={menuItem.icon}
                  className="sidebar-icon light"
                  alt="sidebar-icon"
                />
                <Typography className="label" variant="body2">
                  {menuItem.label}
                </Typography>
              </Grid>
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
}
