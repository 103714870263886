import React, { useEffect, useState } from "react";
import { Grid, Button, IconButton, Dialog } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { UID, dateProp } from "utils/Util";
import CustomTextField from "components/shared/CustomTextField";
import { LoadingButton } from "@mui/lab";

const AddTeam = (props) => {
  const { data, close, action, update, loading } = props;
  const [initialData, setInitialData] = useState({});
  const [assignmentErrors, setAssignmentErrors] = useState({});
  const dateProps = dateProp();
  useEffect(() => {
    let initialData = {
      id: UID(),
      team_name: "",
      team_number: "",
      UDF1: "",
      start_date: "",
      end_date: "",
    };
    if (action === "Edit") {
      initialData = {
        ...data,
        start_date: data.start_date,
        end_date: data.end_date,
        team_name: data.territory_name,
        team_number: data.territory_number,
      };
    }
    setInitialData(initialData);
  }, [action]);

  const validateForm = () => {
    let errors = {
      team_name: false,
      team_number: false,
      UDF1: false,
      start_date: false,
      // end_date: false,
    };

    Object.entries(errors).forEach(([key, value]) => {
      if (initialData[key] === "" || initialData[key] === null) {
        errors[key] = true;
      }
    });

    setAssignmentErrors(errors);
    return Object.values(errors).indexOf(true) > -1;
  };

  const Save = () => {
    if (validateForm()) {
      return;
    }
    update(initialData, action);
    close();
  };

  const handleTeamInputChange = ($e) => {
    const { name, value } = $e.target;
    setInitialData({ ...initialData, [name]: value });
    if (value) {
      setAssignmentErrors({ ...assignmentErrors, [name]: false });
    }
  };

  const handleDateInputChange = (e) => {
    const { name, value } = e.target;
    setInitialData({ ...initialData, [name]: value });
    if (value) {
      setAssignmentErrors({ ...assignmentErrors, [name]: false });
    }
  };

  return (
    <Dialog
      onClose={close}
      aria-labelledby="customized-dialog-title"
      open={true}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className="bg-ligh-blue">
        {action === "Add" ? "Add New" : "Edit"} Team
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={close}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container direction={"column"} spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <CustomTextField
                  label="Team Name"
                  name="team_name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                  value={initialData.team_name || ""}
                  onChange={($e) => handleTeamInputChange($e)}
                  error={Boolean(assignmentErrors.team_name)}
                  helperText={
                    assignmentErrors.team_name ? "This field is required" : ""
                  }
                />
              </Grid>

              <Grid item xs={6}>
                <CustomTextField
                  label="Team Number"
                  name="team_number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                  value={initialData.team_number || ""}
                  onChange={($e) => handleTeamInputChange($e)}
                  error={Boolean(assignmentErrors.team_number)}
                  helperText={
                    assignmentErrors.team_number ? "This field is required" : ""
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <CustomTextField
                  label="SFA team ID"
                  name="UDF1"
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                  value={initialData.UDF1 || ""}
                  onChange={($e) => handleTeamInputChange($e)}
                  error={Boolean(assignmentErrors.UDF1)}
                  helperText={
                    assignmentErrors.UDF1 ? "This field is required" : ""
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTextField
                  {...dateProps}
                  size="small"
                  name="start_date"
                  required
                  format="YYYY-MM-DD"
                  label="Start Date"
                  value={initialData.start_date || ""}
                  onChange={(e) => handleDateInputChange(e)}
                  onKeyDown={(e) => e.preventDefault()}
                  fullWidth
                  error={Boolean(assignmentErrors.start_date)}
                  helperText={
                    assignmentErrors.start_date ? "This field is required" : ""
                  }
                />
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    slotProps={{ textField: { size: "small", fullWidth: true } }}
                    label="Start Date"
                    required
                    defaultValue={""}
                    format="YYYY-MM-DD"
                    value={dayjs(initialData.start_date)}
                    name="start_date"
                    onChange={(value) =>
                      handleDateInputChange(value, "start_date")
                    }
                    // error={assignmentErrors.ICDate}
                    // helperText={
                    //   assignmentErrors.ICDate ? "This field is required" : ""
                    // }
                  />
                </LocalizationProvider> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <CustomTextField
                  {...dateProps}
                  size="small"
                  name="end_date"
                  format="YYYY-MM-DD"
                  // defaultValue={""}
                  // required
                  label="End Date"
                  value={initialData.end_date || ""}
                  onChange={(e) => handleDateInputChange(e)}
                  onKeyDown={(e) => e.preventDefault()}
                  //  onChange={(value) =>
                  //   handleDateInputChange(value, "end_date")
                  // }
                  fullWidth
                  // error={assignmentErrors.end_date}
                  // helperText={
                  //   assignmentErrors.end_date ? "This field is required" : ""
                  // }
                />

                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    slotProps={{ textField: { size: "small" } }}
                    sx={{ width: "100%" }}
                    label="End Date"
                    format="YYYY-MM-DD"
                    id="outlined-error-helper-text"
                    defaultValue={""}
                    value={dayjs(initialData.end_date)}
                    name="end_date"
                    onChange={(value) =>
                      handleDateInputChange(value, "end_date")
                    }
                    // error={assignmentErrors.endDate}
                    // helperText={
                    //   assignmentErrors.endDate ? "This field is required" : ""
                    // }
                  />
                </LocalizationProvider> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        // dividers
        sx={{ mr: 2, mb: 1 }}
      >
        <Button
          sx={{ width: "100px" }}
          size="small"
          variant="outlined"
          disabled={loading}
          onClick={close}
        >
          Cancel
        </Button>
        <LoadingButton
          sx={{ width: "100px" }}
          size="small"
          variant="contained"
          loading={loading}
          color="primary"
          onClick={Save}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddTeam;
