import React, { useState, useRef, useEffect, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ListItemText from "@mui/material/ListItemText";
import SearchIcon from "@mui/icons-material/Search";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  Button,
  Grid,
  Stack,
  Box,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import PeopleIcon from "../assets/images/people-bg.png";
import "../css/ag-grid.css";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import CustomLoader from "components/shared/CustomLoader";
import AddItem from "components/admin/AddItem";
import DeleteModal from "components/admin/DeleteModal";
import { mapKeyToDisplayName } from "utils/Util";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import AdminTerritoryTree from "components/admin/AdminTerritoryTree";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useSelector } from "react-redux";

const adminData = {
  employee: {
    Position: "position",
    Department: "department",
    "Employment Type": "employee_type",
    "Email Type": "email_type",
    "Phone Type": "phone_type",
    "Address Type": "address_type",
    State: "state",
    Country: "country",
    Interface: "interface",
    "Assignment Type": "assignment_type",
    "Time Off Type": "time_off_type",
  },
  territory: {
    "Territory Levels": "territory-level-admin",
  },
};

const pagination = true;
const paginationPageSize = 25;

const Admin = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const gridRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(id);
  const [selectedItemName, setSelectedItemName] = useState("address_type");
  const [selectedRow, setSelectedRow] = useState(null);
  const [action, setAction] = useState(null);
  const [refresh, setRefresh] = useState("");
  const [columnDefs, setColumnDefs] = useState([]);
  const [selectedParentData, setSelectedParentData] = useState();
  const [expandStates, setExpandStates] = useState({
    employee: true,
    territory: true,
  });

  const countryCodesDropdown = useSelector(
    (state) => state.employees.CountryOptions,
  );

  const unique = new Set();

  const uniqueDataArray = countryCodesDropdown?.filter((item) => {
    if (!unique.has(item.code)) {
      unique.add(item.code);
      return true;
    }
    return false;
  });

  const getTitleForSelectedItem = (selectedItem) => {
    for (const key in adminData) {
      const subKeys = adminData[key];
      for (const subKey in subKeys) {
        if (subKeys[subKey] === selectedItem) {
          return subKey;
        }
      }
    }
    return null;
  };

  const AdminFieldType = getTitleForSelectedItem(id);

  useEffect(() => {
    setLoading(true);
    setRowData();

    axios
      .get(`${process.env.REACT_APP_API_URL}${selectedItem}`)
      .then((response) => {
        const data = response.data.results;

        if (data.length > 0) {
          const dynamicColumn = Object.keys(data[0]).map((column) => ({
            headerName: mapKeyToDisplayName(column),
            field: column,
            filter: "agTextColumnFilter",
            floatingFilter: false,
            resizable: true,
            sortable: true,
          }));
          dynamicColumn.push({
            headerName: "Actions",
            field: "actions",
            sortable: true,
            cellRenderer: (props) => {
              return (
                <div>
                  <IconButton
                    color="info"
                    variant="outlined"
                    size="small"
                    onClick={() => handleEdit(props)}
                  >
                    <EditIcon />
                  </IconButton>

                  <IconButton
                    color="error"
                    variant="outlined"
                    size="small"
                    onClick={() => handleDelete(props)}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </div>
              );
            },
            width: 100,
            maxWidth: 100,
            filter: false,
            // sortable: false,
          });

          setColumnDefs(dynamicColumn);
          setRowData(data);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });

    setSelectedItemName(AdminFieldType);
  }, [id, refresh]);

  const gridOptions = {
    onGridSizeChanged: () => {
      gridOptions.api.sizeColumnsToFit();
    },
  };
  

  const onGridReady = (params) => {
    setGridApi(params.api);
    gridApi?.setQuickFilter("");
  };

  const handleAddtem = (nodes) => {
    setOpenModal(true);
    setAction("add");
    setSelectedParentData(nodes);
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: "agTextColumnFilter",
      floatingFilter: false,
      resizable: true,
      sortable: true,
    };
  }, []);

  const closeModal = (event, reason) => {
    if (reason && ["backdropClick", "escapeKeyDown"].indexOf(reason) !== -1) {
      return;
    }
    setOpenModal(false);
  };

  const closeDeleteModal = (event, reason) => {
    if (reason && ["backdropClick", "escapeKeyDown"].indexOf(reason) !== -1) {
      return;
    }
    setOpenDeleteModal(false);
  };

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          width: 225,
          maxWidth: 225,
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
      ],
      position: "right",
      defaultToolPanel: "",
    };
  }, []);

  const handleEdit = (params) => {
    setOpenModal(true);
    setSelectedRow(params.data);
    setAction("edit");
    setSelectedParentData(params);
  };
  const handleDelete = (params) => {
    setOpenDeleteModal(true);
    setSelectedRow(params.data);
    setSelectedParentData(params);
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
    gridApi.setQuickFilter(e.target.value);
  };

  const handleNavigate = (name, value) => {
    gridApi.setQuickFilter("");
    setSearchTerm("");
    setSelectedItem(value);
    setSelectedItemName(name);
    navigate(`/admin/${value}`);
  };

  const updateData = (val) => {
    setRefresh(val);
  };

  const handleExpandChange = (field) => {
    setExpandStates((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters {...props} />
  ))(({ theme }) => ({
    boxShadow: "none",
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      style={{ minHeight: "25px" }}
      expandIcon={
        <ArrowRightIcon sx={{ fontSize: "23px", minHeight: "20px" }} />
      }
      {...props}
    />
  ))(({ theme }) => ({
    headerHeight: "20px",
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .08)"
        : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: "0px",
  }));

  return (
    <div className="admin-page-content-container">
      <div className="page-header">
        <Grid container direction="row" alignItems="center">
          <Grid item md={5} sm={12}>
            <Stack direction="row" alignItems="center">
              <Typography variant="h5">Admin Module</Typography>
              <img
                style={{ marginLeft: "3em", maxWidth: "200px" }}
                src={PeopleIcon}
                alt="people"
              />
            </Stack>
          </Grid>
        </Grid>
      </div>
      <div className="admin-card-content">
        <div className="admin-options">
          <Accordion
            expanded={expandStates.employee}
            onChange={() => handleExpandChange("employee")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography>Employee</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
              <List
                sx={{ width: "100%", maxWidth: 360 }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                {Object.entries(adminData.employee)
                  .sort(([nameA], [nameB]) => nameA.localeCompare(nameB))
                  .map(([name, value]) => (
                    <ListItemButton
                      key={name}
                      className={`option-name ${value === id && "active"}`}
                      onClick={() => handleNavigate(name, value)}
                    >
                      <ListItemText primary={name} />
                    </ListItemButton>
                  ))}
              </List>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expandStates.territory}
            onChange={() => handleExpandChange("territory")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography>Territory</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
              <List
                sx={{ width: "100%", maxWidth: 360 }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                {Object.entries(adminData.territory)
                  .sort(([nameA], [nameB]) => nameA.localeCompare(nameB))
                  .map(([name, value]) => (
                    <ListItemButton
                      key={name}
                      className={`option-name ${value === id && "active"}`}
                      onClick={() => handleNavigate(name, value)}
                    >
                      <ListItemText primary={name} />
                    </ListItemButton>
                  ))}
              </List>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className="page-content">
          {loading && <CustomLoader />}
          <div className="card-content_">
            <Grid
              item
              md={8}
              sm={8}
              className="search-box"
              sx={{ alignItems: "center" }}
            >
              <Typography variant="h6">{AdminFieldType} Details</Typography>
              {selectedItemName === "Territory Levels" ? (
                ""
              ) : (
                <Box spacing={2} display="flex" alignItems="center" gap={2}>
                  <TextField
                    size="small"
                    type="search"
                    label="Search"
                    value={searchTerm}
                    onChange={handleChange}
                    sx={{ width: 250 }}
                    InputProps={{
                      endAdornment: <SearchIcon />,
                    }}
                  />
                  <Button
                    size="small"
                    color="info"
                    variant="contained"
                    onClick={() => handleAddtem()}
                  >
                    <AddOutlinedIcon fontSize="small" sx={{ mr: "2px" }} />{" "}
                    {selectedItemName}
                  </Button>
                </Box>
              )}
            </Grid>
          </div>
          <div className="custom-card">
            {selectedItemName === "Territory Levels" ? (
              <div style={{ backgroundColor: "white", padding: "2em" }}>
                <AdminTerritoryTree
                  data={selectedRow}
                  close={() => closeModal()}
                  action={action}
                  selectedItem={selectedItem}
                  selectedItemName={selectedItemName}
                  update={updateData}
                  refresh={refresh}
                  handleAddtem={handleAddtem}
                  handleEditItem={handleEdit}
                  handleDeleteitem={handleDelete}
                />
              </div>
            ) : (
              <div className="table-container ag-theme-alpine ag-theme-alpine--compact">
                <AgGridReact
                  headerHeight={35}
                  rowData={rowData}
                  columnDefs={columnDefs}
                  pagination={pagination}
                  paginationPageSize={paginationPageSize}
                  rowHeight={30}
                  ref={gridRef}
                  sideBar={sideBar}
                  defaultColDef={defaultColDef}
                  onGridReady={onGridReady}
                />
              </div>
            )}
          </div>
        </div>

        {openModal && (
          <AddItem
            data={selectedRow}
            close={() => closeModal()}
            action={action}
            selectedItem={selectedItem}
            selectedItemName={selectedItemName}
            update={updateData}
            selectedParentData={selectedParentData}
            uniqueDataArray={uniqueDataArray}
          />
        )}
        {openDeleteModal && (
          <DeleteModal
            data={selectedRow}
            close={closeDeleteModal}
            selectedItem={selectedItem}
            selectedItemName={selectedItemName}
            update={updateData}
            selectedParentData={selectedParentData}
          />
        )}
      </div>
    </div>
  );
};

export default Admin;
