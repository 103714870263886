import { createSlice } from "@reduxjs/toolkit";

export const layoutSlice = createSlice({
  name: "layout",
  initialState: {
    userInfo: null,
    isAuthorised: false,
  },
  reducers: {
    userDataFromLocalStorage: (state) => {
      const userInfo = localStorage.getItem("user_data");
      if (userInfo) {
        state.userInfo = JSON.parse(userInfo);
      }
    },
    updateAuthorisedFlag: (state, flag) => {
      state.isAuthorised = flag;
    },
  },
});
export const { userDataFromLocalStorage, updateAuthorisedFlag } =
  layoutSlice.actions;
export default layoutSlice.reducer;
