import React, { useState } from "react";
import FolderRoundedIcon from "@mui/icons-material/FolderRounded";
import GroupRoundedIcon from "@mui/icons-material/GroupRounded";
import ArticleRoundedIcon from "@mui/icons-material/ArticleRounded";
import AddTeam from "components/territory_hierarchy/AddTeam";
import axios from "axios";
import CircleIcon from "@mui/icons-material/Circle";
import { Tooltip } from "@mui/material";

const CustomNode = (props) => {
  const { node, heirarchyLevels, handleSelNode, refreshHierarchy } = props;
  const [teamModal, setTeamModel] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(false);

  const getLevel = (node) => {
    return heirarchyLevels[node?.territory_level?.name];
  };

  const closeTeamModel = () => {
    setSelectedRow(null);
    setTeamModel(false);
  };

  const saveTeamDetails = (data) => {
    // dispatch(updateTerrModuleFormErrors(null))
    setLoading(true);
    axios
      .put(`${process.env.REACT_APP_API_URL}team/${data.id}`, data)
      .then((response) => {
        refreshHierarchy();
        closeTeamModel();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onEditTerritory = (e, node) => {
    if (node.UDF1) {
      setSelectedRow(node);
      setTeamModel(true);
      return;
    }
    e.preventDefault();
    handleSelNode({ ...node, level: node?.territory_level?.name }, "Edit");
  };

  const onCreateTerritory = (e, node) => {
    e.preventDefault();
    const level = getLevel(node);
    handleSelNode({ ...node, level: level }, "Add");
  };

  const renderAddActionButton = () => {
    const level = getLevel(node);
    if (level) {
      return (
        <>
          {" - "}
          <div
            className="node-action link"
            onClick={(e) => onCreateTerritory(e, node)}
          >
            Add {level}
          </div>
        </>
      );
    }
  };

  const renderEmployeeDetails = () => {
    // const level = heirarchyLevels[node?.territory_level?.name?.toUpperCase()];
    let employees = [];
    if (node?.territory_assignments?.length > 0) {
      employees = [{ ...node?.territory_assignments[node?.territory_assignments?.length-1] }];
      // level === "TERRITORY"
      // !level
      // ? node?.territory_assignments
      // : [{ ...node?.territory_assignments[0] }];
    }
    if (employees.length === 0) {
      return (
        <>
          {" - "}
          <div className="node-employee">VACANT</div>
        </>
      );
    }
    return employees.map((emp, index) => (
      // <>
      <div className="node-employee" key={emp?.id}>
        {" - "}
        {emp?.first_name} {emp?.last_name}
      </div>
      // </>
    ));
  };

  const renderTheIcon = () => {
    const level = heirarchyLevels[node?.territory_level?.name?.toUpperCase()];
    if (node.UDF1) {
      return <GroupRoundedIcon sx={{ width: "18px", mr: 0.5 }} />;
    }
    if (!level) {
      return <ArticleRoundedIcon sx={{ width: "18px", mr: 0.5 }} />;
    }
    return <FolderRoundedIcon sx={{ width: "18px", mr: 0.5 }} />;
  };

  const handleAlertEndDate = () => {
    if (node?.territory_assignments?.length > 0) {
      let getLatestAssgn =
        node?.territory_assignments?.[node.territory_assignments?.length - 1];
      let val = getLatestAssgn?.end_date;
      if (val && typeof val === "string" && val.length >= 10) {
        const specificDate = new Date(
          Date.UTC(
            parseInt(val.slice(0, 4)),
            parseInt(val.slice(5, 7)) - 1,
            parseInt(val.slice(8, 10)),
            0,
            0,
            0,
            0,
          ),
        );
        const currentdate = new Date();
        const checkDateDiffernce =
          specificDate?.getTime() - currentdate?.getTime();
        const daysDifference = Math.floor(
          checkDateDiffernce / (1000 * 60 * 60 * 24),
        );

        specificDate.setDate(specificDate?.getDate() - 13);
        const finalDate = specificDate?.toISOString()?.split("T")[0];

        if (
          finalDate <= currentdate?.toISOString()?.split("T")[0] &&
          currentdate?.toISOString()?.split("T")[0] <= val
        ) {
          return (
            <div className="red-dot">
              <Tooltip
                title={`The Employee Assignment is set to expire in the next ${
                  daysDifference + 2
                } days`}
                arrow
              >
                <CircleIcon fontSize="14px" />
              </Tooltip>
            </div>
          );
        } else {
          return "";
        }
      } else {
        return "";
      }
    }
  };

  return (
    <div className="flex flex-row custom-tree-node text-center">
      {renderTheIcon()}

      <div className="node-number">{node.territory_number}</div>
      <div className="node-name link" onClick={(e) => onEditTerritory(e, node)}>
        {node.territory_name}
      </div>

      {renderEmployeeDetails()}

      {renderAddActionButton()}

      {teamModal && (
        <AddTeam
          data={selectedRow}
          action={"Edit"}
          loading={loading}
          close={() => closeTeamModel()}
          update={saveTeamDetails}
        />
      )}
      {handleAlertEndDate()}
    </div>
  );
};

export default CustomNode;
