import { React,  } from "react";
import Box from "@mui/material/Box";
import { Outlet } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import AppHeader from "components/layouts/AppHeader";
import Sidebar from "components/layouts/Sidebar";

import {  useSelector } from "react-redux";
import { ExpiredSessionPopup } from "components/shared/ExpiredSessionPopup";

const MainLayout = () => {
  const isAuthorised = useSelector((state) => state.layout.isAuthorised);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      {/* App Header Start here */}
      <AppHeader />
      {/* App Header Ends here */}

      {/* App Sidebar Start here */}
      <Sidebar />
      {/* App Sidebar Ends here */}

      {/* Unauthorised Popup Start here */}
      {isAuthorised && <ExpiredSessionPopup />}
      {/* Unauthorised Popup Ends here */}

      <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};

export default MainLayout;
