import { useCallback, useState } from "react";

function isFunction(obj) {
  return typeof obj === "function";
}

function useSetState(initialState = {}) {
  const [state, set] = useState(initialState);

  const setState = useCallback((patch, callback) => {
    set((prevState) => {
      const nextState = {
        ...prevState,
        ...(isFunction(patch) ? patch(prevState) : patch),
      };
      if (callback) {
        callback(prevState, nextState);
      }
      return nextState;
    });
  }, []);

  return [state, setState];
}

export default useSetState;
