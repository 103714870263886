import React, { useState } from "react";
import {
  Grid,
  Stack,
  Typography,
  Box,
  Tabs,
  Tab,
} from "@mui/material";
import PeopleIcon from "../assets/images/people-bg.png";
import DownloadFile from "components/reports/downloads/DownloadFile";
import { Report } from "components/reports/Report";

const Reports = (props) => {
  const [value, setValue] = useState("Reports"); 

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="page-content">
      <div className="page-header">
        <Grid container direction="row" alignItems="center">
          <Grid item md={5}>
            <Stack direction="row" alignItems="center">
              <Typography variant="h5">Reports</Typography>
              <img
                style={{ marginLeft: "3em", maxWidth: "200px" }}
                src={PeopleIcon}
                alt="people"
              />
            </Stack>
          </Grid>
          <Grid item md={7}></Grid>
        </Grid>
      </div>
      <div className="page-content">
        <Box className="bg-ligh-grey">
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label="Reports" value={"Reports"} />
            <Tab label="Downloads" value={"Downloads"} />
          </Tabs>
        </Box>

        <div className="report-card-content box-shadow">
          {value === "Reports" && <Report />}
          {value === "Downloads" && <DownloadFile />}
        </div>
      </div>
    </div>
  );
};

export default Reports;
