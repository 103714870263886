import React from "react";
import ReactDOM from "react-dom/client";
import "./css/index.scss";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import Routers from "./router/router";
import { msalInstance } from "./lib/authConfig";
import { MsalProvider } from "@azure/msal-react";
import { GLOBAL_THEME } from "./css/globalTheme";
import { Provider } from "react-redux";
import { store } from "store/store";
import { userDataFromLocalStorage } from "store/layout/layoutSlice";
import axios from "axios";
import { getUserData } from "utils/Util";
import { updateAuthorisedFlag } from "store/layout/layoutSlice";
import { LicenseManager } from "ag-grid-enterprise";
LicenseManager.setLicenseKey(`${process.env.REACT_APP_AG_GRID_LICENSE_KEY}`);

let theme = createTheme(GLOBAL_THEME);
theme = responsiveFontSizes(theme);

store.dispatch(userDataFromLocalStorage());
const data = getUserData("user_data");

if (data) {
  axios.defaults.headers.common.Authorization = `Bearer ${data.token}`;
}
axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err.response?.status && err.response.status === 401) {
      delete axios.defaults.headers.common.Authorization;
      store.dispatch(updateAuthorisedFlag(true));
    }
        
    return Promise.reject(err);
  },
);

ReactDOM.createRoot(document.getElementById("root")).render(
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <RouterProvider router={Routers} />
      </ThemeProvider>
    </Provider>
  </MsalProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
