import axios from "axios";
import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { getUserNameInitials, userEmail } from "utils/Util";
import { Container, TextareaAutosize } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import { useLocation } from "react-router-dom";
import DeleteModal from "components/shared/DeleteModal";
import NotesSkeleton from "components/shared/NotesSkeleton";

const Notes = (props) => {
  const { empDetails } = props;
  const location = useLocation();

  const [notesList, setNotesList] = useState([]);
  const [notesLoading, setNotesLoading] = useState(false);
  const [deletingNote, setDeletingNote] = useState(false);
  const [submittingNote, setSubmittingNote] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteNoteId, setDeleteNoteId] = useState(null);
  const [noteVal, setNoteVal] = useState("");

  const getNotes = async (id) => {
    setNotesLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}employee/${id}/notes`,
      );
      setNotesList(response.data.results);
    } catch (error) {
      console.error(error);
    } finally {
      setNotesLoading(false);
    }
  };

  const submitNote = async (e) => {
    setSubmittingNote(true);
    try {
      const payload = {
        note: noteVal,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}employee/${empDetails?.id}/notes`,
        payload,
      );
      setNotesList((prev) => [response?.data?.results, ...prev]);
    } catch (error) {
      console.error(error);
    } finally {
      setSubmittingNote(false);
      setNoteVal("");
    }
  };

  const handleDeleteNote = async () => {
    setDeletingNote(true);
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}employee/${empDetails?.id}/note/${deleteNoteId}`,
      );
      const filteredNotes = notesList.filter(
        (notes) => deleteNoteId !== notes.id,
      );
      setNotesList(filteredNotes);
    } catch (error) {
      console.error(error);
    } finally {
      setOpenDeleteModal(false);
      setDeletingNote(false);
    }
  };

  useEffect(() => {
    let action = location.pathname.split("/").pop();
    if (empDetails && empDetails.id) {
      getNotes(action);
    }
  }, []);

  const handleClose = () => {
    setOpenDeleteModal(false);
  };

  const handleDeleteModal = (noteId) => {
    setDeleteNoteId(noteId);
    setOpenDeleteModal(true);
  };

  return (
    <div className="notes">
      <ListItem sx={{ my: 1 }}>
        <ListItemAvatar className="avatar">
          <Avatar>{getUserNameInitials()}</Avatar>
        </ListItemAvatar>
        <TextareaAutosize
          className="note-textarea"
          name="textArea"
          variant="outlined"
          fullwidth="true"
          minRows={1}
          value={noteVal}
          onChange={(e) => setNoteVal(e.target.value)}
          placeholder="Type here..."
        />

        <LoadingButton
          variant="contained"
          sx={{ mx: 1 }}
          onClick={submitNote}
          loading={submittingNote}
          disabled={!noteVal}
        >
          ADD
        </LoadingButton>
      </ListItem>
      {notesList.length > 0 &&
        !notesLoading &&
        notesList?.map((note) => (
          <List key={note.id}>
            <ListItem alignItems="flex-start">
              <ListItemAvatar className="avatar">
                <Avatar>{getUserNameInitials(note.created_by)}</Avatar>
              </ListItemAvatar>
              <Container>
                <pre style={{ margin: "0px" }}>
                  <ListItemText
                    className="all-notes"
                    component={"div"}
                    primary={
                      <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                        {note?.created_by}
                        <span style={{ marginLeft: "10px", fontSize: "10px" }}>
                          {moment(note.created_at).fromNow()}
                        </span>
                      </Typography>
                    }
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline", fontSize: "13px" }}
                          component={"span"}
                          variant="body2"
                          color="text.primary"
                        >
                          {note.note}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </pre>
                {userEmail() === note?.executed_by && (
                  <Typography
                    sx={{
                      textDecoration: "none",
                      "&:hover": { textDecoration: "underline" },
                    }}
                    className="delete-btn"
                    onClick={() => handleDeleteModal(note.id)}
                  >
                    Delete
                  </Typography>
                )}
              </Container>
            </ListItem>
          </List>
        ))}
      {notesLoading && (
        <div>
          {Array.from({ length: 3 }, (_, index) => (
            <NotesSkeleton key={index} />
          ))}
        </div>
      )}
      {openDeleteModal && (
        <DeleteModal
          itemName="Note"
          handleDelete={handleDeleteNote}
          handleClose={handleClose}
          loading={deletingNote}
        />
      )}
    </div>
  );
};

export default Notes;
