import React, { useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

export default function CustomNotify(props) {
  const { open, severity, message } = props;
  const [flag, setFlag] = React.useState(false);

  useEffect(() => {
    setFlag(open);
  }, [open]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFlag(false);
  };

  return (
    <Snackbar open={flag} autoHideDuration={4000} onClose={handleClose}>
      <MuiAlert
        onClose={handleClose}
        severity={severity}
        variant="filled"
        sx={{ width: "100%" }}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
}
