import React, { useState, useRef, useEffect, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import SearchIcon from "@mui/icons-material/Search";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import {
  Button,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
  Card,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  Dialog,
  Alert,
} from "@mui/material";
import PeopleIcon from "../assets/images/people-bg.png";
import "../css/ag-grid.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import CustomLoader from "components/shared/CustomLoader";
import { useDispatch } from "react-redux";
import { updateErrorList } from "../store/employees/employeesSlice";
import { Import } from "components/employees/Import";
import { LoadingButton } from "@mui/lab";
const pagination = true;
const paginationPageSize = 25;

const Employees = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const gridRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [rowData, setRowData] = useState([]);
  const [globalData, setglobalData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [validateImportFile, setValidateImportFile] = useState("");
  const [value, setValue] = useState("All");
  const [refresh, setRefresh] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const checkImportFileStatus = (val) => {
    setValidateImportFile(val);
  };

  const handleItemChange = (e) => {
    setValue(e.target.value);

    let filteredData = globalData;

    switch (e.target.value) {
      case "All":
        filteredData = globalData;
        break;
      case "Terminated":
        filteredData = globalData.filter(
          (item) =>
            item.status === "Inactive" && item.termination_date !== null,
        );
        break;
      case "ActiveTerminated":
        filteredData = globalData.filter(
          (item) => item.status === "Active" && item.termination_date !== null,
        );
        break;
      case "Active":
        filteredData = globalData.filter(
          (item) => item.status === "Active" && item.termination_date === null,
        );
        break;
      case "Inactive":
        filteredData = globalData.filter(
          (item) =>
            item.status === "Inactive" && item.termination_date === null,
        );
        break;
    }

    setRowData(filteredData);
  };

  const onSelectionChanged = () => {
    const selectedNodes = gridApi.getSelectedNodes();
    const selectedIds = selectedNodes.map((node) => node.data.id);
    setSelectedRows(selectedIds);
  };

  const gridOptions = {
    onGridSizeChanged: () => {
      gridOptions.api.sizeColumnsToFit();
    },
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      // width: 128, // set the default column width
      filter: "agTextColumnFilter", // make every column use 'text' filter by default
      floatingFilter: false, // enable floating filters by default
      resizable: true, // make columns resizable
      sortable: true,
      // suppressRowClickSelection: true,
      // rowSelection: "multiple",
    };
  }, []);

  const handleImport = () => {
    setOpenImportModal(true);
  };

  const handleDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleDelete = () => {
    setDeleteLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}employees/bulk/delete`, {
        ids: selectedRows,
      })
      .then(() => {
        closeDeleteModal();
        setSelectedRows("");
        setRefresh(Math.random());
      })
      .catch((error) => {
        const errMessage = error?.response?.data?.message;
        setErrorMessage(errMessage);
      })
      .finally(() => {
        setDeleteLoading(false);
      });
  };

  const closeImportModal = (reason) => {
    if (reason && ["backdropClick", "escapeKeyDown"].indexOf(reason) !== -1) {
      return;
    }
    setOpenImportModal(false);
  };
  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          width: 225,
          maxWidth: 225,
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
      ],
      position: "right",
      defaultToolPanel: "",
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}employees`)
      .then((response) => {
        setRowData(response.data.results);
        setglobalData(response.data.results);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [validateImportFile, refresh]);

  const editEmployee = (params) => {
    dispatch(updateErrorList({}));
    navigate(`/employees/edit/${params.data.id}`);
  };

  const updateTheEmployeeStatus = (e, params, newData) => {
    // setLoading(true);
    const value = e.target.checked ? "Active" : "Inactive";
    const InputData = {
      status: value,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}employee/status/${params.data.id}`,
        InputData,
      )
      .then(() => {
        const gridApi = gridRef.current.api;
        const displayedRows = gridApi.getModel().rowsToDisplay;
        newData = displayedRows.map((el) => el.data);
        newData = newData.map((item) => {
          if (item.id === params.data.id) {
            return {
              ...item,
              status: value,
            };
          }
          return item;
        });
        setRowData(newData);
        setglobalData(newData);
        setRefresh(Math.random());
      })
      .catch((error) => {
        console.error("Something went wrong!", error);
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  const checkFutureTerminationDate = (props) => {
    const today = new Date();
    const targetDate = new Date(props.data.termination_date);
    const isTodayBeforeTargetDate = today <= targetDate;
    if (isTodayBeforeTargetDate) {
      return true;
    }
    return false;
  };

  const [columnDefs] = useState([
    {
      headerName: "",
      field: "",
      sortable: true,
      width: 50,
      maxWidth: 50,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    {
      headerName: "ID",
      field: "id",
      sortable: true,
      width: 80,
      maxWidth: 80,
    },
    {
      headerName: "Employee #",
      field: "employeeNo",
      sortable: true,
      width: 80,
      maxWidth: 100,
    },
    {
      headerName: "Name",
      field: "fullName",
      valueGetter: (props) => {
        return `${props.data.firstName} ${props.data.lastName}`;
      },
      sortable: true,
      width: 150,
      cellRenderer: (props) => {
        return (
          <div>
            {props.data.firstName} {props.data.lastName}
          </div>
        );
      },
    },
    { headerName: "Title", field: "jobTitle", sortable: true, width: 100 },
    { headerName: "Type", field: "employmentType", sortable: true, width: 100 },
    { headerName: "Territory Name", field: "territory_name", sortable: true, width: 150 },
    { headerName: "Territory", field: "territory", sortable: true, width: 100 },
    {
      headerName: "Added on",
      field: "date_added",
      sortable: true,
      width: 150,
    },
    {
      headerName: "Terminated By",
      field: "terminated_by",
      sortable: true,
      width: 100,
    },
    {
      headerName: "Termination Date",
      field: "termination_date",
      sortable: true,
      width: 100,
    },

    {
      headerName: "Active",
      field: "status",
      sortable: true,

      cellRenderer: (props) => {
        return props.data.termination_date === null ||
          checkFutureTerminationDate(props) ? (
          <div>
            <Switch
              size="small"
              color="success"
              name="switch"
              checked={props?.data?.status === "Active" ? true : false}
              onChange={(e) => updateTheEmployeeStatus(e, props, rowData)}
            />
          </div>
        ) : (
          <span className="terminated">Terminated</span>
        );
      },
      // width: 80,
      maxWidth: 100,
    },
    {
      cellRenderer: (props) => {
        return (
          <div>
            <IconButton
              color="info"
              variant="outlined"
              size="small"
              onClick={() => editEmployee(props)}
            >
              <EditIcon />
            </IconButton>
          </div>
        );
      },
      width: 60,
      maxWidth: 60,
      filter: false,
      sortable: false,
    },
  ]);

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
    gridApi.setQuickFilter(e.target.value);
  };

  return (
    <div className="page-content-container">
      <div className="page-header">
        <Grid container direction="row" alignItems="center">
          <Grid item md={5} sm={6}>
            <Stack direction="row" alignItems="center">
              <Typography variant="h5">Manage Employees</Typography>
              <img
                style={{ marginLeft: "3em", maxWidth: "200px" }}
                src={PeopleIcon}
                alt="people"
              />
            </Stack>
          </Grid>
          <Grid item md={7} sm={6}>
            <Stack spacing={2} direction="row" justifyContent="flex-end">
              <Button
                size="small"
                color="info"
                variant="contained"
                onClick={handleImport}
              >
                Import
              </Button>
              <Link to="/employees/add">
                <Button size="small" color="info" variant="contained">
                  Add Employee
                </Button>
              </Link>
            </Stack>
          </Grid>
        </Grid>
      </div>
      <div className="page-content">
        {loading && <CustomLoader />}
        <Card className="custom-card">
          <div className="card-header">
            <Grid container direction="row" alignItems="center">
              <Grid item md={6} sm={6}>
                <Stack spacing={4} direction="row" justifyContent="flex-start">
                  <FormControl
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                    size="small"
                  >
                    <Typography variant="body1" fontWeight={"bold"}>
                      Show
                    </Typography>
                    <Select
                      value={value}
                      sx={{ width: "150px", ml: 1, mr: 1 }}
                      onChange={handleItemChange}
                    >
                      <MenuItem value="All">All</MenuItem>
                      <MenuItem value="Active">Active</MenuItem>
                      <MenuItem value="Inactive">Inactive</MenuItem>
                      <MenuItem value="Terminated">Terminated</MenuItem>
                      <MenuItem value="ActiveTerminated">
                        Active + Terminated
                      </MenuItem>
                    </Select>
                    <Typography variant="body1" fontWeight={"bold"}>
                      Employees
                    </Typography>
                  </FormControl>
                </Stack>
              </Grid>
              <Grid
                item
                md={6}
                sm={6}
                display={"flex"}
                justifyContent={"end"}
                alignItems={"center"}
              >
                {selectedRows?.length !== 0 && (
                  <Button
                    size="small"
                    color="error"
                    variant="contained"
                    onClick={handleDeleteModal}
                    sx={{ height: "26px" }}
                  >
                    Delete {selectedRows?.length}{" "}
                    {selectedRows?.length === 1 ? "Employee" : "Employees"}
                  </Button>
                )}

                <TextField
                  size="small"
                  type="search"
                  label="Search"
                  value={searchTerm}
                  onChange={handleChange}
                  sx={{ width: 250, ml: 2 }}
                  InputProps={{
                    endAdornment: <SearchIcon />,
                  }}
                />
              </Grid>
            </Grid>
          </div>
          <div className="card-content_">
            <div className="table-container ag-theme-alpine ag-theme-alpine--compact">
              <AgGridReact
                headerHeight={35}
                rowData={rowData}
                columnDefs={columnDefs}
                pagination={pagination}
                paginationPageSize={paginationPageSize}
                rowHeight={30}
                // gridOptions={gridOptions}
                suppressRowClickSelection={true}
                rowSelection="multiple"
                onSelectionChanged={onSelectionChanged}
                ref={gridRef}
                sideBar={sideBar}
                defaultColDef={defaultColDef}
                // onCellClicked={handleRowClick}
                onGridReady={onGridReady}
              />
            </div>
          </div>
        </Card>
      </div>

      {openImportModal && (
        <Import
          close={closeImportModal}
          checkImportFileStatus={checkImportFileStatus}
        />
      )}
      {openDeleteModal && (
        <Dialog aria-labelledby="customized-dialog-title" open={true}>
          <IconButton
            aria-label="close"
            onClick={() => {
              closeDeleteModal();
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "gray",
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent className="custom-model-content-height">
            <div className="delete-record">
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <div className="admin-delete-button">
                  <DeleteForeverIcon sx={{ color: "white" }} />
                </div>

                <Typography variant="h5">Are you sure?</Typography>
                <Typography variant="subtitle2" textAlign="center">
                  You are about to delete {selectedRows?.length}{" "}
                  {selectedRows?.length === 1 ? "Employee" : "Employees"} . This
                  action cannot be undone.
                </Typography>
                {errorMessage ? (
                  <Alert
                    severity="error"
                    sx={{
                      width: "300px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <strong>{errorMessage}</strong>
                  </Alert>
                ) : (
                  ""
                )}
              </Stack>
            </div>
          </DialogContent>
          <DialogActions
            // dividers
            sx={{ mr: 2, mb: 1, justifyContent: "space-evenly" }}
          >
            <Button
              size="small"
              variant="outlined"
              color="primary"
              sx={{ width: "120px", borderRadius: "20px" }}
              onClick={() => {
                closeDeleteModal();
              }}
            >
              Take me back
            </Button>

            <LoadingButton
              sx={{ width: "120px", borderRadius: "20px" }}
              size="small"
              variant="contained"
              loading={deleteLoading}
              color="error"
              onClick={handleDelete}
            >
              Yes, Delete
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default Employees;
