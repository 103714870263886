import React, { useEffect, useState } from "react";
import {
  Button,
  IconButton,
  Dialog,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Box,
  Alert,
  MenuItem,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import CustomTextField from "components/shared/CustomTextField";
import { useDispatch } from "react-redux";
import {
  editAddressTypeOptions,
  editCountryOptions,
  editDepartmentOptions,
  editEmailTypeOptions,
  editEmployementTypeOptions,
  editInterfaceOptions,
  editPhoneTypeOptions,
  editPositionOptions,
  editStateOptions,
  editTerritoryLevelAdminOptions,
  editAssignmentTypeOptions,
  editTimeOffTypeOptions,
  upDateDepartmentOptions,
  upDateEmploymentTypeOptions,
  upDatePositionOptions,
  updateAddressTypeOptions,
  updateCountryOptions,
  updateEmailTypeOptions,
  updateInterfaceOptions,
  updatePhoneTypeOptions,
  updateStateOptions,
  updateTerritoryLevelAdminOptions,
  updateAssignmentTypeOptions,
  updateTimeOffTypeOptions,
} from "store/employees/employeesSlice";

const AddItem = (props) => {
  const {
    data,
    close,
    action,
    selectedItem,
    update,
    selectedItemName,
    selectedParentData,
    uniqueDataArray,
  } = props;
  const [initialData, setInitialData] = useState({});
  const [loading, setLoading] = useState(false);
  const [adminErrors, setAdminErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();

  const closeDialog = () => {
    close();
  };

  useEffect(() => {
    let initialData =
      // selectedItemName === "Email" ||
      selectedItemName === "Address Type" || selectedItemName === "Phone"
        ? {
            type: "",
            // sort_order: "",
            status: "Active",
          }
        : selectedItemName === "State"
          ? {
              name: "",
              code: "",
              country_code: "",
              // status: "",
            }
          : selectedItemName === "Country"
            ? {
                name: "",
                code: "",
                // sort_order: "",
                // status: "",
              }
            : selectedItemName === "Interface"
              ? {
                  name: "",
                  field_name: "",
                  // sort_order: "", //not required
                  status: "Active",
                }
              : selectedItemName === "Territory Levels"
                ? {
                    name: "",
                    parent_id: selectedParentData?.id,
                    is_singleton: false,
                    status: "Active",
                  }
                : selectedItemName === "Assignment Type"
                  ? {
                      assignment_type_name: "",
                      // sort_order: "",
                      status: "Active",
                    }
                  : selectedItemName === "Time Off Type"
                    ? {
                        time_off_type_name: "",
                        // sort_order: "",
                      }
                    : selectedItemName === "Employment Type"
                      ? {
                          name: "",
                          prefix: "",
                          // sort_order: "",
                          status: "Active",
                        }
                      : selectedItemName === "Department"
                        ? {
                            name: "",
                            // abbreviation: "",
                            // sort_order: "",
                            status: "Active",
                          }
                        : selectedItemName === "Email"
                          ? {
                              type: "",
                              // sort_order: "",
                              domain: "",
                              status: "Active",
                            }
                          : {
                              name: "",
                              abbreviation: "",
                              // sort_order: "",
                              status: "Active",
                            };
    if (action === "edit") {
      initialData =
        // selectedItemName === "Email Type" ||
        selectedItemName === "Address Type" || selectedItemName === "Phone Type"
          ? {
              type: data.type,
              // sort_order: data.sort_order,
              status: data.status,
            }
          : selectedItemName === "State"
            ? {
                name: data.name,
                code: data.code,
                country_code: data.country_code,
                // status: data.status,
              }
            : selectedItemName === "Country"
              ? {
                  name: data.name,
                  code: data.code,
                  // sort_order: data.sort_order,
                  // status: data.status,
                }
              : selectedItemName === "Interface"
                ? {
                    name: data.name,
                    field_name: data.field_name,
                    // sort_order: data.sort_order,
                    status: data.status,
                  }
                : selectedItemName === "Territory Levels"
                  ? {
                      name: selectedParentData?.name,
                      parent_id: selectedParentData?.parent_id,
                      is_singleton: selectedParentData?.is_singleton,
                      status: selectedParentData?.status,
                    }
                  : selectedItemName === "Assignment Type"
                    ? {
                        assignment_type_name: data.assignment_type_name,
                        // sort_order: data.sort_order,
                        status: data.status,
                      }
                    : selectedItemName === "Time Off Type"
                      ? {
                          time_off_type_name: data.time_off_type_name,
                          // sort_order: data.sort_order,
                        }
                      : selectedItemName === "Employment Type"
                        ? {
                            type: data.type,
                            prefix: data.prefix,
                            // sort_order: data.sort_order,
                            status: data.status,
                          }
                        : selectedItemName === "Department"
                          ? {
                              name: data.name,
                              // abbreviation: "",
                              // sort_order: "",
                              status: data.status,
                            }
                          : selectedItemName === "Email Type"
                            ? {
                                type: data.type,
                                status: data.status,
                                domain:data.domain
                              }
                            : {
                                name: data.name,
                                abbreviation: data.abbreviation,
                                // sort_order: data.sort_order,
                                status: data.status,
                              };
    }
    setInitialData(initialData);
  }, [action]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInitialData({
      ...initialData,
      [name]: name === "code" ? value?.replace(/[^a-zA-Z]/g, "") : value,
    });
    if (value) {
      setAdminErrors({ ...adminErrors, [name]: false });
    }
  };

  const handleCheckbox = (e) => {
    const { name, checked } = e.target;
    setInitialData({ ...initialData, [name]: checked ? "Active" : "Inactive" });
    if (checked) setAdminErrors({ ...adminErrors, [name]: false });
  };
  const handleSingletonCheckbox = (e) => {
    const { name, checked } = e.target;
    setInitialData({ ...initialData, [name]: checked });
    if (checked) setAdminErrors({ ...adminErrors, [name]: false });
  };

  const validateForm = () => {
    let errors =
      selectedItemName === "Email Type"
        ? {
            type: false,
            // sort_order: false,
            domain: false,
            status: false,
          }
        : selectedItemName === "Address Type" ||
            selectedItemName === "Phone Type"
          ? {
              type: false,
              status: false,
            }
          : selectedItemName === "State"
            ? {
                name: false,
                code: false,
                country_code: false,
                // status: false,
              }
            : selectedItemName === "Country"
              ? {
                  name: false,
                  code: false,
                  // sort_order: false,
                  // status: false,
                }
              : selectedItemName === "Interface"
                ? {
                    name: false,
                    field_name: false,
                    // sort_order: false, //not required
                    status: false,
                  }
                : selectedItemName === "Territory Levels"
                  ? {
                      name: false,
                      // parent_id: false,
                      // is_singleton: false,
                      status: false,
                    }
                  : selectedItemName === "Assignment Type"
                    ? {
                        assignment_type_name: false,
                        // sort_order: false,
                        status: false,
                      }
                    : selectedItemName === "Time Off Type"
                      ? {
                          time_off_type_name: false,
                          // sort_order: false,
                        }
                      : selectedItemName === "Employment Type"
                        ? {
                            type: false,
                            prefix: false,
                            // sort_order: false,
                            status: false,
                          }
                        : selectedItemName === "Department"
                          ? {
                              name: false,
                              // abbreviation: "",
                              // sort_order: "",
                              status: false,
                            }
                          : {
                              name: false,
                              abbreviation: false,
                              // sort_order: false,
                              status: false,
                            };

    Object.entries(errors).forEach(([key, value]) => {
      if (
        initialData[key] === "" ||
        initialData[key] === null ||
        initialData[key] === "Inactive" ||
        initialData[key] === undefined
      ) {
        errors[key] = true;
      }
    });

    setAdminErrors(errors);
    return Object.values(errors).indexOf(true) > -1;
  };

  const dispatchAddFunction = (data) => {
    const addFunctionMap = {
      Position: upDatePositionOptions,
      Department: upDateDepartmentOptions,
      "Employment Type": upDateEmploymentTypeOptions,
      "Email Type": updateEmailTypeOptions,
      "Phone Type": updatePhoneTypeOptions,
      "Address Type": updateAddressTypeOptions,
      State: updateStateOptions,
      Country: updateCountryOptions,
      Interface: updateInterfaceOptions,
      "Territory Levels": updateTerritoryLevelAdminOptions,
      "Assignment Type": updateAssignmentTypeOptions,
      "Time Off Type": updateTimeOffTypeOptions,
    };

    const addFunction = addFunctionMap[selectedItemName];

    if (addFunction) {
      dispatch(addFunction([data]));
    } else {
      console.log("Invalid selected item:", selectedItemName);
    }
  };

  const dispatchEditFunction = (data) => {
    const editFunctionMap = {
      Position: editPositionOptions,
      Department: editDepartmentOptions,
      "Employment Type": editEmployementTypeOptions,
      "Email Type": editEmailTypeOptions,
      "Phone Type": editPhoneTypeOptions,
      "Address Type": editAddressTypeOptions,
      State: editStateOptions,
      Country: editCountryOptions,
      Interface: editInterfaceOptions,
      "Territory Levels": editTerritoryLevelAdminOptions,
      "Assignment Type": editAssignmentTypeOptions,
      "Time Off Type": editTimeOffTypeOptions,
    };

    const editFunction = editFunctionMap[selectedItemName];

    if (editFunction) {
      dispatch(editFunction(data));
    } else {
      console.log("Invalid selected item:", selectedItemName);
    }
  };

  const saveRecord = () => {
    if (validateForm()) {
      return;
    }

    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}${selectedItem}`, initialData)
      .then((response) => {
        dispatchAddFunction(response.data.results);
        close();
        if (response) {
          update(Math.random());
        }
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage(error?.response?.data?.message);
      })

      .finally(() => {
        setLoading(false);
      });
  };

  const updateRecord = () => {
    if (validateForm()) {
      return;
    }
    let ID =
      selectedItemName === "State" || selectedItemName === "Country"
        ? data?.code
        : selectedItemName === "Territory Levels"
          ? selectedParentData.id
          : data?.id;
    setLoading(true);
    axios
      .put(`${process.env.REACT_APP_API_URL}${selectedItem}/${ID}`, {
        ...initialData,
      })
      .then((response) => {
        // dispatch(editPositionOptions(initialData));
        dispatchEditFunction(response.data.results);
        close();
        if (response) {
          update(Math.random());
        }
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage(error?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={true}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className="bg-ligh-blue">
        {" "}
        {action === "add"
          ? selectedItemName === "Territory Levels"
            ? `Add Level for - ${selectedParentData?.name}`
            : `Add ${selectedItemName}`
          : selectedItemName === "Territory Levels"
            ? `Edit  ${selectedParentData?.name}`
            : `Edit ${selectedItemName}`}{" "}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          closeDialog();
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent className="custom-model-content-height">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={6}
              // style={{
              //   display: selectedItemName === "Territory Levels" && "none",
              // }}
            >
              {selectedItemName === "Email Type" ||
              selectedItemName === "Address Type" ||
              selectedItemName === "Phone Type" ||
              selectedItemName === "Employment Type" ? (
                <CustomTextField
                  label="Type"
                  name="type"
                  id="type"
                  variant="outlined"
                  size="small"
                  // defaultValue={""}
                  // fullWidth
                  sx={{ width: "250px" }}
                  required
                  value={initialData.type || ""}
                  onChange={($e) => handleInputChange($e)}
                  error={Boolean(adminErrors?.type)}
                  helperText={adminErrors?.type ? "This field is required" : ""}
                ></CustomTextField>
              ) : selectedItemName === "Assignment Type" ? (
                <CustomTextField
                  label="Assignment Type "
                  name="assignment_type_name"
                  variant="outlined"
                  size="small"
                  // defaultValue={""}
                  sx={{ width: "250px" }}
                  // fullWidth
                  required
                  value={initialData.assignment_type_name || ""}
                  onChange={($e) => handleInputChange($e)}
                  error={Boolean(adminErrors?.assignment_type_name)}
                  helperText={
                    adminErrors?.assignment_type_name
                      ? "This field is required"
                      : ""
                  }
                >
                  {" "}
                </CustomTextField>
              ) : selectedItemName === "Time Off Type" ? (
                <CustomTextField
                  label="Time Off Type "
                  name="time_off_type_name"
                  variant="outlined"
                  size="small"
                  // defaultValue={""}
                  // fullWidth
                  sx={{ width: "250px" }}
                  required
                  value={initialData.time_off_type_name || ""}
                  onChange={($e) => handleInputChange($e)}
                  error={Boolean(adminErrors?.time_off_type_name)}
                  helperText={
                    adminErrors?.time_off_type_name
                      ? "This field is required"
                      : ""
                  }
                >
                  {" "}
                  ?
                </CustomTextField>
              ) : selectedItemName === "State" ||
                selectedItemName === "Country" ? (
                <CustomTextField
                  label="Code"
                  name="code"
                  variant="outlined"
                  size="small"
                  // defaultValue={""}
                  // fullWidth
                  sx={{ width: "250px" }}
                  inputProps={{
                    maxLength: 2,
                  }}
                  required
                  value={initialData.code || ""}
                  onChange={($e) => handleInputChange($e)}
                  error={Boolean(adminErrors?.code)}
                  helperText={adminErrors?.code ? "This field is required" : ""}
                ></CustomTextField>
              ) : (
                <CustomTextField
                  label="Name"
                  name="name"
                  variant="outlined"
                  size="small"
                  // defaultValue={""}
                  // fullWidth
                  sx={{ width: "250px" }}
                  required
                  // disabled={action === "edit" ? true : false}
                  value={initialData.name || ""}
                  onChange={($e) => handleInputChange($e)}
                  error={Boolean(adminErrors.name)}
                  helperText={adminErrors.name ? "This field is required" : ""}
                ></CustomTextField>
              )}
            </Grid>

            <Grid
              item
              xs={6}
              style={{
                display:
                  // selectedItemName === "Email Type" ||
                  selectedItemName === "Address Type" ||
                  selectedItemName === "Phone Type" ||
                  // selectedItemName === "Employment Type" ||
                  selectedItemName === "Department" ||
                  selectedItemName === "Territory Levels" ||
                  selectedItemName === "Assignment Type" ||
                  selectedItemName === "Time Off Type"
                    ? "none"
                    : "block",
              }}
            >
              {selectedItemName === "State" ||
              selectedItemName === "Country" ? (
                // <CustomTextField
                //   label="Code"
                //   name="code"
                //   variant="outlined"
                //   size="small"
                //   defaultValue={""}
                //   // fullWidth
                //   sx={{ width: "250px" }}
                //   required
                //   value={initialData.code || ""}
                //   onChange={($e) => handleInputChange($e)}
                //   error={adminErrors?.code}
                //   helperText={adminErrors?.code ? "This field is required" : ""}
                // ></CustomTextField>
                <CustomTextField
                  label="Name"
                  name="name"
                  variant="outlined"
                  size="small"
                  // defaultValue={""}
                  // fullWidth
                  sx={{ width: "250px" }}
                  required
                  // disabled={action === "edit" ? true : false}
                  value={initialData.name || ""}
                  onChange={($e) => handleInputChange($e)}
                  error={Boolean(adminErrors.name)}
                  helperText={adminErrors.name ? "This field is required" : ""}
                ></CustomTextField>
              ) : selectedItemName === "Interface" ? (
                <CustomTextField
                  label="Field Name"
                  name="field_name"
                  variant="outlined"
                  size="small"
                  // defaultValue={""}
                  // fullWidth
                  sx={{ width: "250px" }}
                  required
                  value={initialData.field_name || ""}
                  onChange={($e) => handleInputChange($e)}
                  error={Boolean(adminErrors?.field_name)}
                  helperText={
                    adminErrors?.field_name ? "This field is required" : ""
                  }
                ></CustomTextField>
              ) : selectedItemName === "Employment Type" ? (
                <CustomTextField
                  label="Prefix"
                  name="prefix"
                  variant="outlined"
                  size="small"
                  // defaultValue={""}
                  // fullWidth
                  sx={{ width: "250px" }}
                  required
                  value={initialData.prefix || ""}
                  onChange={($e) => handleInputChange($e)}
                  error={Boolean(adminErrors?.prefix)}
                  helperText={
                    adminErrors?.prefix ? "This field is required" : ""
                  }
                ></CustomTextField>
              ) : selectedItemName === "Email Type" ? (
                <CustomTextField
                  label="Domain"
                  name="domain"
                  variant="outlined"
                  size="small"
                  // defaultValue={""}
                  // fullWidth
                  sx={{ width: "250px" }}
                  required
                  value={initialData.domain || ""}
                  onChange={($e) => handleInputChange($e)}
                  error={Boolean(adminErrors?.domain)}
                  helperText={
                    adminErrors?.domain ? "This field is required" : ""
                  }
                ></CustomTextField>
              ) : (
                <CustomTextField
                  label="Abbreviation"
                  name="abbreviation"
                  variant="outlined"
                  size="small"
                  // defaultValue={""}
                  // fullWidth
                  sx={{ width: "250px" }}
                  required
                  value={initialData.abbreviation || ""}
                  onChange={($e) => handleInputChange($e)}
                  error={Boolean(adminErrors?.abbreviation)}
                  helperText={
                    adminErrors?.abbreviation ? "This field is required" : ""
                  }
                ></CustomTextField>
              )}
            </Grid>

            <Grid
              item
              xs={6}
              style={{
                display: selectedItemName === "State" ? "block" : "none",
              }}
            >
              {/* <CustomTextField
                label="Country Code"
                name="country_code"
                variant="outlined"
                size="small"
                defaultValue={""}
                // onKeyPress={handleInputKeyPress}
                // fullWidth
                sx={{ width: "250px" }}
                inputProps={{
                  maxLength: 2,
                }}
                required
                // disabled={action === "edit" ? true : false}
                value={initialData.country_code || ""}
                onChange={($e) => handleInputChange($e)}
                error={adminErrors.country_code}
                helperText={
                  adminErrors.country_code ? "This field is required" : ""
                }
              ></CustomTextField> */}

              <CustomTextField
                required
                label="Country Code"
                name="country_code"
                value={initialData.country_code || ""}
                onChange={($e) => handleInputChange($e)}
                error={Boolean(adminErrors.country_code)}
                size="small"
                // fullWidth
                sx={{ width: "250px" }}
                select
                inputProps={{
                  max: "9999-12-31",
                  maxLength: 10,
                }}
                // defaultValue={""}
                helperText={
                  adminErrors.country_code ? "This field is required" : ""
                }
              >
                {uniqueDataArray &&
                  uniqueDataArray.map((option) => (
                    <MenuItem key={option.code} value={option.code}>
                      {option.code}
                    </MenuItem>
                  ))}
              </CustomTextField>
            </Grid>
            {selectedItemName === "Time Off Type" ||
              selectedItemName === "State" ||
              (selectedItemName === "Country" ? (
                ""
              ) : (
                <>
                  <Grid item xs={3}>
                    <FormGroup
                      row
                      required
                      sx={{ alignItems: "center" }}
                      error={adminErrors.status?.toString()}
                    >
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Active"
                        size="small"
                        name="status"
                        required
                        checked={Boolean(
                          initialData.status === "Active" ? true : false,
                        )}
                        onChange={($e) => handleCheckbox($e)}
                        // error={adminErrors.status}
                        // error={adminErrors.status ? 'true' : 'false'}
                        // error={adminErrors.status ? adminErrors.status : undefined}
                      />

                      {adminErrors.status && (
                        <FormHelperText className="text-error">
                          Active is required
                        </FormHelperText>
                      )}
                    </FormGroup>
                  </Grid>
                  {selectedItemName === "Territory Levels" ? (
                    <Grid item xs={3}>
                      <FormGroup
                        row
                        required
                        sx={{ alignItems: "center" }}
                        // error={adminErrors.is_singleton}
                      >
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Singleton"
                          size="small"
                          name="is_singleton"
                          // required
                          checked={Boolean(initialData.is_singleton || "")}
                          onChange={($e) => handleSingletonCheckbox($e)}
                          // error={adminErrors.is_singleton}
                        />

                        {/* {adminErrors.is_singleton && (
                          <FormHelperText className="text-error">
                            Singleton is required
                          </FormHelperText>
                        )} */}
                      </FormGroup>
                    </Grid>
                  ) : (
                    ""
                  )}
                </>
              ))}

            <Grid item xs={12}>
              {errorMessage ? (
                <Alert
                  severity="error"
                  sx={{ width: "550px", display: "flex", alignItems: "center" }}
                >
                  <strong>{Object?.values(errorMessage)}</strong>
                </Alert>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Box>
      </DialogContent>

      <DialogActions
        // dividers
        sx={{ mr: 2, mb: 1 }}
      >
        <Button
          sx={{ width: "100px" }}
          size="small"
          variant="outlined"
          color="primary"
          onClick={() => {
            closeDialog();
          }}
        >
          Cancel
        </Button>

        <LoadingButton
          sx={{ width: "100px" }}
          size="small"
          variant="contained"
          loading={loading}
          color="primary"
          onClick={() => (action === "edit" ? updateRecord() : saveRecord())}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddItem;
