export const getUserData = (localStorageKeyName) => {
  let data = localStorage.getItem(localStorageKeyName);

  if (data) {
    data = JSON.parse(data);
  }

  return data;
};

export const UID = () => {
  // return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
  //   (
  //     c ^
  //     (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
  //   ).toString(16)
  // );
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < 8) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export const agGridConfigs = () => {
  return {
    flex: 1,
    // set the default column width
    // width: 128,

    // make every column use 'text' filter by default
    filter: "agTextColumnFilter",
    // enable floating filters by default
    floatingFilter: false,
    // make columns resizable
    resizable: true,
    sortable: true,
  };
};

export const colorPalletes = () => {
  return [
    "#27aae1",
    //"#2caffe",
    "#544fc5",
    "#00e272",
    "#fe6a35",
    "#6b8abc",
    "#d568fb",
    "#2ee0ca",
    "#fa4b42",
    "#feb56a",
    "#91e8e12",
  ];
};


export const dateProp = () => ({
  type: 'date',
  inputProps: {
    max: '9999-12-31',
    maxLength: 10,
  },
  
});

  // Utility function to get matches and calculate row height
export const calculateRowHeight = (messageString) => {
  const regex = /'([^']*)'/g;
  const matches = messageString?.match(regex);
    
  return matches;
};

// Convert snake_case or camelCase to Title Case
export const mapKeyToDisplayName = (key) => {
  const displayName = key
    .replace(/_/g, ' ')
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/\b\w/g, (word) => word.toUpperCase());

  return displayName;
};


export function isFutureDate(dateString) {
  var inputDate = new Date(dateString);
  var currentDate = new Date();
  return inputDate > currentDate;
}

export const getUserNameInitials = (userName) => {
  let name = userName ? userName : "";
  if (!userName) {
  let data = localStorage.getItem("user_data");
  if (data) {
    data = JSON.parse(data);
    name = data.displayName
      ? data.displayName
      : data.givenName
        ? data.givenName
        : data.surname;
  }
  }
  let index = name.indexOf(" ");
  let name_initials = name.charAt(0);
  let surname_initials = index > 0 ? name.charAt(index + 1) : "";
  let initials = name_initials + surname_initials;
  return initials;
};

export const userEmail = () => {
  let email = "";
  let data = localStorage.getItem("user_data");
  data = JSON.parse(data);
  email = data.mail;
  return email;
};
