import React from "react";
import { TextField } from "@mui/material";

export default function CustomTextField({ InputLabelProps = {}, ...props }) {
  return (
    <TextField
      // defaultValue=""
      InputLabelProps={{ ...InputLabelProps, shrink: true }}
      {...props}
    />
  );
}
