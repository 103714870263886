import React, { useEffect } from "react";
import { Grid, Card, CardContent, Typography, Box } from "@mui/material";
import BarChart from "../charts/BarChart";
import axios from "axios";
import CardSkeleton from "components/shared/CardSkeleton";
import StatsCard from "../employee/StatsCard";

export default function TerritoryReport(props) {
  const { value } = props;
  const [TerritoryByTeam, setTerritoryByTeam] = React.useState([]);
  const [teamLoading, setTeamLoading] = React.useState(false);
  const [positionLoading, setPositionLoading] = React.useState(false);
  const [TerritoriesByPosition, setTerritoriesByPosition] = React.useState([]);
  const [typeLoading, setTypeLoading] = React.useState(false);
  const [TerritoriesByEmploymentType, setTerritoriesByEmploymentType] =
    React.useState([]);

  const [statsLoading, setStatsLoading] = React.useState(false);
  const [TerritoryStats, setTerritoryStats] = React.useState({
    total: 0,
    assigned: 0,
    unassigned: 0,
    active: 0,
    inactive: 0,
    timeOff: 0,
  });

  useEffect(() => {
    getStatsData();
    getTerritoriesByTeam();
    getTerritoriesByPosition();
    getEmployeesyType();
  }, [value]);

  const getEmployeesyType = () => {
    setTypeLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}report/territory/split-by-employment-type?team_id=${value}`,
      )
      .then((response) => {
        setTerritoriesByEmploymentType(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setTypeLoading(false);
      });
  };

  const getTerritoriesByPosition = () => {
    setPositionLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}report/territory/split-by-position?team_id=${value}`,
      )
      .then((response) => {
        setTerritoriesByPosition(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setPositionLoading(false);
      });
  };

  const getTerritoriesByTeam = () => {
    setTeamLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}report/territory/split-by-team?team_id=${value}`,
      )
      .then((response) => {
        setTerritoryByTeam(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setTeamLoading(false);
      });
  };

  const getStatsData = () => {
    setStatsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}report/territory?team_id=${value}`)
      .then((response) => {
        setTerritoryStats(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setStatsLoading(false);
      });
  };

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{ mt: 0.5 }}
      >
        <Grid item xs={12} md={12}>
          {statsLoading ? (
            <CardSkeleton small="true" />
          ) : (
            <StatsCard title={"Territories"} stats={TerritoryStats} />
          )}
        </Grid>
        {
          value === 'all' 
          ? 
          <Grid item md={12} sm={12}>
             {positionLoading ? (
            <CardSkeleton />
          ) : (
          <Card className="report-card">
            <CardContent>
              <Typography className="card-title no-padding">
                 # of Territories By Team 
              </Typography>
              <Box
                // display="flex"
                // justifyContent="center"
                sx={{ mt: 1.5, width: "100%",height:'100%' }}
              >
                <BarChart  data={TerritoryByTeam}/>
              </Box>
            </CardContent>
          </Card>
          )}
        </Grid>
         :
         ''
        }
        <Grid item md={12} sm={12}>
          {positionLoading ? (
            <CardSkeleton />
          ) : (
            <Card className="report-card">
              <CardContent>
                <Typography className="card-title no-padding">
                  # of Territories By Position
                </Typography>
                <Box
                  // display="flex"
                  // justifyContent="center"
                  sx={{ mt: 1.5, width: "100%" ,height:'100%'}}
                >
                  <BarChart data={TerritoriesByPosition} />
                </Box>
              </CardContent>
            </Card>
          )}
        </Grid>
        <Grid item md={12} sm={12}>
          {typeLoading ? (
            <CardSkeleton />
          ) : (
            <Card className="report-card">
              <CardContent>
                <Typography className="card-title no-padding">
                  # of Territories By Employment Type
                </Typography>
                <Box
                  // display="flex"
                  // justifyContent="center"
                  sx={{ mt: 1.5, width: "100%",height:'100%' }}
                >
                  <BarChart data={TerritoriesByEmploymentType} />
                </Box>
              </CardContent>
            </Card>
          )}
        </Grid>

        
      </Grid>

      {/* <Grid
        container
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{ mt: 0.1 }}
      >
        <Grid item md={6} sm={6}>
          <Card className="report-card">
            <CardContent>
              <Typography className="card-title no-padding">
                Split By Geography
              </Typography>
              <Box
                display="flex"
                justifyContent="center"
                sx={{ mt: 1.5, width: "100%" }}
              >
                 <PieChart data={EmployeesByPosition} />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid> */}
    </>
  );
}
