import { createBrowserRouter } from "react-router-dom";

import MainLayout from "pages/MainLayout";
import ParentLayout from "pages/ParentLayout";
import Employees from "pages/Employees";
import NotFound from "pages/NotFound";
import Reports from "pages/Reports";
import Admin from "pages/Admin";
import AddEmployee from "components/employees/AddEmployee";
import TerritoryHierarchy from "pages/TerritoryHierarchy";
import Notification from "pages/Notification";
import NotificationLayout from "pages/NotificationLayout";

const router = createBrowserRouter([
  {
    path: "",
    element: <ParentLayout />,
    errorElement: <NotFound />,
    children: [
      {
        path: "",
        element: <MainLayout />,
        errorElement: <NotFound />,
        children: [
          {
            path: "/",
            element: <Employees />,
          },
          {
            path: "/employees/add",
            element: <AddEmployee />,
          },
          {
            path: "/employees/edit/:id",
            element: <AddEmployee />,
          },
          {
            path: "/territoryHierarchy",
            element: <TerritoryHierarchy />,
          },
          {
            path: "/reports",
            element: <Reports />,
          },
          {
            path: "/admin/:id",
            element: <Admin />,
          },
        ],
      },
      {
        path: "",
        element: <NotificationLayout />,
        children: [
          {
            path: "notification",
            element: <Notification />,
          },
          {
            path: "/notification/:id",
            element: <Notification />,
          },
        ],
      },
    ],
  },
]);

export default router;
