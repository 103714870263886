import React, { useEffect, useState } from "react";
import { Grid, Typography, Box, Stack, Button, Alert } from "@mui/material";
import GenericTerritoryForm from "./territory_level_forms/GenericTerritoryForm";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import useSetState from "hooks/useSetState";
import { updateTerrModuleFormErrors } from "store/employees/employeesSlice";
import { useSelector, useDispatch } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";

export default function HierarchyFormLayout(props) {
  const {
    action,
    selectedRow,
    close,
    loading,
    setLoading,
    setIsSaved,
    refreshHierarchy,
    topNode,
    formLoader,
  } = props;

  const dispatch = useDispatch();
  const formErrors = useSelector(
    (state) => state.employees.terrModuleFormErrors,
  );
  const [state, setState] = useSetState({});
  const [employeeOptions, setEmployeeOptions] = useState([]);

  useEffect(() => {
    if (action === "Edit" || !action) {
      dispatch(updateTerrModuleFormErrors(null));
      setState({
        id: selectedRow.id,
        territory_level: selectedRow.level,
        territory_number: selectedRow.territory_number,
        parent_id: selectedRow.parent_id,
        territory_name: selectedRow.territory_name,
        start_date: selectedRow.start_date,
        end_date: selectedRow.end_date,
        territory_assignments: selectedRow.territory_assignments,
      });
    } else if (action === "Add") {
      dispatch(updateTerrModuleFormErrors(null));

      setState({
        parent_id: selectedRow.id,
        id: "",
        territory_level: selectedRow.level,
        territory_number: "",
        territory_name: "",
        start_date: "",
        end_date: "",
        action: null,
        openModal: false,
        territory_assignments: [],
      });
    }
  }, [action, selectedRow]);

  useEffect(() => {
    getEmployeeOptions();
  }, []);

  const getEmployeeOptions = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}employee-list`)
      .then((response) => {
        setEmployeeOptions(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({
      [name]: value,
    });
    if (value) {
      const newEmpErrors = { ...formErrors, [name]: false };
      dispatch(updateTerrModuleFormErrors(newEmpErrors));
    }
  };

  const openAssignmentModel = (action) => {
    setState({
      openModal: true,
      action: action,
    });
  };

  const closeAssignmentModel = (event, reason) => {
    if (reason && ["backdropClick", "escapeKeyDown"].indexOf(reason) !== -1) {
      return;
    }
    setState({
      openModal: false,
      action: null,
    });
  };

  const isValidationFailed = (errorsObj) => {
    if (errorsObj && Object.keys(errorsObj).length) {
      const errors = Object.values(errorsObj).indexOf(true) > -1;

      if (errors) {
        return true;
      }
      return false;
    }
    return false;
  };

  const validateForm = () => {
    let errors = {
      territory_number: false,
      territory_name: false,
      start_date: false,
    };

    Object.entries(errors).forEach(([key, value]) => {
      if (state[key] === "" || state[key] === null) {
        errors[key] = true;
      }
    });

    dispatch(updateTerrModuleFormErrors(errors));
    return isValidationFailed(errors);
  };

  const isEndDateBeforeStartDate = () => {
    if (state.end_date !== "") {
      return state.start_date > state.end_date;
    }
    return false;
  };
  const endDateBeforeStartDate = isEndDateBeforeStartDate();

  const Save = () => {
    if (validateForm()) {
      return;
    }
    if (endDateBeforeStartDate) {
      return;
    }
    setLoading(true);
    setIsSaved(null);

    const newState = {
      ...state,
      territory_assignments: [
        ...state.territory_assignments.map((item) => {
          const { id, employee, ...rest } = item;
          return {
            ...rest,
            employee: id,
          };
        }),
      ],
    };

    if (action === "Edit") {
      axios
        .put(`${process.env.REACT_APP_API_URL}territory/${state.id}`, newState)
        .then((response) => {
          refreshHierarchy();
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_API_URL}territory`, newState)
        .then((response) => {
          refreshHierarchy();
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  const handleAlertEndDate = () => {
    if (state?.territory_assignments?.length > 0) {
      let getLatestAssgn =
        state?.territory_assignments?.[state.territory_assignments?.length - 1];
      let val = getLatestAssgn?.end_date;
      if (val && typeof val === "string" && val.length >= 10) {
        const specificDate = new Date(
          Date.UTC(
            parseInt(val.slice(0, 4)),
            parseInt(val.slice(5, 7)) - 1,
            parseInt(val.slice(8, 10)),
            0,
            0,
            0,
            0,
          ),
        );
        const currentdate = new Date();
        const checkDateDiffernce =
          specificDate?.getTime() - currentdate?.getTime();
        const daysDifference = Math.floor(
          checkDateDiffernce / (1000 * 60 * 60 * 24),
        );
        const hoursDifference = Math.floor(
          (checkDateDiffernce % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
        );

        specificDate.setDate(specificDate?.getDate() - 13);
        const finalDate = specificDate?.toISOString()?.split("T")[0];

        if (
          finalDate <= currentdate?.toISOString()?.split("T")[0] &&
          currentdate?.toISOString()?.split("T")[0] <= val
        ) {
          return (
            <Alert variant="filled" severity="error" sx={{ m: 1 }}>
              The Employee Assignment is set to expire in the next{" "}
              {daysDifference + 2}  {daysDifference+2 === 1 ? 'day' : 'days'}
            </Alert>
          );
        } else {
          return "";
        }
      } else {
        return "";
      }
    }
  };

  return (
    <div className="hierarchy-form-container">
      <Grid
        container
        className="bg-ligh-grey"
        direction="row"
        alignItems="center"
        sx={{ p: 1, borderRadius: "8px 8px 0 0" }}
      >
        <Grid item>
          <Typography variant="h6" className="title-case">
            {action} {selectedRow?.level?.toLowerCase()}
          </Typography>
        </Grid>
      </Grid>
      {formLoader && <LinearProgress />}
      {handleAlertEndDate()}
      <div className="box-shadow">
        <Box sx={{ p: 0 }} className="form-content">
          <GenericTerritoryForm
            state={state}
            setState={setState}
            selectedRow={selectedRow}
            handleInputChange={handleInputChange}
            action={action}
            openAssignmentModel={openAssignmentModel}
            closeAssignmentModel={closeAssignmentModel}
            endDateBeforeStartDate={endDateBeforeStartDate}
            employeeOptions={employeeOptions}
            topNode={topNode}
          />
        </Box>
        <Stack
          className="form-actions"
          direction="row"
          justifyContent="flex-end"
          spacing={1}
          sx={{ p: 1, mt: 2 }}
        >
          <Button
            size="small"
            disabled={loading}
            variant="outlined"
            onClick={close}
          >
            Cancel
          </Button>
          <LoadingButton
            sx={{ width: "100px" }}
            size="small"
            variant="contained"
            loading={loading}
            color="primary"
            onClick={Save}
          >
            Save
          </LoadingButton>
        </Stack>
      </div>
    </div>
  );
}
