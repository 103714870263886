import React from "react";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Grid, Chip, Card, Stack, Typography } from "@mui/material";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useParams } from "react-router-dom";

const NotificationCard = (props) => {
  const { id } = useParams();
  const { data, handleNotification } = props;
  const originalDate = new Date(data.created_at);

  const year = originalDate.getFullYear();
  const month = (originalDate.getMonth() + 1).toString().padStart(2, "0");
  const day = originalDate.getDate().toString().padStart(2, "0");
  const hours = originalDate.getHours().toString().padStart(2, "0");
  const minutes = originalDate.getMinutes().toString().padStart(2, "0");
  const seconds = originalDate.getSeconds().toString().padStart(2, "0");
  const meridiem = hours >= 12 ? "PM" : "AM";
  const formattedTime = `${hours % 12 || 12}:${minutes}:${seconds}${meridiem}`;

  const formatteddate = `${year}-${month}-${day}, ${formattedTime}`;

  return (
    <Card
      className={`notification-card ${data.id === parseInt(id) ? "active": ''}`}
      onClick={() => handleNotification(data.id)}
    >
      <div className="notification-card-outer">
        <div className="notification-card-inner">
          {data.read_flag === true ? (
            ""
          ) : (
            <FiberManualRecordIcon className="notification-dot" />
          )}
          <CircleNotificationsIcon className="notification-icon" />
        </div>
        <Grid item xs={10}>
          <Grid
            container
            display={"flex"}
            flexDirection={"column"}
           
          >
            <Grid item display={"flex"} justifyContent={"space-between"}>
              <Typography
                variant="subtitle2"
                sx={{ fontSize: "13px", fontWeight: 600 }}
              >
                {data.title}
              </Typography>
            </Grid>
            <Grid item display={"flex"} alignItems={"center"}>
              <InsertDriveFileOutlinedIcon
                className="card-icons"
                sx={{ fontSize: 14 }}
              />
              <Typography variant="subtitle1">{data?.file_path}</Typography>
            </Grid>
            <Grid item display={"flex"} alignItems={"center"}>
              <CalendarMonthIcon className="card-icons" sx={{ fontSize: 14 }} />
              <Typography variant="subtitle1">{formatteddate}</Typography>
            </Grid>
            <Grid item>
              <Stack direction="row" spacing={1} sx={{ marginTop: "15px" }}>
                <Chip
                  label={`Success-${data?.success_count}`}
                  size="small"
                  className="notification-chip success"
                />
                <Chip
                  label={`Skipped-${data?.skipped_count}`}
                  size="small"
                  className="notification-chip warning"
                />
                <Chip
                  label={`Failed-${data?.failed_count}`}
                  size="small"
                  className="notification-chip failed"
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
};

export default NotificationCard;
