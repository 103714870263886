import React, { useState } from "react";
import EmployeeReport from "./employee/EmployeeReport";
import {
  Grid,
  Select,
  FormControl,
  MenuItem,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import TerritoryReport from "./territory/TerritoryReport";

export const Report = () => {
  const TeamOptions = useSelector((state) => state.employees.TeamOptions);
  const [value, setValue] = useState("all");
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div>
      <FormControl
        sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        size="small"
      >
        <Typography variant="body1" fontWeight={"bold"}>
          Filter By Team:
        </Typography>
        <Select
          value={value}
          sx={{ width: "200px", ml: 1 }}
          onChange={handleChange}
        >
          <MenuItem value="all">
            <dt>Overall</dt>
          </MenuItem>
          {TeamOptions.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.territory_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Grid
        container
        display={"flex"}
        justifyContent={"space-around"}
        spacing={1}
      >
        <Grid item xs={6}>
          <EmployeeReport value={value} />
        </Grid>
        <Grid item xs={6}>
          <TerritoryReport value={value} />
        </Grid>
      </Grid>
    </div>
  );
};
