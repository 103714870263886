import { configureStore } from "@reduxjs/toolkit";
import employeesReducer from "./employees/employeesSlice";
import layoutReducer from "./layout/layoutSlice";

export const store = configureStore({
  reducer: {
    layout: layoutReducer,
    employees: employeesReducer,
  },
});
