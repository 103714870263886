import React from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

export default function CustomLoader() {
  return (
    <div className="bg-blur">
      <Box className="custom-preloader-container">
        <CircularProgress size={40} color="info" />
        <Typography sx={{ mt: 1 }} variant="subtitle2">
          Loading...Please wait
        </Typography>
      </Box>
    </div>
  );
}
