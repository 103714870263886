import { Box, CssBaseline, Toolbar } from "@mui/material";
import AppHeader from "components/layouts/AppHeader";
import { ExpiredSessionPopup } from "components/shared/ExpiredSessionPopup";
import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

const NotificationLayout = () => {
  const isAuthorised = useSelector((state) => state.layout.isAuthorised);

  
  return (
    <Box sx={{ }}>
      <CssBaseline />
      <AppHeader />
      {isAuthorised && <ExpiredSessionPopup/>}
      <Box component="main" sx={{ flexGrow: 1, p: 2, }}>
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};

export default NotificationLayout;
