import React, { useState, useEffect } from "react";
import { Grid, Stack, Typography, Button, Card } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import PeopleIcon from "../../assets/images/people-bg.png";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import EmployeeDetails from "./EmployeeDetails";
import TerritoryAssignments from "./TerritoryAssignments";
import ContactDetails from "./ContactDetails";
import TerminateEmployee from "./TerminateEmployee";
import ReasignEmployee from "./ReasignEmployee";
import { useSelector, useDispatch } from "react-redux";
import {
  updateEmployeeDetails,
  InitializeEmployeeDetails,
  updateErrorList,
} from "../../store/employees/employeesSlice";
import axios from "axios";
import CustomLoader from "components/shared/CustomLoader";
import CustomNotify from "components/shared/CustomNotify";
import confetti from "canvas-confetti";
import { isFutureDate } from "utils/Util";
import Notes from "./Notes";

const AddEmployee = () => {
  const empDetails = useSelector((state) => state.employees.EMPLOYEE_DETAILS);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [ReasignModal, setReasignModal] = useState(false);
  const [TerminateModal, setTerminateModal] = useState(false);
  const [isEditMode, setIsEditModel] = useState(null);
  const [action, setAction] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [empTerminated, setEmpTerminated] = useState("");

  const empOptions = useSelector(
    (state) => state.employees.EmploymentTypeOptions,
  );
  const EmailTypeOptions = useSelector(
    (state) => state.employees.EmailTypeOptions,
  );

  const openReasignModel = () => {
    setReasignModal(true);
    setAction("add");
  };

  const closeReasignModel = () => {
    setReasignModal(false);
    setAction(null);
  };

  //TimeOff model
  const openTermonateModel = () => {
    setTerminateModal(true);
    setAction("add");
  };

  const closeTerminateModel = () => {
    setTerminateModal(false);
    setAction(null);
  };

  const loadEmployeeDetails = (id) => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/employees/${id}`)
      .then((res) => {
        dispatch(updateEmployeeDetails(res.data.results));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    let action = location.pathname.split("/").pop();
    if (action === "add") {
      setIsEditModel("add");
      dispatch(InitializeEmployeeDetails());
    } else {
      setIsEditModel("edit");
      loadEmployeeDetails(action);
    }
  }, [location]);

  useEffect(() => {
    let isDefaultEmptype = empOptions.find((el) => el.is_default === true);
    let val = isDefaultEmptype?.id;
    let actionType = location.pathname.split("/").pop();
    if (actionType === "add") {
      update({ employmentType: val });
    }
  }, [empOptions]);

  const isValidationFailed = (errorsObj) => {
    if (errorsObj && Object.keys(errorsObj).length) {
      const { emailErrors, phoneErrors, addressErrors, ...rest } = errorsObj;

      const restErros = Object.values(rest).indexOf(true) > -1;
      const err1 = addressErrors && Object.keys(addressErrors).length > 0;
      const err2 = phoneErrors && Object.keys(phoneErrors).length > 0;
      const err3 = emailErrors && Object.keys(emailErrors).length > 0;

      if (restErros || err1 || err2 || err3) {
        return true;
      }
      return false;
    }
    return false;
  };

  const validateForm = () => {
    let errors = {
      firstName: false,
      lastName: false,
      position: false,
      department: false,
      employeeNo: false,
      employmentType: false,
      startDate: false,
    };
    let emailErrors = {};
    let phoneErrors = {};
    let addressErrors = {};

    Object.entries(errors).forEach(([key, value]) => {
      if (
        empDetails[key] === "" ||
        empDetails[key] === null ||
        empDetails[key] === undefined
      ) {
        errors[key] = true;
      }
    });

    ["status"].forEach((el) => {
      if (empDetails[el] === "Inactive") {
        errors[el] = true;
      }
    });

    empDetails.emails.forEach((item) => {
      let err = [];
      if (item.value === "") {
        err.push("email");
      }
      if (item.emailType === "") {
        err.push("emailType");
      }
      if (item.value && checkEmailFormat(item.value) === false) {
        err.push("emailCheck");
      }
      if (item.value && checkEmailFormat(item.value) === true) {
        const domainMatch = item.value.match(/@(.+)/);
        const domain = domainMatch ? "@" + domainMatch[1] : "";
        let selectedEmailTypeDomain = EmailTypeOptions?.find(
          (el) => el.id === item.emailType,
        );

        if (
          domain &&
          selectedEmailTypeDomain &&
          domain !== selectedEmailTypeDomain?.domain
        ) {
          err.push("invalidDomain");
        }
      }

      if (err.length > 0) {
        emailErrors[item.id] = [...err];
      }
    });

    if (Object.keys(emailErrors).length > 0) {
      errors = { ...errors, emailErrors: { ...emailErrors } };
    }

    empDetails.phoneNumbers.forEach((item) => {
      let err = [];
      if (item.value !== "" && item.phoneType === "" && checkPhoneFormat(item.value) === true) {
        err.push("phoneType")
      }

      if (item.value === "" && item.phoneType !== "") {
        err.push("phone")
      }

      if (item.value && checkPhoneFormat(item.value) === false) {
        err.push("phoneCheck");
      }

      if (err.length > 0) {
      phoneErrors[item.id] = [...err];
      }
    });

    if (Object.keys(phoneErrors).length > 0) {
    errors = { ...errors, phoneErrors: { ...phoneErrors } };
    }

    // let primAddress = [];
    empDetails.addresses.forEach((item, i) => {
      let err = [];
      // primAddress.push(item.isPrimary);

      if (item.addressesType === "") {
        err.push("addressesType");
      }
      if (item.isPrimary === "Inactive") {
        err.push("isPrimary");
      }

      if (item.isActive === "Inactive") {
        err.push("isActive");
      }

      if (item.addressLine1.trim() === "") {
        err.push("addressLine1");
      }

      if (item.state === "") {
        err.push("state");
      }
      if (item.city === "") {
        err.push("city");
      }
      if (item.zip === "") {
        err.push("zip");
      }
      if (item.zip && checkZipFormat(item.zip) === false) {
        err.push("zipCheck");
      }
      if (item.country === "") {
        err.push("country");
      }
      // if (primAddress.length > 1) {
      //   let duplicateChecker = primAddress.every((item) => item === "Active");
      //   if (duplicateChecker) err.push("isDuplicatePrimary");
      // }

      if (err.length > 0) {
        addressErrors[item.id] = [...err];
      }
    });

    if (Object.keys(addressErrors).length > 0) {
      errors = { ...errors, addressErrors: { ...addressErrors } };
    }

    dispatch(updateErrorList(errors));
    return isValidationFailed(errors);
  };

  const checkEmailFormat = (val) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    // const emailRegex = /^[A-Z0-9._%+-]+/i;
    return emailRegex.test(val);
  };

  const checkPhoneFormat = (val) => {
    const cleanedVal = val.replace(/\D/g, "");
    return /^\d{10}$/.test(cleanedVal);
    // const phoneRegex = /^\d{10}$/;
    // return phoneRegex.test(val);
  };

  const checkZipFormat = (val) => {
    const zipRegex = /^\d{5}$/;
    return zipRegex.test(val);
  };

  const addNewEmployee = async () => {
    setIsSaved(null);
    setErrorMessage(null);
    setLoading(true);

    try {
      if (validateForm()) {
        return;
      }
      let modifiedEmpDetails = { ...empDetails };
      modifiedEmpDetails.phoneNumbers = empDetails.phoneNumbers.filter(phone => phone.phoneType !== "" && phone.value !== "");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}employees`,
        modifiedEmpDetails,
      );
      if (response.data.results) {
        setIsSaved("success");
        callConfetti();
        navigate(`/employees/edit/${response.data.results.id}`);
      }
    } catch (error) {
      setIsSaved("error");
      const errMessage = error?.response?.data?.message;
      setErrorMessage(Object.values(errMessage));
    } finally {
      setLoading(false);
    }
  };

  const callConfetti = () => {
    var count = 200;
    var defaults = {
      origin: { y: 0.7 },
    };

    function fire(particleRatio, opts) {
      confetti({
        ...defaults,
        ...opts,
        particleCount: Math.floor(count * particleRatio),
      });
    }

    fire(0.25, {
      spread: 26,
      startVelocity: 55,
    });
    fire(0.2, {
      spread: 60,
    });
    fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  };

  const updateEmployee = () => {
    setIsSaved(null);
    setErrorMessage(null);
    if (validateForm()) {
      return;
    }
    setLoading(true);
    let modifiedEmpDetails = { ...empDetails };
    modifiedEmpDetails.phoneNumbers = empDetails.phoneNumbers.filter(phone => phone.phoneType !== "" && phone.value !== "");

    axios
      .put(
        `${process.env.REACT_APP_API_URL}employees/${empDetails.id}`,
        modifiedEmpDetails,
      )
      .then((response) => {
        setIsSaved("success");
        callConfetti();
      })
      .catch((error) => {
        setIsSaved("error");
        const errMessage = error?.response?.data?.message;
        setErrorMessage(errMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const isDisabled = () => {
    if (empDetails?.terminated_by === null && empTerminated?.date_terminated) {
      return true;
    }
    if (empDetails?.territoryAssignment?.length === 0) {
      return true;
    }

    if (empDetails && Object.keys(empDetails).length > 0) {
      const isActive = empDetails?.territoryAssignment
        ?.map((item) => {
          if (isFutureDate(item.endDate) || item.endDate === null) {
            return true;
          }
          return false;
        })
        .includes(true);
      return isActive;
    }
    return true;
  };

  const saveReasignEmployee = (data) => {};

  const update = (data) => {
    dispatch(updateEmployeeDetails(data));
  };

  // const handleInterface = (e, item) => {
  //   const { checked } = e.target;

  //   let newInterfaces = empDetails.interfaces || [];
  //   if (checked) {
  //     newInterfaces = [...newInterfaces, item.id];
  //   } else {
  //     newInterfaces = newInterfaces.filter((val) => val !== item.id);
  //   }
  //   update({ ...empDetails, interfaces: [...newInterfaces] });
  // };

  const updateTerminate = (val) => {
    setEmpTerminated(val);
    dispatch(
      updateEmployeeDetails({
        date_terminated: val.date_terminated || val.termination_date,
      }),
    );
  };

  return (
    <div className="page-content-container">
      <div className="page-header">
        <Grid container direction="row" alignItems="center">
          <Grid item md={5} sm={12}>
            <Stack direction="row" alignItems="center">
              <Typography variant="h5">Manage Employees</Typography>
              <img
                style={{ marginLeft: "3em", maxWidth: "200px" }}
                src={PeopleIcon}
                alt="people"
              />
            </Stack>
          </Grid>
        </Grid>
      </div>
      <div className="page-content">
        {loading && <CustomLoader />}
        <Card className="custom-card">
          <div className="card-header">
            <Grid container direction="row" alignItems="center">
              <Grid item md={6} sm={6}>
                <Stack
                  spacing={1}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Link to="/">
                    <Button size="small" color="info" variant="text">
                      <ArrowBackIosIcon fontSize="15px" />
                      Back to All
                    </Button>
                  </Link>
                </Stack>
              </Grid>
              <Grid item md={6} sm={6}>
                {isEditMode === "edit" && (
                  <Stack
                    spacing={2}
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Button
                      size="small"
                      color="info"
                      variant="outlined"
                      disabled={isDisabled()}
                      onClick={() => openReasignModel()}
                    >
                      Reassign
                    </Button>
                    {empDetails?.terminated_by === null &&
                    empTerminated?.date_terminated === undefined ? (
                      <Button
                        size="small"
                        color="error"
                        variant="outlined"
                        onClick={() => openTermonateModel()}
                      >
                        Terminate
                      </Button>
                    ) : (
                      <>
                        {(empDetails?.date_terminated ||
                          empDetails?.termination_date) && (
                          <Button
                            size="small"
                            color="error"
                            variant="contained"
                            onClick={() => openTermonateModel()}
                          >
                            {`Terminated On : ${empDetails?.date_terminated || empDetails?.termination_date}`}
                          </Button>
                        )}
                      </>
                    )}
                  </Stack>
                )}
              </Grid>
            </Grid>
          </div>
          <div className="card-content">
            <Grid container spacing={4}>
              <Grid item md={8}>
                <EmployeeDetails
                  empDetails={empDetails}
                  update={(data) => update(data)}
                  isEditMode={isEditMode}
                  empOptions={empOptions}
                />
                <TerritoryAssignments
                  empDetails={empDetails}
                  update={(data) => update(data)}
                  isEditMode={isEditMode}
                  empTerminated={empTerminated}
                />
                {/* <div>
                  <Grid
                    container
                    direction={"column"}
                    spacing={0}
                    sx={{ mb: 3 }}
                  >
                    <Grid item>
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1 }}
                      >
                        Interfaces
                      </Typography>
                    </Grid>
                    <Grid item>
                      <FormGroup>
                        <Grid container>
                          {InterfaceOptions.map((item, index) => (
                            <Grid item xs={3} key={index}>
                              <FormControlLabel
                                control={<Checkbox value={item} />}
                                key={index}
                                label={item.name}
                                size="small"
                                // name={item.name}
                                checked={
                                  empDetails?.interfaces?.indexOf(item.id) > -1
                                }
                                onChange={($e) => handleInterface($e, item)}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </FormGroup>
                    </Grid>
                  </Grid>
                </div> */}
                {isEditMode === "edit" && empDetails && empDetails?.id && (
                  <Grid
                    container
                    direction={"column"}
                    spacing={0}
                    sx={{ mb: 3 }}
                  >
                    <Grid item>
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1 }}
                      >
                        Employee Notes
                      </Typography>
                    </Grid>
                    <Notes empDetails={empDetails} />
                  </Grid>
                )}
              </Grid>

              <Grid item md={4}>
                <ContactDetails
                  empDetails={empDetails}
                  isEditMode={isEditMode}
                  update={(data) => update(data)}
                />
              </Grid>
            </Grid>
          </div>
        </Card>
      </div>
      <div className="content-footer bg-ligh-grey">
        <Grid
          container
          justifyContent={"flex-end"}
          spacing={1}
          alignItems={"center"}
        >
          <Grid item>
            <Button
              size="small"
              sx={{ width: "100px" }}
              variant="outlined"
              onClick={() => navigate("/")}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <LoadingButton
              sx={{ width: "100px" }}
              size="small"
              loading={loading}
              variant="contained"
              disabled={
                isEditMode === "edit"
                  ? empDetails?.terminated_by === null &&
                    empTerminated?.date_terminated === undefined
                    ? false
                    : true
                  : false
                // || (empTerminated?.terminated_by === null ? false :true)
              }
              onClick={() =>
                isEditMode === "edit" ? updateEmployee() : addNewEmployee()
              }
            >
              Save
            </LoadingButton>
          </Grid>
        </Grid>
      </div>

      {isSaved === "success" && (
        <CustomNotify
          open={true}
          severity="success"
          message="The Employee Details has been saved Successfully"
        />
      )}
      {isSaved === "error" && (
        <CustomNotify open={true} severity="error" message={errorMessage} />
      )}

      {empTerminated?.date_terminated ? (
        <CustomNotify
          open={true}
          severity="success"
          message=" The employee's termination has been processed."
        />
      ) : (
        ""
      )}

      {ReasignModal && (
        <ReasignEmployee
          // data={selectedRow}
          action={action}
          close={() => closeReasignModel()}
          save={saveReasignEmployee}
          empDetails={empDetails}
          update={update}
        />
      )}

      {TerminateModal && (
        <TerminateEmployee
          action={action}
          isEditMode={isEditMode}
          empDetails={empDetails}
          close={() => closeTerminateModel()}
          updateTerminate={updateTerminate}
        />
      )}
    </div>
  );
};

export default AddEmployee;
