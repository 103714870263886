import React, { useState, useRef } from "react";
import { Button, Grid, Box, CircularProgress } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import {
  updateFileError,
  updateSelectedFile,
} from "store/employees/employeesSlice";
import MuiAlert from "@mui/material/Alert";
import CustomMenu from "components/shared/CustomMenu";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary, {
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const Accordion = styled((props) => (
  <MuiAccordion elevation={0} square {...props} />
))(({ theme }) => ({
  "&:before": {
    display: "none",
  },
  margin: "0 0 -20px -20px",
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{ fontSize: "0.9rem", fontWeight: "600" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    // padding: '10px',
  },
  "&.Mui-expanded": {
    margin: "-15px 0 -30px -20px",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
}));

export const UploadFile = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const selectedFile = useSelector((state) => state.employees.selectedFile);
  const fileError = useSelector((state) => state.employees.fileError);
  const selectedFileRef = useRef();

  const SuffixOptions = useSelector((state) => state.employees.SuffixOptions);
  const PositionOptions = useSelector(
    (state) => state.employees.PositionOptions,
  );
  const ManagerTypeoptions = useSelector(
    (state) => state.employees.ManagerTypeoptions,
  );
  const DepartmentOptions = useSelector(
    (state) => state.employees.DepartmentOptions,
  );
  const EmploymentTypeOptions = useSelector(
    (state) => state.employees.EmploymentTypeOptions,
  );
  const EmailTypeOptions = useSelector(
    (state) => state.employees.EmailTypeOptions,
  );
  const PhoneTypeOptions = useSelector(
    (state) => state.employees.PhoneTypeOptions,
  );
  const AddressTyoeOptions = useSelector(
    (state) => state.employees.AddressTyoeOptions,
  );

  const handleDrop = (event) => {
    dispatch(updateSelectedFile(event.dataTransfer.files[0]));
  };

  const handleDeleteFile = () => {
    dispatch(updateSelectedFile(null));
    if (selectedFileRef.current) {
      selectedFileRef.current.value = null;
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleFileSelect = (event) => {
    setLoading(true);
    const file = event.target.files[0];

    if (file.type !== "text/csv") {
      dispatch(updateFileError(".csv files are allowed"));
    } else {
      dispatch(updateFileError(null));
      dispatch(updateSelectedFile(file));
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  return (
    <>
      <div className="template">
        <a
          className="template-text"
          href={`${process.env.REACT_APP_API_URL}bulk_import_template`}
          target="_blank"
          rel="noreferrer"
        >
          Download Template
        </a>
      </div>
      <div className="template-or">OR</div>
      <Box
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        className="upload-content"
      >
        <input
          type="file"
          id="fileInput"
          accept=".csv"
          style={{ display: "none" }}
          ref={selectedFileRef}
          onChange={handleFileSelect}
        />
        <label htmlFor="fileInput">
          <Button
            component="span"
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
          >
            Upload File
          </Button>
        </label>

        <h4>
          <label htmlFor="fileInput">Drop a file here or click to upload</label>
        </h4>

        {selectedFile ? (
          <Box className="file-box">
            <Grid container display={"flex"} justifyContent={"center"}>
              {loading ? (
                <Box sx={{ display: "flex" }}>
                  <CircularProgress />
                </Box>
              ) : (
                <MuiAlert
                  variant="outlined"
                  severity="success"
                  sx={{ width: "100%" }}
                  onClose={handleDeleteFile}
                >
                  {selectedFile.name}
                </MuiAlert>
              )}
            </Grid>
          </Box>
        ) : fileError ? (
          <MuiAlert variant="filled" severity="error" sx={{ width: "100%" }}>
            {fileError}
          </MuiAlert>
        ) : (
          ""
        )}
      </Box>
      <Accordion>
        <AccordionSummary
          className="accordian"
          expandIcon={<ArrowRightIcon />}
          aria-controls="panel-content"
          id="panel-header"
        >
          <Typography variant="subtitle2">Upload Rules</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ol>
            <li>Only .csv file is allowed </li>
            <li>All date columns should be in 'YYYY-MM-DD' format</li>
            <li>The following columns should match with one of the options</li>
            <Stack direction="row">
              <CustomMenu name={"Suffix"} options={SuffixOptions} />
              <CustomMenu name={"Email Type"} options={EmailTypeOptions} />
              <CustomMenu name={"Phone Type"} options={PhoneTypeOptions} />
              <CustomMenu name={"Address Type"} options={AddressTyoeOptions} />
            </Stack>
            <Stack direction="row">
              <CustomMenu name={"Position"} options={PositionOptions} />
              <CustomMenu name={"Manager"} options={ManagerTypeoptions} />
              <CustomMenu name={"Department"} options={DepartmentOptions} />
              <CustomMenu
                name={"Employment Type"}
                options={EmploymentTypeOptions}
              />
            </Stack>
          </ol>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
