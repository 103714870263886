import React, { useEffect, useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import ListItemIcon from "@mui/material/ListItemIcon";
import { useDispatch, useSelector } from "react-redux";
import { Logout, PhoneAndroid, EmailOutlined } from "@mui/icons-material";
import MenuList from "@mui/material/MenuList";
import { getProfilePhoto } from "lib/graph";
import {  signOut } from "lib/authConfig";
import { getUserData } from "utils/Util";
import { Badge,  } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useNavigate,  } from "react-router-dom";
import NotificationCard from "components/shared/NotificationCard";
import axios from "axios";
import emptyNotification from "../../assets/images/empty-notification.jpg"
import {
  updateNotifReadCount,
  updateNotificationList,
  updateShowTable,
} from "store/employees/employeesSlice";

export default function AppHeader() {
  const userInfo = useSelector((state) => state.layout.userInfo);
  const NotificationList = useSelector(
    (state) => state.employees.NotificationList,
  );
  const readId = useSelector(
    (state) => state.employees.readId,
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorNotifyEl, setAnchorNotifyEl] = useState(null);
  const [photoUrl, setPhotoUrl] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const openNotification = Boolean(anchorNotifyEl);
  const handleClick = ($event) => {
    setAnchorEl($event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchData = async () => {
    getUserPhoto();
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}notifications?limit=5`);
      dispatch(updateNotificationList(response.data.results));
    } catch (error) {
      console.error(error, "error");
    }
  };

  const handleNotification = (id) => {
    dispatch(updateNotifReadCount(id));
    navigate(`/notification/${id}`);
  };

  useEffect(() => {
    fetchData();
  }, [readId]);

  const handleNotificationClick = ($event) => {
    setAnchorNotifyEl($event.currentTarget);
  };
  const handleNotificationClose = () => {
    setAnchorNotifyEl(null);
  };

  const getUserPhoto = () => {
    let token = getUserData("user_data").token;
    const promise = getProfilePhoto(token);

    promise
      .then((response) => {
        if (response.status !== 200 && response.status !== 401) {
          setPhotoUrl(null);
          return;
        }
        // else if (
        //   response.status === 401 ||
        //   response.statusText === "Unauthorized"
        // ) {
        //   getToken();
        // }

        response.blob().then((res) => {
          const objectURL = URL.createObjectURL(res);

          if (objectURL) {
            setPhotoUrl(objectURL);
          } else {
            setPhotoUrl(null);
          }
        });
      })

      .catch((error) => {
        console.log("error", error);
      });
  };
  const getUserNameInitials = () => {
    let name = " ";
    let data = localStorage.getItem("user_data");
    if (data) {
      data = JSON.parse(data);
      name = data.displayName
        ? data.displayName
        : data.givenName
          ? data.givenName
          : data.surname;
    }
    let index = name.indexOf(" ");
    let name_initials = name.charAt(0);
    let surname_initials = index > 0 ? name.charAt(index + 1) : "";
    let initials = name_initials + surname_initials;
    return initials;
  };


  const handleAllNotification = () => {
    dispatch(updateShowTable(false));
    navigate(`/notification`);
    // window.location.reload(true);
  };

  const getUnreadCount = () => {
    return NotificationList.filter(el => !el.read_flag).length;
  }

  const handleLogo = () => {
    navigate(`/`);
  };

  return (
    <Box sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, position: "fixed" }}>
      <div className="appheader-icon" onClick={handleLogo}>
      <img src={`/logos/${process.env.REACT_APP_CLIENT}`} alt="sidebar-icon" className="corium-icon" />
      </div>
      <AppBar sx={{ width: "calc(100vw - 150px)" }}>
        <Toolbar variant="dense">
          <Typography
            variant="h5"
            component="div"
            fontWeight={"bold"}
            sx={{ flexGrow: 1 }}
          >
            Roster Management
          </Typography>

          <Box
            sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
          >
            <Typography variant="body1">{userInfo?.givenName}</Typography>

            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                {photoUrl !== null ? (
                  <Avatar src={photoUrl} className="profile-icon" />
                ) : (
                  <Avatar className="profile-icon">
                    {getUserNameInitials()}
                  </Avatar>
                )}
              </IconButton>
            </Tooltip>
            <Tooltip title="Notification">
              <IconButton
                size="large"
                onClick={handleNotificationClick}
                aria-controls={openNotification ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openNotification ? "true" : undefined}
              >
                <Badge badgeContent={getUnreadCount()} color="info">
                  {/* <MailIcon size='large' style={{ color: 'white' }}  /> */}
                  <NotificationsIcon style={{ color: "white" }} />
                </Badge>
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                width: 250,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuList
              className="custom-menu-list"
              denses='true'
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                flexDirection: "column",
              }}
            >
              <MenuItem className="custom-menu-item">
                {photoUrl !== null ? (
                  <Avatar src={photoUrl} className="menu-item-avatar" />
                ) : (
                  <Avatar className="menu-item-avatar">
                    {getUserNameInitials()}
                  </Avatar>
                )}
              </MenuItem>
              <MenuItem className="custom-menu-item">
                <Typography variant="h6">{userInfo?.displayName}</Typography>
              </MenuItem>

              <MenuItem className="custom-menu-item jobtitle">
                {userInfo?.jobTitle}
              </MenuItem>
            </MenuList>

            <MenuList  
              denses='true'
              >
              <MenuItem className="custom-menu-item">
                <ListItemIcon>
                  <EmailOutlined fontSize="small" />
                </ListItemIcon>
                <Typography className="email-ellipsis">
                  {userInfo?.userPrincipalName}
                </Typography>
              </MenuItem>
              <MenuItem className="custom-menu-item">
                <ListItemIcon>
                  <PhoneAndroid fontSize="small" />
                </ListItemIcon>
                {userInfo?.mobilePhone || "### ### ####"}
              </MenuItem>
              <Divider />
              <MenuItem onClick={signOut}>
                <ListItemIcon>
                  <Logout fontSize="small" className="logout-button" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </MenuList>
          </Menu>

          <Menu
            anchorEl={anchorNotifyEl}
            id="account-menu"
            open={openNotification}
            onClose={handleNotificationClose}
            onClick={handleNotificationClose}
            autoFocus={false}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                // mt: 1.5,
                backgroundColor: "white",
                width: 380,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "white",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            style={{ marginLeft: "2px" }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <div className="all-notifications">
              {NotificationList?.length === 0 ? (
                <Box className="notification-loader">
                  <img src={emptyNotification} alt="no-notification"/>
                  <Typography variant="subtitle1">
                    No notifications yet!
                  </Typography>
                </Box>
              ) : (
                <>
                  <Typography variant="h6" className="heading">
                    Top 5 Notifications
                  </Typography>
                  <div className="custom-menu-list">
                    {NotificationList?.map((el) => (
                      <div key={el.id} className="custom-menu-item-card">
                        <NotificationCard
                          data={el}
                          handleNotification={handleNotification}
                        />
                      </div>
                    ))}
                  </div>
                  <Typography
                    variant="subtitle2"
                    className="notification-typo"
                    onClick={handleAllNotification}
                  >
                    See All Notifications
                  </Typography>
                </>
              )}
            </div>
          </Menu>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
