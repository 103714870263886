export const GLOBAL_THEME = {
  typography: {
    fontSize: 11,
    // htmlFontSize: 10,
  },
  palette: {
    mode: "light",
    primary: {
      main: "#304659",
    },
    secondary: {
      main: "#346393",
    },
    background: {
      default: "#f4f5f5",
      paper: "#f4f5f5",
    },
    info: {
      main: "#27AAE1",
      contrastText: "#FFFFFF",
    },
    warning: {
      main: "#da2228",
    },
    success: {
      main: "#25a148",
    },
    divider: "#797979",
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {color:"#d32f2f", fontSize: "15px", fontWeight: "bold"},
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        asterisk: {color:"#d32f2f", fontWeight: "bold"},
      },
    },
  },
};
