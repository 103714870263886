import React, { useEffect } from "react";
import { Grid, Card, CardContent, Typography, Box } from "@mui/material";
import BarChart from "../charts/BarChart";
import axios from "axios";
import CardSkeleton from "components/shared/CardSkeleton";
import StatsCard from "./StatsCard";

export default function EmployeeReport(props) {
  const { value } = props;
  const [EmployeesByTeam, setEmployeesByTeam] = React.useState([]);
  const [teamLoading, setTeamLoading] = React.useState(false);
  const [positionLoading, setPositionLoading] = React.useState(false);
  const [EmployeesByPosition, setEmployeesByPosition] = React.useState([]);
  const [typeLoading, setTypeLoading] = React.useState(false);
  const [EmployeesByEmploymentType, setEmployeesByEmploymentType] =
    React.useState([]);

  const [statsLoading, setStatsLoading] = React.useState(false);
  const [EmployeeStats, setEmployeeStats] = React.useState({
    total: 0,
    assigned: 0,
    unassigned: 0,
    active: 0,
    inactive: 0,
    timeOff: 0,
  });

  useEffect(() => {
    getStatsData();
    getEmployeesByTeam();
    getEmployeesByPosition();
    getEmployeesyType();
  }, [value]);

  const getEmployeesyType = () => {
    setTypeLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}report/employee/split-by-employment-type?team_id=${value}`,
      )
      .then((response) => {
        setEmployeesByEmploymentType(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setTypeLoading(false);
      });
  };

  const getEmployeesByPosition = () => {
    setPositionLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}report/employee/split-by-position?team_id=${value}`,
      )
      .then((response) => {
        setEmployeesByPosition(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setPositionLoading(false);
      });
  };

  const getEmployeesByTeam = () => {
    setTeamLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}report/employee/split-by-team?team_id=${value}`,
      )
      .then((response) => {
        setEmployeesByTeam(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setTeamLoading(false);
      });
  };

  const getStatsData = () => {
    setStatsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}report/employee?team_id=${value}`)
      .then((response) => {
        setEmployeeStats(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setStatsLoading(false);
      });
  };

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{ mt: 0.5 }}
      >
        <Grid item xs={12}>
          {statsLoading ? (
            <CardSkeleton small="true" />
          ) : (
            <StatsCard title={"Employees"} stats={EmployeeStats} />
          )}
        </Grid>
        {value === "all" ? (
          <Grid item md={12} sm={12}>
             {positionLoading ? (
            <CardSkeleton />
          ) : (
            <Card className="report-card">
              <CardContent>
                <Typography className="card-title no-padding">
                  # of Employees By Team
                </Typography>
                <Box
                  // display="flex"
                  // justifyContent="center"
                  sx={{ mt: 1.5,width:'100%',height:'100%' }}
                >
                  <BarChart data={EmployeesByTeam} />
                </Box>
              </CardContent>
            </Card>
          )}
          </Grid>
        ) : (
          ""
        )}

       

        <Grid item md={12} sm={12}>
          {positionLoading ? (
            <CardSkeleton />
          ) : (
            <Card className="report-card">
              <CardContent>
                <Grid display={"flex"} justifyContent={"space-between"}>
                  <Typography className="card-title no-padding">
                    # of Employees By Position
                  </Typography>
                </Grid>

                <Box
                  // display="flex"
                  // justifyContent="center"
                  sx={{ mt: 1.5, width: "100%",height:'100%' }}
                >
                  <BarChart data={EmployeesByPosition} />
                </Box>
              </CardContent>
            </Card>
          )}
        </Grid>
        <Grid item md={12} sm={12}>
          {typeLoading ? (
            <CardSkeleton />
          ) : (
            <Card className="report-card">
              <CardContent>
                {/* <Grid display={"flex"} justifyContent={"space-between"}> */}
                <Typography className="card-title no-padding">
                  # of Employees By Employment Type
                </Typography>

                <Box
                  // display="flex"
                  // justifyContent="center"
                  sx={{ mt: 1.5, width: "100%",height:'100%' }}
                >
                  <BarChart data={EmployeesByEmploymentType} />
                </Box>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>

      {/* <Grid
        container
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{ mt: 0.1 }}
      >
        <Grid item md={6} sm={6}>
          <Card className="report-card">
            <CardContent>
              <Typography className="card-title no-padding">
                Split By Geography
              </Typography>
              <Box
                display="flex"
                justifyContent="center"
                sx={{ mt: 1.5, width: "100%" }}
              >
                 <PieChart data={EmployeesByPosition} />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid> */}
    </>
  );
}
