import { createSlice } from "@reduxjs/toolkit";
import { UID } from "utils/Util";

const EMPLOYEE_SKELITON = {
  firstName: "",
  middleName: "",
  lastName: "",
  preferredName: "",
  suffix: "",
  dateOfBirth: "",
  position: "",
  department: "",
  employmentType: "",
  employeeNo: "",
  jobTitle: "",
  job_code: "",
  Manager: "",
  networkUsername: "",
  hire_date: "",
  startDate: "",
  end_date: "",
  status: "Inactive",
  insider: "Inactive",
  field_based: "Inactive",
  crmLogins: [],
  interfaces: [],
  teamId: 0,
  territoryAssignment: [
    // {
    //   id: "",
    //   team: "",
    //   territoryId: "",
    //   territoryName: "",
    //   type: "",
    //   startDate: "",
    //   ICDate: "",
    //   endDate: "",
    //   active: "",
    // },
  ],
  timeOffTerritory: [
    // {
    //   id: "",
    //   team: "",
    //   territoryId: "",
    //   territoryName: "",
    //   type: "",
    //   startDate: "",
    //   ICDate: "",
    //   endDate: "",
    //   active: "",
    // },
  ],
  emails: [
    {
      id: UID(),
      emailType: "",
      value: "",
      status: "Active",
    },
  ],
  phoneNumbers: [
    {
      id: UID(),
      phoneType: "",
      value: "",
      status: "Active",
    },
  ],
  addresses: [
    {
      id: UID(),
      addressesType: "",
      isPrimary: "Inactive",
      isActive: "Inactive",
      addressLine1: "",
      addressLine2: "",
      city: "",
      timezone: "",
      state: "",
      zip: "",
      country: "",
    },
  ],
};
const initialState = {
  EMPLOYEE_DETAILS: {},
  PositionOptions: [],
  DepartmentOptions: [],
  EmploymentTypeOptions: [],
  ManagerTypeoptions: [],
  EmailTypeOptions: [],
  PhoneTypeOptions: [],
  StateOptions: [],
  CountryOptions: [],
  AddressTyoeOptions: [],
  TeamOptions: [],
  // TerritoryOptions: [],
  loginData: [],
  Interface: [],
  InterfaceOptions: [],
  AssignmentTypeOptions: [],
  TimeOffTypeOptions: [],
  empErrors: {},
  terrModuleFormErrors: {},
  TerritoryLevelOptions: [],
  TerritoryLevelAdminOptions: [],
  EmployeeAssignments: [],
  EmployeeTimeoffs: [],
  SuffixOptions: [
    {
      value: "Jr",
      label: "Jr",
    },
    {
      value: "Sr",
      label: "Sr",
    },
    {
      value: "II",
      label: "II",
    },
    {
      value: "III",
      label: "III",
    },
    {
      value: "IV",
      label: "IV",
    },
    {
      value: "V",
      label: "V",
    },
  ],
  AddTerritoryTypeOptions: [
    {
      value: 1,
      label: "Primary",
    },
    {
      value: 2,
      label: "Secondary",
    },
  ],
  // TimeOffTypeOptions: [
  //   {
  //     value: 1,
  //     label: "Vacation",
  //   },
  //   {
  //     value: 2,
  //     label: "Sick",
  //   },
  //   {
  //     value: 3,
  //     label: "Long Term Disability",
  //   },
  //   {
  //     value: 4,
  //     label: "LOA",
  //   },
  // ],
  selectedFile: null,
  fileError: "",
  NotificationList: [],
  SelectedNotificationId: 0,
  readId: "",
  showTable: true,
};

export const employeesSlice = createSlice({
  name: "employees",
  initialState,
  reducers: {
    updateEmployeeDetails: (state, action) => {
      state.EMPLOYEE_DETAILS = { ...state.EMPLOYEE_DETAILS, ...action.payload };
      if (
        Array.isArray(action.payload.phoneNumbers) &&
        action.payload.phoneNumbers.length === 0
      ) {
        const newPhoneNumber = {
          id: UID(),
          phoneType: "",
          value: "",
          status: "Active",
        };
        state.EMPLOYEE_DETAILS.phoneNumbers = [newPhoneNumber];
      }
    },
    upDateEmails: (state, action) => {
      state.EMPLOYEE_DETAILS.emails = {
        ...state.EMPLOYEE_DETAILS.emails,
        ...action.payload,
      };
      // state.EMPLOYEE_DETAILS.emails.push(action.payload);
    },
    InitializeEmployeeDetails: (state) => {
      state.empErrors = {};
      state.EMPLOYEE_DETAILS = EMPLOYEE_SKELITON;
    },
    upDatePositionOptions: (state, action) => {
      state.PositionOptions = [...state.PositionOptions, ...action.payload];
    },
    editPositionOptions: (state, action) => {
      state.PositionOptions = state.PositionOptions.map((position) =>
        position.id === action.payload.id
          ? { ...position, ...action.payload }
          : position,
      );
    },
    deletePositionOptions: (state, action) => {
      state.PositionOptions = state.PositionOptions.filter(
        (position) => position.id !== action.payload,
      );
    },

    upDateDepartmentOptions: (state, action) => {
      state.DepartmentOptions = [...state.DepartmentOptions, ...action.payload];
    },
    editDepartmentOptions: (state, action) => {
      state.DepartmentOptions = state.DepartmentOptions.map((el) =>
        el.id === action.payload.id ? { ...el, ...action.payload } : el,
      );
    },
    deleteDepartmentOptions: (state, action) => {
      state.DepartmentOptions = state.DepartmentOptions.filter(
        (el) => el.id !== action.payload,
      );
    },

    upDateEmploymentTypeOptions: (state, action) => {
      state.EmploymentTypeOptions = [
        ...state.EmploymentTypeOptions,
        ...action.payload,
      ];
    },
    updateManagerTypeOptions: (state, action) => {
      state.ManagerTypeoptions = [
        ...state.ManagerTypeoptions,
        ...action.payload,
      ];
    },
    editEmployementTypeOptions: (state, action) => {
      state.EmploymentTypeOptions = state.EmploymentTypeOptions.map((el) =>
        el.id === action.payload.id ? { ...el, ...action.payload } : el,
      );
    },
    deleteEmploymentTypeOptions: (state, action) => {
      state.EmploymentTypeOptions = state.EmploymentTypeOptions.filter(
        (el) => el.id !== action.payload,
      );
    },
    updateEmailTypeOptions: (state, action) => {
      state.EmailTypeOptions = [...state.EmailTypeOptions, ...action.payload];
    },
    editEmailTypeOptions: (state, action) => {
      state.EmailTypeOptions = state.EmailTypeOptions.map((el) =>
        el.id === action.payload.id ? { ...el, ...action.payload } : el,
      );
    },
    deleteEmailTypeOptions: (state, action) => {
      state.EmailTypeOptions = state.EmailTypeOptions.filter(
        (el) => el.id !== action.payload,
      );
    },
    updatePhoneTypeOptions: (state, action) => {
      state.PhoneTypeOptions = [...state.PhoneTypeOptions, ...action.payload];
    },
    editPhoneTypeOptions: (state, action) => {
      state.PhoneTypeOptions = state.PhoneTypeOptions.map((el) =>
        el.id === action.payload.id ? { ...el, ...action.payload } : el,
      );
    },
    deletePhoneTypeOptions: (state, action) => {
      state.PhoneTypeOptions = state.PhoneTypeOptions.filter(
        (el) => el.id !== action.payload,
      );
    },
    updateCountryOptions: (state, action) => {
      state.CountryOptions = [...state.CountryOptions, ...action.payload];
    },
    editCountryOptions: (state, action) => {
      state.CountryOptions = state.CountryOptions.map((el) =>
        el.code === action.payload.code ? { ...el, ...action.payload } : el,
      );
    },
    deleteCountryOptions: (state, action) => {
      state.CountryOptions = state.CountryOptions.filter(
        (el) => el.code !== action.payload,
      );
    },
    updateStateOptions: (state, action) => {
      state.StateOptions = [...state.StateOptions, ...action.payload];
    },
    editStateOptions: (state, action) => {
      state.StateOptions = state.StateOptions.map((el) =>
        el.code === action.payload.code ? { ...el, ...action.payload } : el,
      );
    },
    deleteStateOptions: (state, action) => {
      state.StateOptions = state.StateOptions.filter(
        (el) => el.code !== action.payload,
      );
    },
    updateAddressTypeOptions: (state, action) => {
      state.AddressTyoeOptions = [
        ...state.AddressTyoeOptions,
        ...action.payload,
      ];
    },
    editAddressTypeOptions: (state, action) => {
      state.AddressTyoeOptions = state.AddressTyoeOptions.map((el) =>
        el.id === action.payload.id ? { ...el, ...action.payload } : el,
      );
    },
    deleteAddressTypeOptions: (state, action) => {
      state.AddressTyoeOptions = state.AddressTyoeOptions.filter(
        (el) => el.id !== action.payload,
      );
    },
    updateTeamOptions: (state, action) => {
      state.TeamOptions = [...action.payload];
    },
    upDateLoginData: (state, action) => {
      state.loginData = [...action.payload];
    },
    updateInterfaceOptions: (state, action) => {
      state.InterfaceOptions = [...state.InterfaceOptions, ...action.payload];
    },
    editInterfaceOptions: (state, action) => {
      state.InterfaceOptions = state.InterfaceOptions.map((el) =>
        el.id === action.payload.id ? { ...el, ...action.payload } : el,
      );
    },
    deleteInterfaceOptions: (state, action) => {
      state.InterfaceOptions = state.InterfaceOptions.filter(
        (el) => el.id !== action.payload,
      );
    },
    updateAssignmentTypeOptions: (state, action) => {
      state.AssignmentTypeOptions = [
        ...state.AssignmentTypeOptions,
        ...action.payload,
      ];
    },
    editAssignmentTypeOptions: (state, action) => {
      state.AssignmentTypeOptions = state.AssignmentTypeOptions.map((el) =>
        el.id === action.payload.id ? { ...el, ...action.payload } : el,
      );
    },
    deleteAssignmentTypeOptions: (state, action) => {
      state.AssignmentTypeOptions = state.AssignmentTypeOptions.filter(
        (el) => el.id !== action.payload,
      );
    },
    updateTimeOffTypeOptions: (state, action) => {
      state.TimeOffTypeOptions = [
        ...state.TimeOffTypeOptions,
        ...action.payload,
      ];
    },
    editTimeOffTypeOptions: (state, action) => {
      state.TimeOffTypeOptions = state.TimeOffTypeOptions.map((el) =>
        el.id === action.payload.id ? { ...el, ...action.payload } : el,
      );
    },
    deleteTimeOffTypeOptions: (state, action) => {
      state.TimeOffTypeOptions = state.TimeOffTypeOptions.filter(
        (el) => el.id !== action.payload,
      );
    },

    updateErrorList: (state, action) => {
      state.empErrors = { ...action.payload };
    },
    updateTerrModuleFormErrors: (state, action) => {
      state.terrModuleFormErrors = { ...action.payload };
    },

    updateTeamId: (state, action) => {
      state.teamId = action.payload;
    },
    updateSelectedFile: (state, action) => {
      state.selectedFile = action.payload;
    },
    updateFileError: (state, action) => {
      state.fileError = action.payload;
    },
    updateTerritoryLevelOptions: (state, action) => {
      state.TerritoryLevelOptions = [
        ...state.TerritoryLevelOptions,
        ...action.payload,
      ];
    },
    updateTerritoryLevelAdminOptions: (state, action) => {
      state.TerritoryLevelAdminOptions = [
        ...state.TerritoryLevelAdminOptions,
        ...action.payload,
      ];
    },
    editTerritoryLevelAdminOptions: (state, action) => {
      state.TerritoryLevelAdminOptions = state.TerritoryLevelAdminOptions.map(
        (el) =>
          el.id === action.payload.id ? { ...el, ...action.payload } : el,
      );
    },
    deleteTerritoryLevelAdminOptions: (state, action) => {
      state.TerritoryLevelAdminOptions =
        state.TerritoryLevelAdminOptions.filter(
          (el) => el.id !== action.payload,
        );
    },

    updateEmployeeAssignment: (state, action) => {
      state.EmployeeAssignments = [
        ...state.EmployeeAssignments,
        action.payload,
      ];
    },
    updateEmployeeTimeoffs: (state, action) => {
      state.EmployeeTimeoffs = [...state.EmployeeTimeoffs, action.payload];
    },
    updateNotificationList: (state, action) => {
      state.NotificationList = [...action.payload];
    },
    updateNotifReadCount: (state, action) => {
      state.NotificationList = state.NotificationList.map((el) => {
        if (el.id === action.payload) {
          return {
            ...el,
            read_flag: true,
          };
        }
        return el;
      });
    },
    updatedId: (state, action) => {
      state.readId = action.payload;
    },
    updateShowTable: (state, action) => {
      state.showTable = action.payload;
    },
  },
});

export const {
  updateEmployeeDetails,
  upDateEmails,
  InitializeEmployeeDetails,
  upDatePositionOptions,
  editPositionOptions,
  deletePositionOptions,
  upDateDepartmentOptions,
  editDepartmentOptions,
  deleteDepartmentOptions,
  upDateEmploymentTypeOptions,
  updateManagerTypeOptions,
  editEmployementTypeOptions,
  deleteEmploymentTypeOptions,
  updateEmailTypeOptions,
  editEmailTypeOptions,
  editAddressTypeOptions,
  editPhoneTypeOptions,
  deletePhoneTypeOptions,
  deleteAddressTypeOptions,
  deleteEmailTypeOptions,
  updatePhoneTypeOptions,
  updateCountryOptions,
  editCountryOptions,
  deleteCountryOptions,
  updateStateOptions,
  editStateOptions,
  deleteStateOptions,
  updateAddressTypeOptions,
  updateTeamOptions,
  upDateLoginData,
  updateInterfaceOptions,
  editInterfaceOptions,
  deleteInterfaceOptions,
  updateAssignmentTypeOptions,
  editAssignmentTypeOptions,
  deleteAssignmentTypeOptions,
  updateTimeOffTypeOptions,
  editTimeOffTypeOptions,
  deleteTimeOffTypeOptions,
  updateErrorList,
  updateTerrModuleFormErrors,
  updateTeamId,
  updateSelectedFile,
  updateFileError,
  updateTerritoryLevelOptions,
  updateTerritoryLevelAdminOptions,
  editTerritoryLevelAdminOptions,
  deleteTerritoryLevelAdminOptions,
  updateEmployeeAssignment,
  updateEmployeeTimeoffs,
  updateNotificationList,
  updateNotifReadCount,
  updatedId,
  updateShowTable,
} = employeesSlice.actions;

export default employeesSlice.reducer;
