import React, { useState } from "react";
import {
  Button,
  Dialog,
  Alert,
  AlertTitle,
  Typography,
  Box,
} from "@mui/material";
import { getToken } from "lib/authConfig";

export const ExpiredSessionPopup = () => {
  const [open, setOpen] = useState(true);

  return (
    <div>
      <Dialog open={open} >
        <Box
          sx={{
            display: "flex",
            width: "350px",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Alert severity="warning" sx={{ padding: "2em" }}>
            <AlertTitle variant="h5">Session Expired!</AlertTitle>
            <Typography variant="subtitle1" gutterBottom>
              It seems like your session has expired. Please click the button
              below to login again.
            </Typography>
            <Button
              onClick={() => getToken()}
              color="primary"
              variant="contained"
              sx={{ mt: 2 }}
            >
              Login
            </Button>
          </Alert>
        </Box>
      </Dialog>
    </div>
  );
};
