import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  IconButton,
  Dialog,
  Chip,
  MenuItem,
  Typography,
  Box,
  Alert,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import axios from "axios";
import CustomTextField from "components/shared/CustomTextField";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { dateProp } from "utils/Util";
dayjs.extend(localizedFormat);

const AddAssignment = (props) => {
  const AddTerritoryTypeOptions = useSelector(
    (state) => state.employees.AssignmentTypeOptions,
  );
  const {
    data,
    close,
    action,
    allAssignments,
    update,
    employeeOptions,
    selectedTerritoryAssignment,
    topNode,
  } = props;
  const [initialData, setInitialData] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [assignmentErrors, setAssignmentErrors] = useState({});
  const [latestAssignmentDates, setLatestAssignmentDates] = useState({});
  const [typeOfAssignment, setTypeOfAssignment] = useState(null);
  const [typeOfAssignmentDetails, setTypeOfAssignmentDetails] = useState({});

  const dateProps = dateProp();

  const leastStartDateObj = allAssignments?.territory_assignments?.reduce(
    (minStartDateObj, currentObj) => {
      return new Date(currentObj.start_date) <
        new Date(minStartDateObj.start_date)
        ? currentObj
        : minStartDateObj;
    },
    allAssignments?.territory_assignments[0],
  );

  const latestEndDateObj = allAssignments?.territory_assignments?.reduce(
    (maxEndDateObj, currentObj) => {
      return new Date(currentObj.end_date) > new Date(maxEndDateObj.end_date)
        ? currentObj
        : maxEndDateObj;
    },
    allAssignments?.territory_assignments[0],
  );

  const leastRecordStartDate = leastStartDateObj?.start_date
    ? leastStartDateObj?.start_date
    : null;

  const leastRecordStartDateTerritoryEnddate = leastStartDateObj?.end_date
    ? leastStartDateObj?.end_date
    : null;

  const lastRecordEndDate = latestEndDateObj?.end_date
    ? latestEndDateObj?.end_date
    : null;
  const lastRecordStartDateTerritory = latestEndDateObj?.territory
    ? latestEndDateObj?.territory
    : null;
  const lastRecordEndDateEmployee = latestEndDateObj?.employee
    ? latestEndDateObj?.employee
    : null;

  const lastRecordEndDateTerritoryStartDate = latestEndDateObj?.start_date
    ? latestEndDateObj?.start_date
    : null;

  let currentDate = new Date();
  let currentDateVal = currentDate.toISOString().split("T")[0];
  useEffect(() => {
    let initialData = {
      employee: "",
      assignment_type_id: "",
      start_date: "",
      ic_effective_date: "",
      end_date: "",
      territory: data.id,
      territory_team: topNode.id,
      team_name: topNode.territory_name,
    };
    if (action === "Edit") {
      initialData = {
        employee: selectedTerritoryAssignment.employee,
        assignment_type_id: selectedTerritoryAssignment.assignment_type_id,
        start_date: selectedTerritoryAssignment.start_date,
        ic_effective_date: selectedTerritoryAssignment.ic_effective_date,
        end_date: selectedTerritoryAssignment.end_date,
        territory: data.id,
        territory_team: topNode.id,
        team_name: topNode.territory_name,
      };
      fetchEmployeeLatestAssignmentDates(selectedTerritoryAssignment?.employee);
    }
    setInitialData(initialData);

    callTypeFunction();
  }, [action, selectedTerritoryAssignment]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInitialData({ ...initialData, [name]: value });
    if (value) {
      setAssignmentErrors({ ...assignmentErrors, [name]: false });
    }
  };

  const validateForm = () => {
    let errors = {
      employee: false,
      assignment_type_id: false,
      start_date: false,
      ic_effective_date: false,
    };

    Object.entries(errors).forEach(([key, value]) => {
      if (initialData[key] === "" || initialData[key] === null) {
        errors[key] = true;
      }
    });

    if (initialData.end_date && initialData.start_date > initialData.end_date) {
      errors["end_date"] = true;
    }
    // if (initialData.start_date > initialData.ic_effective_date) {
    //   errors["icDateBeforeStartdate"] = true;
    // }
    // if (
    //   initialData.end_date &&
    //   initialData.ic_effective_date > initialData.end_date
    // ) {
    //   errors["icDateAfterEnddate"] = true;
    // }

    if (
      checkEndDateofPrevAssignment(initialData.start_date, initialData.end_date)
    ) {
      errors["startdateBeforePreviousAssignmentEnddate"] = true;
    }

    if (
      initialData.start_date < leastRecordStartDate &&
      initialData.end_date > leastRecordStartDate
    ) {
      errors["enddateInbetweenlatestAssignmentDates"] = true;
    }

    if (
      initialData.assignment_type_id === 1 &&
      typeOfAssignment &&
      (action === "Edit" || action === "Add") &&
      new Date(initialData.end_date) > new Date()
    ) {
      errors["PrimaryResourceExisted"] = true;
    }

    // if (checkExistingAssignmentsEnddates(initialData.end_date)) {
    //   errors["exceedingLatestAssignmentEnddate"] = true;
    // }

    // if (checkExistingAssignmentsEnddateRange(initialData.end_date)) {
    //   errors["EndDateisNotAvailable"] = true;
    // }

    setAssignmentErrors(errors);
    return Object.values(errors).indexOf(true) > -1;
  };
  const Save = () => {
    if (validateForm()) {
      return;
    }

    setLoading(true);
    setErrorMessage();

    if (action === "Edit") {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}territory-assignment/${selectedTerritoryAssignment.id}`,
          {
            ...initialData,
          },
        )
        .then((response) => {
          update({ ...response.data.results }, action);
          close();
        })
        .catch((err) => {
          const errMessage = err?.response?.data?.message;
          setErrorMessage(errMessage);
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_API_URL}territory-assignment`, {
          ...initialData,
        })
        .then((response) => {
          update({ ...response.data.results }, action);
          close();
        })
        .catch((err) => {
          const errMessage = err?.response?.data?.message;
          setErrorMessage(errMessage);
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleEmpChange = (newValue) => {
    newValue = newValue.target.value;
    setInitialData({ ...initialData, employee: newValue });
    if (newValue) {
      setAssignmentErrors({ ...assignmentErrors, employee: false });
    }
    fetchEmployeeLatestAssignmentDates(newValue);
  };

  // const checkExistingAssignmentsEnddateRange = (currentEndDate) => {
  //   if (action === "Add") {
  //     if (leastRecordStartDate === null && lastRecordEndDate === null) {
  //       return false;
  //     } else if (lastRecordEndDate && currentEndDate < lastRecordEndDate) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else if (action === "Edit") {
  //     let endDates =
  //       initialData.end_date > currentDateVal
  //         ? latestAssignmentDates.end_date
  //         : lastRecordEndDate;
  //     let startDates =
  //       initialData.start_date > currentDateVal
  //         ? latestAssignmentDates.start_date
  //         : leastRecordStartDate;

  //     if (
  //       endDates &&
  //       startDates &&
  //       (lastRecordEndDate || lastRecordEndDate === null) > currentDateVal &&
  //       lastRecordEndDateEmployee === initialData.employee
  //         ? currentEndDate < currentDateVal
  //         : leastRecordStartDateTerritoryEnddate < currentEndDate &&
  //           lastRecordEndDate > currentEndDate
  //     ) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }
  //   return false;
  // };

  // if (
  //   latestPrimary &&
  //   new Date(currentDateVal) <= new Date(latestPrimary.end_date) &&
  //   initialData.assignment_type_id === 1
  // ) {
  //   errors["PrimaryResourceExisted"] = true;
  // }

  // let primaryObjects = allAssignments?.territory_assignments?.filter(
  //   (obj) => obj.assignment_type === "Primary",
  // );

  // let latestPrimary = primaryObjects.reduce((latest, obj) => {
  //   return new Date(obj.end_date) > new Date(latest.end_date) ? obj : latest;
  // }, primaryObjects[0]);

  const checkEndDateofPrevAssignment = (currentStartDate) => {
    if (action === "Add") {
      if (leastRecordStartDate === null && lastRecordEndDate === null) {
        return false;
      } else if (
        lastRecordEndDate &&
        currentStartDate < lastRecordEndDate &&
        currentStartDate > leastRecordStartDate
      ) {
        return true;
      } else if (leastRecordStartDate && lastRecordEndDate === null) {
        return true;
      } else {
        return false;
      }
    } else if (action === "Edit") {
      let endDates =
         initialData.end_date > currentDateVal
          ? latestAssignmentDates.end_date
          : lastRecordEndDate;
      let startDates =
        initialData.start_date > currentDateVal
          ? latestAssignmentDates.start_date
          : leastRecordStartDate;

      if (initialData.end_date === null) {
        return false;
      }
      if (
        endDates &&
        startDates &&
        (lastRecordEndDate || lastRecordEndDate === null) > currentDateVal &&
        lastRecordEndDateEmployee === initialData.employee
          ? lastRecordEndDateTerritoryStartDate > currentStartDate
          : endDates >= currentStartDate && currentStartDate >= startDates
      ) {
        return true;
      }
      // else if (startDates && endDates === null) {
      //   return true;
      // }
      else if (startDates === null && endDates === null) {
        return false;
      } else {
        return false;
      }
    }
    return false;
  };

  // const checkEndDateofPrevAssignment = (currentStartDate) => {
  //   if (
  //     action === "Add" &&
  //     currentStartDate < latestAssignmentDates?.endDate &&
  //     currentStartDate > latestAssignmentDates.startDate
  //   ) {
  //     return true;
  //   } else if (action === "Edit" && latestAssignmentDates.length > 0) {
  //     if (
  //       latestAssignmentDates?.end_date &&
  //       latestAssignmentDates?.end_date >
  //         currentStartDate >
  //         latestAssignmentDates.startDate
  //     ) {
  //       return true;
  //     } else if (latestAssignmentDates?.end_date === null) {
  //       return true;
  //     }
  //     return false;
  //   }
  //   return false;
  // };

  const callTypeFunction = () => {
    let primaryObjects = allAssignments?.territory_assignments?.filter(
      (obj) =>
        obj.assignment_type === "Primary" &&
        (action === "Edit"
          ? selectedTerritoryAssignment.employee
          : initialData.employee) !== obj.employee &&
        (!obj.end_date || new Date(obj.end_date) > new Date()),
    );
    setTypeOfAssignmentDetails(primaryObjects);
    setTypeOfAssignment(primaryObjects?.length > 0 ? true : false);
  };

  const fetchEmployeeLatestAssignmentDates = (empId) => {
    setLatestAssignmentDates({});
    axios
      .get(
        `${process.env.REACT_APP_API_URL}employee-assignments/${empId}/date_range`,
      )
      .then((response) => {
        setLatestAssignmentDates(response.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Dialog
      onClose={close}
      aria-labelledby="customized-dialog-title"
      open={true}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className="bg-ligh-blue">
        {action === "Edit" ? "Edit" : "Add"} Territory Assignment
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={close}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container direction={"column"}>
          <Grid item xs={12}>
            <Grid container spacing={3} alignItems={"center"}>
              <Grid item xs={6}>
                <Box sx={{ display: "inline-flex", alignItems: "center" }}>
                  <Typography variant="body1" sx={{ fontWeight: 500, mr: 1 }}>
                    Team:
                  </Typography>
                  <Chip label={topNode.territory_name} size="small" />
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box sx={{ display: "inline-flex", alignItems: "center" }}>
                  <Typography variant="body1" sx={{ fontWeight: 500, mr: 1 }}>
                    Territory
                  </Typography>
                  :{" "}
                  <Chip
                    label={`${data.territory_name} - ${data.territory_number}`}
                    size="small"
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ mt: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <CustomTextField
                  {...dateProps}
                  required
                  label="Employee"
                  name="employee"
                  type="Date"
                  value={initialData.employee || ""}
                  onChange={($e) => handleEmpChange($e)}
                  size="small"
                  fullWidth
                  select
                  disabled={action === "Edit"}
                  defaultValue={""}
                  error={assignmentErrors.employee}
                  helperText={
                    assignmentErrors.employee ? "This field is required" : ""
                  }
                >
                  {employeeOptions &&
                    employeeOptions.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.label}
                      </MenuItem>
                    ))}
                </CustomTextField>
              </Grid>
              <Grid item xs={6}>
                <CustomTextField
                  key={initialData.start_date}
                  size="small"
                  name="start_date"
                  format="YYYY-MM-DD"
                  required
                  type="date"
                  inputProps={{
                    max: data.end_date,
                    min: data.start_date,
                  }}
                  // {...dateProps}
                  onKeyDown={(e) => e.preventDefault()}
                  label="In Field Start Date"
                  {...(initialData.start_date && {
                    value: initialData.start_date,
                  })}
                  onChange={handleInputChange}
                  fullWidth
                  error={
                    assignmentErrors.start_date ||
                    assignmentErrors.startdateBeforePreviousAssignmentEnddate
                  }
                  helperText={
                    assignmentErrors.start_date
                      ? "This field is required"
                      : assignmentErrors.startdateBeforePreviousAssignmentEnddate
                        ? `Ensure that the start date falls after the end date (${lastRecordEndDate || latestAssignmentDates?.end_date || "-"})  of the previous assignment`
                        : ""
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ mt: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <CustomTextField
                  key={initialData.ic_effective_date}
                  onKeyDown={(e) => e.preventDefault()}
                  size="small"
                  name="ic_effective_date"
                  format="YYYY-MM-DD"
                  required
                  label="IC Date"
                  {...dateProps}
                  {...(initialData.ic_effective_date && {
                    value: initialData.ic_effective_date,
                  })}
                  onChange={handleInputChange}
                  fullWidth
                  error={
                    assignmentErrors.ic_effective_date 
                    // || assignmentErrors.icDateBeforeStartdate ||
                    // assignmentErrors.icDateAfterEnddate
                  }
                  helperText={
                    assignmentErrors.ic_effective_date
                      ? "This field is required"
                      // : assignmentErrors.icDateBeforeStartdate
                      //   ? "Ensure that the IC Date comes after the Start Date"
                      //   : assignmentErrors.icDateAfterEnddate
                      //     ? "Ensure that the IC Date comes before the End Date"
                          : ""
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTextField
                  key={initialData.end_date}
                  size="small"
                  name="end_date"
                  format="YYYY-MM-DD"
                  label="In Field End Date"
                  // {...dateProps}
                  onKeyDown={(e) => e.preventDefault()}
                  type="date"
                  inputProps={{
                    max: `${data.end_date}`,
                    min: `${data.start_date}`,
                  }}
                  {...(initialData.end_date && { value: initialData.end_date })}
                  onChange={handleInputChange}
                  fullWidth
                  error={
                    assignmentErrors.end_date ||
                    // assignmentErrors.EndDateisNotAvailable ||
                    assignmentErrors.exceedingLatestAssignmentEnddate ||
                    assignmentErrors.enddateInbetweenlatestAssignmentDates
                  }
                  helperText={
                    assignmentErrors.end_date
                      ? "End Date must be after Start Date"
                      : // : assignmentErrors.EndDateisNotAvailable
                        // ? "End date is not available"
                        assignmentErrors.exceedingLatestAssignmentEnddate
                        ? `The end date should not exceed the end date assigned to any other territory. (${latestAssignmentDates.end_date})`
                        : assignmentErrors.enddateInbetweenlatestAssignmentDates
                          ? `The end date should not extend beyond the start date of the preceding assignment {${leastRecordStartDate || latestAssignmentDates?.start_date || "-"}}`
                          : ""
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTextField
                  label="Type"
                  name="assignment_type_id"
                  variant="outlined"
                  size="small"
                  required
                  fullWidth
                  select
                  defaultValue={""}
                  value={initialData.assignment_type_id || ""}
                  onChange={($e) => handleInputChange($e)}
                  error={
                    assignmentErrors.assignment_type_id ||
                    assignmentErrors.PrimaryResourceExisted
                  }
                  helperText={
                    assignmentErrors.assignment_type_id
                      ? "This field is required"
                      : assignmentErrors.PrimaryResourceExisted
                        ? "An employee cannot serve as the primary resource for multiple territories."
                        : ""
                  }
                >
                  {AddTerritoryTypeOptions.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.assignment_type_name}
                    </MenuItem>
                  ))}
                </CustomTextField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className="existed-terr-alert">
          {errorMessage ? (
            <Alert
              severity="error"
              sx={{ width: "300px", display: "flex", alignItems: "center" }}
            >
              <strong>{errorMessage}</strong>
            </Alert>
          ) : (
            ""
          )}
        </div>
      </DialogContent>
      <DialogActions sx={{ mr: 2, mb: 1 }}>
        <Button
          sx={{ width: "100px" }}
          size="small"
          variant="outlined"
          disabled={loading}
          onClick={close}
        >
          Cancel
        </Button>
        <LoadingButton
          sx={{ width: "100px" }}
          size="small"
          variant="contained"
          loading={loading}
          color="primary"
          onClick={Save}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddAssignment;
