import React, { useState } from "react";
import {
  Button,
  IconButton,
  Dialog,
  Typography,
  Stack,
  Alert,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const DeleteModal = (props) => {
  const { itemName, handleDelete, handleClose, loading } = props;
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <Dialog aria-labelledby="customized-dialog-title" open={true}>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "gray",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent className="custom-model-content-height">
        <div className="delete-record">
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <div className="admin-delete-button">
              <DeleteForeverIcon sx={{ color: "white" }} />
            </div>

            <Typography variant="h5">Are you sure?</Typography>
            <Typography variant="subtitle2" textAlign="center">
              You are about to delete {itemName}. This action cannot be undone.
            </Typography>
            {errorMessage ? (
              <Alert
                severity="error"
                sx={{ width: "300px", display: "flex", alignItems: "center" }}
              >
                <strong>{errorMessage}</strong>
              </Alert>
            ) : (
              ""
            )}
          </Stack>
        </div>
      </DialogContent>
      <DialogActions
        // dividers
        sx={{ mr: 2, mb: 1, justifyContent: "space-evenly" }}
      >
        <Button
          size="small"
          variant="outlined"
          color="primary"
          sx={{ width: "120px", borderRadius: "20px" }}
          onClick={handleClose}
        >
          Take me back
        </Button>

        <LoadingButton
          sx={{ width: "120px", borderRadius: "20px" }}
          size="small"
          variant="contained"
          loading={loading}
          disabled={errorMessage ? true : false}
          color="error"
          onClick={handleDelete}
        >
          Yes, Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
